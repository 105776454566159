import { INavigationResponse } from '@impact/data';

export enum STATUS {
    OPENED,
    CLOSED,
}

export enum MobileMenuTypes {
    Default,
    BrandsOverview,
    NewCarsBrands,
    NewCarsBrandModels,
    UsedCarsOptions,
    UsedCarsBrands,
    UsedCarsBrandModels,
    UsedCarsBudgets,
    UsedCarsTypes,
    UsedCarsCategories,
    Articles,
}

export interface IMobileMenu {
    navigation?: INavigationResponse;
    type: MobileMenuTypes;
    brandNodeId?: string;
    headerOverride?: string;
}
