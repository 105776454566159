import { CommonModule, CurrencyPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { OverlayModule } from '@impactdk/ngx-overlay';
import { DynamicRoutingModule } from '@impactdk/ngx-routing-utils';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SortPipe } from '../core/pipes/sort.pipe';

import { FadeInComponent } from './animations/fade-in.component';
import { AutoFocusDirective } from './auto-focus/auto-focus.directive';
import { DecodeURIPipe } from './decodeUri.pipe';
import { DefaultCurrencyPipe } from './default-currency.pipe';
import { FilterPipe } from './filter.pipe';
import { ImageLoadFadeInDirective } from './image-load/image-load.directive';
import { InViewportDirective } from './in-viewport/in-viewport.directive';
import { InterceptLinksDirective } from './intercept-links/intercept-links.directive';
import { PhoneNumberPipe } from './phone-number.pipe';
import { ReplacePipe } from './replace.pipe';
import { ResponsiveImageComponent } from './responsive-image.component';
import { SafePipe } from './safe.pipe';
import { ScrollToElementDirective } from './scroll/scroll-to-element.directive';
import { ToastsComponent } from './toasts/toasts.component';
import { VideoPlayerComponent } from './video-player.component';
import { VimeoPlayerComponent } from './vimeo-player/vimeo-player.component';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';
import { UrlifyPipe } from './urlify.pipe';
import { ColonSplitDirective } from './split-colon';
import { ScrollProgressDirective } from './scroll-progress.directive';

@NgModule({
    imports: [CommonModule, BrowserTransferStateModule, OverlayModule, DynamicRoutingModule, TranslateModule.forChild(), NgbToastModule],
    declarations: [
        DecodeURIPipe,
        FilterPipe,
        InterceptLinksDirective,
        ImageLoadFadeInDirective,
        InViewportDirective,
        ScrollToElementDirective,
        AutoFocusDirective,
        FadeInComponent,
        SafePipe,
        PhoneNumberPipe,
        ResponsiveImageComponent,
        DefaultCurrencyPipe,
        ToastsComponent,
        YoutubePlayerComponent,
        VimeoPlayerComponent,
        ReplacePipe,
        VideoPlayerComponent,
        SortPipe,
        UrlifyPipe,
        ColonSplitDirective,
        ScrollProgressDirective
    ],
    exports: [
        DecodeURIPipe,
        FilterPipe,
        InterceptLinksDirective,
        ImageLoadFadeInDirective,
        InViewportDirective,
        ScrollToElementDirective,
        AutoFocusDirective,
        FadeInComponent,
        SafePipe,
        PhoneNumberPipe,
        ResponsiveImageComponent,
        DefaultCurrencyPipe,
        ToastsComponent,
        YoutubePlayerComponent,
        VimeoPlayerComponent,
        ReplacePipe,
        VideoPlayerComponent,
        SortPipe,
        UrlifyPipe,
        ColonSplitDirective,
        ScrollProgressDirective
    ],
    providers: [CurrencyPipe, PhoneNumberPipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UtilsModule {}
