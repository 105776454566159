import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IMegaNavigationSpotResponse, INavigationResponse, NavigationLink, PAGE_TYPES } from '@impact/data';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { chevronRight, searchIcon } from '../../../icons';
import { SettingsService, SiteIds } from '../../core/settings.service';
import { NavigationService } from '../navigation.service';
import { megaNavigationColumnsMaxSize } from './mega-navigation.component';

export enum UsedCarsNavigationCategory {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TYPES = 'vehicletypes',
    BUDGET = 'budget'
}

@Component({
    selector: 'app-mega-navigation-used-cars',
    template: `
        <div class="mega-navigation-layout-wrapper">
            <div class="mega-navigation-layout-wrapper-inner">
                <div class="mega-navigation__content-header" [ngClass]="{'is-ready': isReady}">
                    <h2 class="mega-navigation-spot-layout__content-header-text">{{ data?.name }}</h2>
                    <ng-container [ngSwitch]="isB2BSite$ | async">
                        <ng-container *ngSwitchCase="true">
                            <a  [routerLink]="b2bUsedCarsOverviewPageUrl" class="button--primary">
                                <span>{{ 'buttons.search_all_used_cars_text' | translate }}</span>
                                <i class="button__icon">${searchIcon}</i>
                            </a>
                        </ng-container>
                        <ng-container *ngSwitchCase="false">
                            <a [ngSwitch]="site" [routerLink]="usedCarsOverviewPageUrl" class="button--primary">
                                <ng-container *ngSwitchCase="siteID.MOTORPOINT">
                                    <span>{{ 'buttons.mp_show_all_cars' | translate }}</span>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <span>{{ 'buttons.search_all_used_cars_text' | translate }}</span>
                                </ng-container>
                                <i class="button__icon">${searchIcon}</i>
                            </a>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="mega-navigation-content-layout" [ngClass]="{'is-ready': isReady}">
                    <div class="mega-navigation__content">
                        <!-- LEFT COLUMN -->
                        <div class="mega-navigation__content-column-left">
                            <ul class="mega-navigation__navigation-list mega-navigation__navigation-list--secondary" *ngIf="pagesNavigation">
                                <ng-container *ngFor="let page of pagesNavigation">
                                    <li [ngSwitch]="page.hasChildren" class="mega-navigation__navigation-list-item">
                                        <ng-container *ngSwitchCase="true">
                                            <button type="button" class="mega-navigation__navigation-list-link"
                                                [ngClass]="{'is-active': activeNavigationCategory === page.id}"
                                                (click)="showChildPages(page.id)"
                                                (mouseover)="showChildPages(page.id)">
                                                {{page?.name}}
                                            </button>
                                            <div class="mega-navigation__navigation-active-indicator">${chevronRight}</div>
                                        </ng-container>
                                        <a *ngSwitchCase="false"
                                           [routerLink]="page?.url"
                                           class="mega-navigation__navigation-list-link"
                                           (mouseover)="showChildPages('')">{{page.name}}</a>
                                     </li>
                                </ng-container>
                            </ul>
                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="mega-navigation__content-column-right">
                                <ng-container *ngTemplateOutlet="PageLinks; context: { items: activePagesNavigation }"></ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <!-- MEGA NAVIGATION SPOT -->
            <div class="mega-navigation__spot-wrapper" *ngIf="navigationSpot">
                <app-navigation-spot [data]="navigationSpot"></app-navigation-spot>
            </div>
        </div>

        <!-- TEMPLATES -->
        <ng-template #Level1Button let-text="text" let-category="category">
            <button type="button" class="mega-navigation__navigation-list-link"
                [ngClass]="{'is-active': activeNavigationCategory === category}"
                (click)="setActiveCategory(category)"
                (mouseover)="setActiveCategory(category)">
                {{text}}
            </button>
            <div class="mega-navigation__navigation-active-indicator">${chevronRight}</div>
        </ng-template>

        <ng-template #CategoryLinks let-items="items">
            <div class="mega-navigation__scroll-container">
                <ul class="mega-navigation-spot-layout__content-list"
                    [ngClass]="{
                        'double-column': items.length > columnItemsSize,
                        'triple-column': items.length > columnItemsSize * 2
                    }">
                    <li *ngFor="let link of items">
                        <a [routerLink]="link?.link?.url" [queryParams]="link?.link?.params">{{link?.linkText}}</a>
                    </li>
                </ul>
            </div>
        </ng-template>

        <!-- Pages link -->
        <ng-template #PageLinks let-items="items">
            <div class="mega-navigation__scroll-container">
                <ul class="mega-navigation-spot-layout__content-list"
                    [ngClass]="{
                        'double-column': items.length > columnItemsSize,
                        'triple-column': items.length > columnItemsSize * 2
                    }">
                    <li *ngFor="let page of items" [ngSwitch]="page.template === pageTypes.LINK_PAGE">
                        <a *ngSwitchCase="true" [routerLink]="page?.pageLink?.url" [queryParams]="page?.pageLink?.params">{{page?.name}}</a>
                        <a *ngSwitchCase="false" [routerLink]="page?.url" [queryParams]="page?.params">{{page?.name}}</a>
                    </li>
                </ul>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MegaNavigationUsedCarsComponent implements OnInit, OnDestroy {
    @Input() data: INavigationResponse;

    navigationData: any;
    navigationSpot: IMegaNavigationSpotResponse;
    primaryNavigation: any[] = [];
    secondaryNavigation: any[] = [];
    budgetNavigation: any[] = [];
    typesNavigation: any[] = [];
    pagesNavigation: INavigationResponse[] = [];
    activeNavigationCategory: string;
    activePagesNavigation: INavigationResponse[] = [];
    usedCarsNavigationCategory = UsedCarsNavigationCategory;
    columnItemsSize = megaNavigationColumnsMaxSize;

    siteID = SiteIds;
    site: string;

    usedCarsOverviewPageUrl: string;
    b2bUsedCarsOverviewPageUrl: string;
    isReady: boolean;
    isB2BSite$: Observable<boolean>;

    pageTypes = PAGE_TYPES;

    private unsubscribe = new Subject<void>();

    constructor(
        private cdr: ChangeDetectorRef,
        private settingsService: SettingsService,
        public navigationService: NavigationService
    ) {}

    ngOnInit() {
        this.navigationData = this.data.usedCarsPageNavigationData;
        this.navigationSpot = this.data.megaNavigationSpot;
        this.isB2BSite$ = this.navigationService.getSiteB2Bstate();

        if (this.data?.children) {
            this.data?.children.map((page) => {
                if (page.includeInNavigation) {
                    this.pagesNavigation.push({
                        ...page,
                        hasChildren: Boolean(page.children && page.children.length)
                    });
                }
            });
        }

        // Structuring navigation content
        this.settingsService.get()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.site = settings.siteNameID;
                this.usedCarsOverviewPageUrl = settings.globalPages.usedCarsOverviewPage.url;
                this.b2bUsedCarsOverviewPageUrl = settings.globalPages.b2bUsedCarsOverviewPage.url + '/bilmaerker';

                if (this.data?.usedCarsPageNavigationData?.primaryNavigation) {
                    // Update url's for primary navigation
                    for (const item of this.data.usedCarsPageNavigationData.primaryNavigation) {
                        item.link = new NavigationLink(this.usedCarsOverviewPageUrl, item.filterParameters);

                        this.primaryNavigation.push(item);
                    }
                }

                this.secondaryNavigation = this.data.children || [];

                if (this.data?.usedCarsPageNavigationData?.budgetsNavigation) {
                    // Update url's for primary navigation
                    for (const item of this.data.usedCarsPageNavigationData.budgetsNavigation) {
                        item.link = new NavigationLink(this.usedCarsOverviewPageUrl, item.filterParameters);

                        this.budgetNavigation.push(item);
                    }
                }

                if (this.data?.usedCarsPageNavigationData?.typesNavigation) {
                    // Update url's for primary navigation
                    for (const item of this.data.usedCarsPageNavigationData.typesNavigation) {
                        item.link = new NavigationLink(this.usedCarsOverviewPageUrl, item.filterParameters);

                        this.typesNavigation.push(item);
                    }
                }

                this.showChildPages(this.pagesNavigation[0].id);
                setTimeout(() => {
                    this.isReady = true;
                    this.cdr.markForCheck();
                }, 350);

                this.cdr.markForCheck();
            });
    }

    setActiveCategory(category: string) {
        this.activeNavigationCategory = category;
    }

    showChildPages(id: string): void {
        // Reset values
        if (id === '') {
            this.activeNavigationCategory = '';
            this.activePagesNavigation = [];
            return;
        }

        this.activeNavigationCategory = id;

        for (const page of this.pagesNavigation) {
            if (page.id === id) {
                this.activePagesNavigation = page.children || [];
                break;
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
