import { CurrencyPipe } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'defaultcurrency' })
export class DefaultCurrencyPipe implements PipeTransform {
    constructor(
        private currencyPipe: CurrencyPipe,
        @Inject(DEFAULT_CURRENCY_CODE) private currencyCode: string,
        @Inject(LOCALE_ID) private localeId: string
    ) {}

    transform(value?: number | string | null) {
        return this.currencyPipe.transform(value, this.currencyCode, 'symbol', '0.0-0', this.localeId);
    }
}
