import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LegalModule } from '../legal/legal.module';
import { NgbCustomDateFormatterService } from '../utils/helpers/dateformatter';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RichTextModule } from '../rich-text/rich-text.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomDatepickerI18n, I18n } from '../utils/helpers/datepicker-i18n';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        RichTextModule,
        NgbDatepickerModule,
        LegalModule,
    ],
    declarations: [],
    providers: [
        I18n,
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        { provide: NgbDateParserFormatter, useClass: NgbCustomDateFormatterService },
    ],
    exports: [FormsModule, ReactiveFormsModule],
})
export class FormModule {}
