import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INavigationResponse } from '@impact/data';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { Observable } from 'rxjs';
import * as icons from '../../../icons';
import { MobileAnimationState, mobileAnimation } from '../../utils/animations/mobile-menu.animations';
import { MobileMenuTypes } from './mobile-menu';
import { MobileNavigationService } from './mobile-navigation.service';

@Component({
    selector: 'app-mobile-used-cars-brands-nav',
    template: `
        <div [@slideContent]="animationState">
            <div class="mobile-menu__section-header">
                <h1>{{ 'navigation.select_brand' | translate }}</h1>
            </div>

            <div class="mobile-menu__scroll-container mobile-menu__scroll-container--sub-header">
                <ul class="mobile-menu__list">
                    <li *ngFor="let brand of navigation.children" class="mobile-menu-item">
                        <button class="button--minimal" (click)="navigateToUsedCarBrandModels(brand.id)">
                            <span class="mobile-menu-item__text">{{ brand.name }}</span>
                            <i class="mobile-menu-item__icon--nav" [innerHTML]="icons.chevronRight | safe: 'html'"></i>
                        </button>
                    </li>
                </ul>

                <!-- NAVIGATION SPOT -->
                <div class="mobile-menu__spot-wrapper" *ngIf="navigation.megaNavigationSpot">
                    <app-navigation-spot [data]="navigation.megaNavigationSpot"></app-navigation-spot>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [mobileAnimation],
})
export class MobileUsedCarsBrandsNavComponent {
    @Input() navigation: INavigationResponse;
    brandPageChildren$: Observable<INavigationResponse[]>;
    icons = icons;

    animationState: MobileAnimationState = 'enter';

    constructor(private overlayRef: ImpactOverlayRef, private mobileNavigationService: MobileNavigationService) {}

    navigateToUsedCarBrandModels(brandId: string) {
        this.mobileNavigationService.navigateByObject({
            navigation: this.navigation,
            type: MobileMenuTypes.UsedCarsBrandModels,
            brandNodeId: brandId,
        });
    }

    closeMenu() {
        this.overlayRef.close();
    }
}
