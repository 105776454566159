import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseSpot } from '@impact/data';

@Component({
    selector: 'app-spots',
    template: `
        <div
            appGenericSpot
            *ngFor="let spot of spots"
            [spotType]="spot.alias"
            [spotData]="spot"
            [columns]="columns"
        ></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpotsComponent {
    @Input()
    spots: BaseSpot[];

    @Input()
    columns: any;
}
