import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseSpot, ILink, ISeoFooterColumn } from '@impact/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { chevronDownIcon } from '../../../icons';
import { SettingsService } from '../../core/settings.service';
import { MetaService } from '../../meta/meta.service';
import { FeatureDetectionService } from '../../utils/helpers/feature-detection.service';
import { TrackingService } from '../../utils/tracking/tracking.service';

@Component({
    selector: 'app-seo-footer',
    template: `
        <footer>
            <app-spots
                class="layout-grid__spots"
                *ngIf="spots?.length"
                [spots]="spots"
                [columns]="1"
            ></app-spots>
            <div  class="seo-site-footer" *ngIf="SeoFooterItems?.length">
                <div class="seo-site-footer-top layout-wrapper">
                    <div class="container">
                        <div *ngIf="featureDetection.isBrowser()" class="seo-site-footer-top-columns__mobile">
                            <app-expansion-panel
                                class="seo-site-footer-top__column"
                                *ngFor="let menu of SeoFooterItems; trackBy: trackByColumn"
                            >
                                <h4 expansion-title class="seo-site-footer-top__menu-label">
                                    <app-link [link]="menu.seoHeaderLink"></app-link>
                                </h4>
                                <ng-container expansion-icon>${chevronDownIcon}</ng-container>
                                <ul class="seo-site-footer-top-menu-list" *ngIf="menu.seoLinks.length">
                                    <li
                                        class="seo-site-footer-top-menu-list__item"
                                        *ngFor="let item of menu.seoLinks; trackBy: trackByColumnLink"
                                    >
                                        <app-link [link]="item"></app-link>
                                    </li>
                                </ul>
                            </app-expansion-panel>
                        </div>
                        <div class="seo-site-footer-top-columns__desktop">
                            <ng-container *ngFor="let menu of SeoFooterItems; let i = index; trackBy: trackByColumn">
                                <div class="seo-site-footer-top__column">
                                    <h4 class="seo-site-footer-top__menu-label">
                                        <app-link [link]="menu.seoHeaderLink"></app-link>
                                    </h4>
                                    <ul class="seo-site-footer-top-menu-list">
                                        <!-- Display only first 10 links in the first column -->
                                        <ng-container *ngFor="let item of menu.seoLinks; let j = index; trackBy: trackByColumnLink">
                                            <li class="seo-site-footer-top-menu-list__item" *ngIf="i !== 0 || j < 15">
                                                <app-link [link]="item"></app-link>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                                <!-- Additional column for remaining links -->
                                <div class="seo-site-footer-top__column" *ngIf="i === 0 && menu.seoLinks.length > 15">
                                    <h4 class="seo-site-footer-top__menu-label seo-site-footer-top__hidden">
                                        <app-link [link]="menu.seoHeaderLink"></app-link>
                                    </h4>
                                    <ul class="seo-site-footer-top-menu-list">
                                        <li class="seo-site-footer-top-menu-list__item" *ngFor="let item of menu.seoLinks.slice(15); trackBy: trackByColumnLink">
                                            <app-link [link]="item"></app-link>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeoSiteFooterComponent implements OnInit, OnDestroy {
    SeoFooterItems: ISeoFooterColumn[];
    SeoFooterLinks: ILink[];
    spots: BaseSpot[];
    baseUrl: SafeResourceUrl = '';
    private unsubscribe = new Subject<void>();

    constructor(
        private settingsService: SettingsService,
        private metaService: MetaService,
        private sanitizer: DomSanitizer,
        private cd: ChangeDetectorRef,
        public featureDetection: FeatureDetectionService,
        private readonly trackingService: TrackingService,
    ) { }

    ngOnInit() {
        this.baseUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.metaService.getBaseUrl());

        this.settingsService
            .get()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.SeoFooterLinks = settings.footerLinks;
                this.SeoFooterItems = settings.seoFooterColumns;
                this.spots = settings.footerSpots;
                this.cd.markForCheck();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    trackByColumn(index: number, item: ISeoFooterColumn) {
        return item.seoHeaderLink.name || index;
    }

    trackByColumnLink(index: number, item: ILink) {
        return item.name || index;
    }

    showCookieBanner(): void {
        this.trackingService.showCookieBanner();
    }
}
