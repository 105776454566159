import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';

import { ComponentLoaderModule } from '../component-loader/component-loader.module';
import { RichTextModule } from '../rich-text/rich-text.module';
import { UtilsModule } from '../utils/utils.module';
import { MediaItemComponent } from './media-item.component';
import { MediaSliderNewComponent } from './media-slider-new.component';
import { MediaSliderComponent } from './media-slider.component';

@NgModule({
  declarations: [
    MediaSliderComponent,
    MediaSliderNewComponent,
    MediaItemComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    RichTextModule,
    UtilsModule,
    ComponentLoaderModule
  ],
  exports: [
    MediaSliderComponent,
    MediaSliderNewComponent,
    MediaItemComponent
  ]
})
export class MediaSliderModule { }
