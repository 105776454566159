import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IMegaNavigationSpotResponse, INavigationResponse, PAGE_TYPES } from '@impact/data';

import { chevronRight } from '../../../icons';
import { NavigationService } from '../navigation.service';
import { megaNavigationColumnsMaxSize } from './mega-navigation.component';

export enum ArticlesNavigationCategory {
    CATEGORIES = 'articleCategories',
    BRANDS = 'articleBrands',
}
@Component({
    selector: 'app-mega-navigation-articles-universe',
    template: `
        <div class="mega-navigation-layout-wrapper">
            <div class="mega-navigation-layout-wrapper-inner">
                <div class="mega-navigation__content-header" [ngClass]="{'is-ready': isReady}">
                    <h2 class="mega-navigation-spot-layout__content-header-text">{{ data?.name }}</h2>
                    <a [routerLink]="articlesOverviewPageUrl" class="button--primary">
                        <span>{{ 'articles.show_all_articles_link_text' | translate }}</span>
                    </a>
                </div>

                <div class="mega-navigation-content-layout" [ngClass]="{'is-ready': isReady}">
                    <div class="mega-navigation__content">
                        <!-- LEFT COLUMN -->
                        <div class="mega-navigation__content-column-left">
                            <ul class="mega-navigation__navigation-list">
                                <li class="mega-navigation__navigation-list-item" *ngIf="data?.articlesNavigationData?.articleCategoriesTagNavigationHeader">
                                    <ng-container *ngTemplateOutlet="Level1Button; context: { 
                                        text: data?.articlesNavigationData?.articleCategoriesTagNavigationHeader,
                                        category: articlesNavigationCategory.CATEGORIES
                                    }"></ng-container>
                                </li>
                                <li class="mega-navigation__navigation-list-item" *ngIf="data?.articlesNavigationData?.articleBrandsTagNavigationHeader">
                                    <ng-container *ngTemplateOutlet="Level1Button; context: { 
                                        text: data?.articlesNavigationData?.articleBrandsTagNavigationHeader,
                                        category: articlesNavigationCategory.BRANDS
                                    }"></ng-container>
                                </li>
                            </ul>
                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="mega-navigation__content-column-right">
                            <!-- Categories -->
                            <ng-container *ngIf="activeNavigationCategory === articlesNavigationCategory.CATEGORIES">
                                <ng-container *ngTemplateOutlet="CategoryLinks; context: { items: articlesCategoriesTagsList }"></ng-container>
                            </ng-container>

                            <!-- Brands -->
                            <ng-container *ngIf="activeNavigationCategory === articlesNavigationCategory.BRANDS">
                                <ng-container *ngTemplateOutlet="CategoryLinks; context: { items: articlesBrandsTagsList}"></ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <!-- MEGA NAVIGATION SPOT -->
            <div class="mega-navigation__spot-wrapper" *ngIf="navigationSpot">
                <app-navigation-spot [data]="navigationSpot"></app-navigation-spot>
            </div>
        </div>

        <!-- TEMPLATES -->
        <ng-template #Level1Button let-text="text" let-category="category">
            <button type="button" class="mega-navigation__navigation-list-link"
                [ngClass]="{'is-active': activeNavigationCategory === category}"
                (click)="setActiveCategory(category)"
                (mouseover)="setActiveCategory(category)">
                {{text}}
            </button>
            <div class="mega-navigation__navigation-active-indicator">${chevronRight}</div>
        </ng-template>

        <ng-template #CategoryHeader let-text="text" let-buttonText="buttonText">
            <div class="mega-navigation-spot-layout__content-header">
                <h2 *ngIf="text"
                    class="mega-navigation-spot-layout__content-header-text">
                    {{text}}
                </h2>
                <a [routerLink]="articlesOverviewPageUrl" class="button--primary">
                    {{buttonText}}
                </a>
            </div>
        </ng-template>

        <ng-template #CategoryLinks let-items="items">
            <ul class="mega-navigation-spot-layout__content-list"
                [ngClass]="{
                    'double-column': items.length > columnItemsSize,
                    'triple-column': items.length > columnItemsSize * 2
                }">
                <li class="" *ngFor="let link of items">
                    <a [routerLink]="articlesOverviewPageUrl" [queryParams]="link?.param">{{link?.displayName}}</a>
                </li>
            </ul>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MegaNavigationArticlesUniverseComponent implements OnChanges {
    @Input() data: INavigationResponse;

    navigationSpot: IMegaNavigationSpotResponse;
    pageTypes = PAGE_TYPES;
    articlesOverviewPageUrl: string;
    articlesCategoriesTagsList: any[];
    articlesBrandsTagsList: any[];
    articlesNavigationCategory = ArticlesNavigationCategory;

    activeNavigationCategory: string;
    columnItemsSize = megaNavigationColumnsMaxSize;
    isReady: boolean;

    constructor(
        private cdr: ChangeDetectorRef,
        public navigationService: NavigationService
    ) {
        this.setActiveCategory(ArticlesNavigationCategory.CATEGORIES);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const pageData = changes.data?.currentValue;
        this.articlesOverviewPageUrl = this.data.url;
        this.navigationSpot = changes.data.currentValue.megaNavigationSpot;

        this.data = pageData;
        this.articlesCategoriesTagsList = pageData.articlesNavigationData.articleCategoriesTagNavigation;
        this.articlesBrandsTagsList = pageData.articlesNavigationData.articleBrandTagsNavigation;
        this.isReady = false;
        this.cdr.markForCheck();

        setTimeout(() => {
            this.isReady = true;
            this.cdr.markForCheck();
        }, 350);

        this.cdr.markForCheck();
    }

    setActiveCategory(category: string) {
        this.activeNavigationCategory = category;
    }
}