import { Component, OnInit } from '@angular/core';

import { NavigationService } from '../../navigation/navigation.service';

@Component({
    selector: 'app-site-backdrop',
    template: `
        <div class="site-backdrop" (click)="hideBackdrop()" [ngClass]="{ 'is-active': isMegaNavigationActive }"></div>
    `
})

export class SiteBackdropComponent implements OnInit {
    isMegaNavigationActive: boolean;

    constructor(
        private navigationService: NavigationService
    ) {}

    ngOnInit() {
        this.navigationService.isMegaNavigationActive().subscribe((data) => {
            this.isMegaNavigationActive = data.isActive;
        });
    }

    hideBackdrop(): void {
        this.navigationService.showMegaNavigation(false);
    }
}