import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IGridContent } from '@impact/data';

export enum GridContext {
    PDP = 'pdp'
}

@Component({
    selector: 'app-umb-grid',
    template: `
        <app-umb-section
            *ngFor="let section of grid?.sections"
            class="layout-grid"
            [section]="section"
        ></app-umb-section>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UmbGridComponent {
    @Input() grid?: IGridContent;
    @Input() context?: GridContext;
    @HostBinding('class.no-padding') get noPaddingClass(): boolean {
        return this.context === GridContext.PDP;
    }

    GridContext = GridContext;
}
