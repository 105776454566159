import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SiteLogoComponent } from './site-logo.component';

@NgModule({
    declarations: [
        SiteLogoComponent
    ],
    imports: [CommonModule],
    exports: [
        SiteLogoComponent
    ],
})
export class LogosModule {}
