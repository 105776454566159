import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

import { SettingsService } from '../../core/settings.service';

const I18N_VALUES: any = {
    da: {
        weekdays: ['man.', 'tir.', 'ons.', 'tor.', 'fre.', 'lør.', 'søn.'],
        months: ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'],
    },
    en: {
        weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    },
    nb: {
        weekdays: ['man.', 'tir.', 'ons.', 'tor.', 'fre.', 'lør.', 'søn.'],
        months: ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'],
    },
    sv: {
        weekdays: ['mån', 'tis', 'ons', 'tors', 'fre', 'lör', 'sön'],
        months: ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'],
    },
};

@Injectable()
export class I18n {
    languageCode = 'da';

    constructor(private readonly settingsService: SettingsService) {
        this.getLanguageCode();
    }

    async getLanguageCode() {
        const settings = await this.settingsService.get().pipe(first()).toPromise();
        if (settings.languageCode) {
            this.languageCode = settings.languageCode.toLowerCase();
        }
    }
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    constructor(private readonly i18n: I18n) {
        super();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getWeekdayLabel(weekday: number, _width?: TranslationWidth): string {
        return I18N_VALUES[this.i18n.languageCode].weekdays[weekday - 1];
    }

    getMonthShortName(month: number): string {
        return I18N_VALUES[this.i18n.languageCode].months[month - 1];
    }

    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}
