import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { ImpactOverlayRef, OverlayService } from '@impactdk/ngx-overlay';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { STATUS } from './mobile-menu';
import { MobileMenuComponent } from './mobile-menu.component';

@Directive({
    selector: '[appMobileMenuTrigger]',
})
export class MobileMenuTriggerDirective implements OnDestroy {
    @Output() status: EventEmitter<STATUS> = new EventEmitter();
    @Input() hasMobileHeader = true;
    isOpen = false;
    mobileOverlay: ImpactOverlayRef;

    private readonly unsubscribe = new Subject<void>();

    constructor(private overlayService: OverlayService) {}

    @HostListener('click')
    toggleMenu(): void {
        if (this.isOpen) {
            this.mobileOverlay.close();
        } else {
            this.open();
        }
    }

    open(): void {
        this.status.emit(STATUS.OPENED);
        this.isOpen = true;
        this.mobileOverlay = this.overlayService.open(MobileMenuComponent, {
            fullHeight: true,
            hasBackdrop: true,
            positionHorizontal: {
                placement: 'right',
            },
        });

        this.mobileOverlay
            .afterClose()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.status.emit(STATUS.CLOSED);
                this.isOpen = false;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
