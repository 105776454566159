import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IGridArea } from '@impact/data';

@Component({
    selector: 'app-umb-area',
    template: `
        <app-spots
            class="layout-grid__spots"
            *ngIf="area.controls"
            [spots]="area.controls"
            [columns]="area.grid"
        ></app-spots>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UmbAreaComponent {
    @Input()
    area: IGridArea;

    @Input()
    columns: any;
}
