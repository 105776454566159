import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { leftFillNum } from '../../core/helpers';

export function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
    return !isNaN(toInteger(value));
}

@Injectable()
export class NgbCustomDateFormatterService extends NgbDateParserFormatter {
    // DOCUMENTAION: https://ng-bootstrap.github.io/#/components/datepicker/api#NgbDateParserFormatter
    // Link: https://stackblitz.com/edit/angular-1lvtbm?file=app%2Fdatepicker-popup.ts
    parse(value: string): NgbDateStruct | any {
        if (value) {
            const dateParts = value.trim().split('-');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { year: toInteger(dateParts[0]), month: 0, day: 0 };
            } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { year: toInteger(dateParts[0]), month: toInteger(dateParts[1]), day: 0 };
            } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return { year: toInteger(dateParts[0]), month: toInteger(dateParts[1]), day: toInteger(dateParts[2]) };
            }
        }

        return null;
    }

    format(date: NgbDateStruct): string {
        return date
            ? `${isNumber(date.day) ? leftFillNum(date.day, 2) : ''}-${isNumber(date.month) ? leftFillNum(date.month, 2) : ''}-${date.year}`
            : '';
    }
}
