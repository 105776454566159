import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { INavigationResponse } from '@impact/data';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';

import { chevronRight } from '../../../icons';

@Component({
    selector: 'app-mobile-menu-link-item',
    template: `
        <!-- Check if item has children or if it is has articlesOverviewPage template -->
        <ng-container *ngIf="item?.children?.length; then itemHasChildrenTmpl; else itemHasNoChildrenTmpl"></ng-container>

        <!-- item has children -->
        <ng-template #itemHasChildrenTmpl>
            <button class="button--minimal" (click)="btnClick.emit()">
                <span class="mobile-menu-item__text">{{ item.name }}</span>
                <i class="mobile-menu-item__icon--nav">${chevronRight}</i>
            </button>
        </ng-template>

        <!-- item has no children or has articlesOverviewPage template -->
        <ng-template #itemHasNoChildrenTmpl>
            <ng-container *ngIf="item.pageLink && item.pageLink?.isExternal; else internalLink">
                <a rel="noopener" [href]="item.pageLink.url" [target]="item.pageLink?.target" (click)="closeMenu()">
                    <span class="mobile-menu-item__text">{{ item.name }}</span>
                    <i class="mobile-menu-item__icon--nav">${chevronRight}</i>
                </a>
            </ng-container>
            <ng-template #internalLink>
                <a [routerLink]="[item.pageLink ? item.pageLink.url : item.url]" (click)="closeMenu()">
                    <span class="mobile-menu-item__text">{{ item.name }}</span>
                    <i class="mobile-menu-item__icon--nav">${chevronRight}</i>
                </a>
            </ng-template>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuLinkItemComponent {
    @Input() item: INavigationResponse;
    @Output() btnClick = new EventEmitter();

    constructor(private overlayRef: ImpactOverlayRef) {}

    closeMenu() {
        this.overlayRef.close();
    }
}
