import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICampaign, IDealerShips } from '@impact/data';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GlobalDataService {
    private globalData$ = new ReplaySubject<any>(1);
    private globalCampaigns$ = new ReplaySubject<any>(1);
    private globalReviews$ = new ReplaySubject<any>(1);
    private dealerships$ = new ReplaySubject<any>(1);
    private banks$ = new ReplaySubject(1);
    private globalNotifications$ = new ReplaySubject(1);

    constructor(private http: HttpClient) {
        this.http.get<any>('/api/globalData').subscribe((res: any) => {
            this.globalData$.next(res);
            this.globalCampaigns$.next(res.globalCampaigns);
            this.globalReviews$.next(res.globalReviews);
            this.dealerships$.next(res.dealerships);
            this.banks$.next(res.banks);
            this.globalNotifications$.next(res.notifications);
        });
    }

    // Global data (everything)
    getGlobalData(): Observable<any> {
        return this.globalData$.asObservable();
    }

    // Global campaigns data
    getGlobalCampaigns(): Observable<any> {
        return this.globalCampaigns$.asObservable();
    }

    // Global campaigns data
    getGlobalReviews(): Observable<any> {
        return this.globalReviews$.asObservable();
    }

    // Global dealerships data
    getDealerships(brandId?: string): Observable<IDealerShips> {
        if (brandId) {
            return this.dealerships$.asObservable().pipe(
                map((dealership) => {
                    return dealership;
                })
            );
        } else {
            return this.dealerships$.asObservable();
        }
    }

    // Global banks data
    getBanks(): Observable<any> {
        return this.banks$.asObservable();
    }

    // Global notifications
    getGlobalNotifications(): Observable<any> {
        return this.globalNotifications$.asObservable();
    }

    // Get specific campaign data
    getVehicleCampaignById(campaignId: string): Observable<any> {
        return this.getGlobalCampaigns().pipe(
            map((campaigns) => {
                return campaigns.children.find((item: any) => item.id === campaignId);
            })
        );
    }

    campaignIsActive(campaign: ICampaign): boolean {
        let isActive = false;
        const today = new Date().getTime();
        const startDate = campaign.startDate ? new Date(campaign.startDate).getTime() : undefined;
        const endDate = campaign.endDate ? new Date(campaign.endDate).getTime() : undefined;

        if (campaign.campaignIsActive) {
            if (startDate && endDate) {
                isActive = startDate <= today && endDate >= today;
            } else if (startDate) {
                isActive = startDate <= today;
            } else if (endDate) {
                isActive = endDate <= today;
            } else {
                isActive = true;
            }
        }

        return isActive;
    }
}
