import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';

import { MediaSliderModule } from '../media-slider/media-slider.module';
import { CarouselSpotComponent } from './carousel-spot/carousel-spot.component';
import { GenericSpotDirective } from './generic-spot.directive';
import { spotComponents } from './spot-components';
import { SpotsConfig } from './spots-config';
import { SpotsComponent } from './spots.component';

@NgModule({
    declarations: [
        ...spotComponents,
        GenericSpotDirective,
        SpotsComponent,
    ],
    imports: [
        CommonModule,
        MediaSliderModule,
        SwiperModule
    ],
    exports: [
        SpotsComponent,
        CarouselSpotComponent
    ],
})
export class SpotsUiModule {
    static forRoot(config: SpotsConfig): ModuleWithProviders<SpotsUiModule> {
        return {
            ngModule: SpotsUiModule,
            providers: [
                {
                    provide: SpotsConfig,
                    useValue: config,
                },
            ],
        };
    }
}
