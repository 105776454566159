import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DynamicRoutingModule } from '@impactdk/ngx-routing-utils';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { TranslateModule } from '@ngx-translate/core';

import { PageComponent } from './page.component';

@NgModule({
    declarations: [
        PageComponent
    ],
    imports: [
        CommonModule,
        DynamicRoutingModule,
        TranslateModule.forChild(),
        RouterModule,
        NgxJsonLdModule,
    ],
    exports: [
        PageComponent,
    ],
})
export class PageModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: PageModule,
        };
    }
}
