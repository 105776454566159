import { ISettingsResponse } from '@impact/data';
import { Observable } from 'rxjs';

export class SpotsConfig {
    settings$: Observable<ISettingsResponse>;
    googleMapsApiKey: string;
    hasUsedCars: boolean;
    iconType: 'filled' | 'light' | 'outlined' = 'filled';
    isMetaLinksVisibleMobile: boolean;
    isMobileBackMultiLevel: boolean;
    mobileOverlayOffsetTop = '65px';
    invertModelPageHeader = true;
    isSearchEnabled = false;
    isHeroFullViewport = false;
}
