import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { JsonLdComponent } from './json-ld.component';

@NgModule({
  declarations: [
    JsonLdComponent,
  ],
  imports: [
    CommonModule,
    NgxJsonLdModule
  ],
  exports: [
    JsonLdComponent,
  ]
})
export class JsonLdModule { }
