import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { INavigationResponse, PAGE_TYPES } from '@impact/data';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';

import * as icons from '../../../icons';
import { carImageFallbackIcon } from '../../../icons';
import { MobileAnimationState, mobileAnimation } from '../../utils/animations/mobile-menu.animations';

@Component({
    selector: 'app-mobile-new-cars-brand-models-nav',
    template: `
        <div [@slideContent]="animationState">
            <div class="mobile-menu__section-header">
                <h1>{{ navigation.name }}</h1>
            </div>

            <div class="mobile-menu__scroll-container mobile-menu__scroll-container--sub-header">
                <ul class="mobile-menu__list">
                    <li class="mobile-menu-item">
                        <a [routerLink]="navigation.url" (click)="closeMenu()">
                            <span class="mobile-menu-item__text">{{ 'car_data.see_all_from_brand' | translate }} {{ navigation.name }}</span>
                            <i class="mobile-menu-item__icon--nav" [innerHTML]="icons.chevronRight | safe: 'html'"></i>
                        </a>
                    </li>
                    <ng-container *ngFor="let item of navigation?.children">
                        <li class="mobile-menu-new-car-item" *ngIf="item.template === pageTypesEnum.VEHICLE_MODEL_PAGE">
                            <a [routerLink]="item?.url" class="mobile-menu-new-car-item__link" (click)="closeMenu()">
                                <div class="mobile-menu-new-car-item__details">
                                    <span class="model-name">{{ item?.vehicleModelData?.modelName }}</span>
                                    <div class="tag-n-price">
                                        <span class="model-price" *ngIf="item?.vehicleModelData?.startPrice">
                                            {{ 'car_data.starting_price_from_short' | translate }}
                                            {{ item?.vehicleModelData?.startPrice | defaultcurrency }}
                                        </span>
                                        <div *ngIf="item?.activeCampaign" class="campaign-tag">
                                            {{ 'car_data.campaign_label_text' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div [ngSwitch]="item?.vehicleModelData?.modelDisplayImage?.url !== undefined" class="mobile-menu-new-car-item__image-wrapper">
                                    <img
                                        *ngSwitchCase="true"
                                        [src]="item?.vehicleModelData?.modelDisplayImage?.url + '?width=300&format=webp'"
                                        [alt]="navigation.name + ' ' + item?.vehicleModelData?.modelName"
                                        loading="lazy"
                                        class="mobile-menu-new-car-item__image"
                                        appImageLoadFadeIn
                                    />
                                    <div *ngSwitchCase="false" class="mobile-menu-new-car-item__image--fallback">${carImageFallbackIcon}</div>
                                </div>
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [mobileAnimation],
})
export class MobileNewCarsBrandModelsNavComponent {
    @Input() navigation: INavigationResponse;
    @Output() navigate = new EventEmitter<INavigationResponse>();

    icons = icons;
    animationState: MobileAnimationState = 'enter';
    pageTypesEnum = PAGE_TYPES;

    constructor(private overlayRef: ImpactOverlayRef) {}

    closeMenu() {
        this.overlayRef.close();
    }
}
