import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { closeLightIcon } from '../../icons';
import { INotificationBarItem, NotificationBarService } from '../core/notification-bar.service';

@Component({
    selector: 'app-notifications-bar',
    template: `
        <ul class="site-notifications" *ngIf="activeNotifications?.length">
            <ng-container *ngFor="let item of activeNotifications">
                <li class="site-notification"
                    [ngStyle]="{
                        'background-color': item?.bgColor,
                        'color': item?.textColor
                    }">
                    <div class="site-notification__content">
                        <span>{{item?.text}}</span>
                        <app-link *ngIf="item.link" [link]="item.link" class="site-notification__link">
                         {{ 'generic.read_more_cta' | translate }}
                        </app-link>
                    </div>
                    <button class="site-notification__close" (click)="removeNotification(item)">
                        ${closeLightIcon}
                    </button>
                </li>
            </ng-container>
        </ul>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationBarComponent implements OnInit, OnDestroy {
    activeNotifications: INotificationBarItem[] = [];
    private readonly unsubscribe = new Subject();

    constructor(
        private notificationBarService: NotificationBarService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.notificationBarService.notifications$.pipe(takeUntil(this.unsubscribe)).subscribe((items) => {
            this.activeNotifications = items;
            this.cdr.markForCheck();
        });
    }

    removeNotification(item: any) {
        this.activeNotifications.splice(this.activeNotifications.indexOf(item), 1);
        this.notificationBarService.storeClosedNotification(item.id);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
