import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LinkModule } from '../../link/link.module';
import { NavigationSpotComponent } from './navigation-spot.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forChild(),
        LinkModule
    ],
    exports: [
        NavigationSpotComponent
    ],
    declarations: [
        NavigationSpotComponent
    ],
    providers: [],
})
export class NavigationSpotModule { }
