import { Injectable, OnDestroy } from '@angular/core';
import { INavigationResponse, ISettingsResponse, IUsedCarsNavigationLink, NavigationLink, PAGE_TYPES } from '@impact/data';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { SettingsService } from '../../core/settings.service';
import { NavigationService } from '../navigation.service';
import { IMobileMenu, MobileMenuTypes } from './mobile-menu';

@Injectable({
    providedIn: 'root',
})
export class MobileNavigationService implements OnDestroy {
    private currentMenu = new BehaviorSubject<IMobileMenu | undefined>(undefined);
    currentMenu$: Observable<IMobileMenu | undefined> = this.currentMenu.asObservable();
    rootMenu$: Observable<INavigationResponse[]>;
    usedCarsBudgetFilters$: Observable<IUsedCarsNavigationLink[]>;
    usedCarsTypeFilters$: Observable<IUsedCarsNavigationLink[]>;
    usedCarsCategoryFilters$: Observable<IUsedCarsNavigationLink[]>;

    private breadCrumbs: IMobileMenu[] = [];

    private unsubscribe = new Subject<void>();

    constructor(navigationService: NavigationService, settingsService: SettingsService) {
        navigationService
            .getSiteB2Bstate()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isB2B: boolean) => {
                if (isB2B) {
                    this.rootMenu$ = navigationService.getB2BNavigation().pipe(
                        map((navigation) => {
                            return navigation.filter((navigationItem) => !navigationItem.hideInMenu && navigationItem.includeInNavigation);
                        })
                    );
                } else {
                    this.rootMenu$ = navigationService.getMainNavigation().pipe(
                        map((navigation) => {
                            return navigation.filter((navigationItem) => !navigationItem.hideInMenu && navigationItem.includeInNavigation);
                        })
                    );
                }
            });

        const usedCarsPageUrl$ = settingsService
            .get()
            .pipe(map<ISettingsResponse, string>((settings) => settings.globalPages.usedCarsOverviewPage.url));

        const usedCarsNavigationData$ = navigationService
            .getMainNavigation()
            .pipe(
                map((mainNav) => mainNav.find((navigation) => navigation.template === PAGE_TYPES.USED_CARS_OVERVIEW_PAGE)?.usedCarsPageNavigationData)
            );

        this.usedCarsBudgetFilters$ = combineLatest([usedCarsPageUrl$, usedCarsNavigationData$]).pipe(
            map(([url, navigationData]) =>
                (navigationData?.budgetsNavigation ?? []).map((budget) => {
                    budget.link = new NavigationLink(url, budget.filterParameters);
                    return budget;
                })
            )
        );

        this.usedCarsTypeFilters$ = combineLatest([usedCarsPageUrl$, usedCarsNavigationData$]).pipe(
            map(([url, navigationData]) =>
                (navigationData?.typesNavigation ?? []).map((types) => {
                    types.link = new NavigationLink(url, types.filterParameters);
                    return types;
                })
            )
        );

        this.usedCarsCategoryFilters$ = combineLatest([usedCarsPageUrl$, usedCarsNavigationData$]).pipe(
            map(([url, navigationData]) =>
                (navigationData?.primaryNavigation ?? []).map((category) => {
                    category.link = new NavigationLink(url, category.filterParameters);
                    return category;
                })
            )
        );
    }

    navigateByType(navigation: INavigationResponse, type: MobileMenuTypes) {
        this.navigateByObject({ navigation, type });
    }

    navigateByTemplate(navigation: INavigationResponse, template: string) {
        let type: MobileMenuTypes = MobileMenuTypes.Default;

        switch (template) {
            case 'vehicleLeasingOverviewPage':
            case 'newCarsPage':
                type = MobileMenuTypes.BrandsOverview;
                break;
            case 'usedCarsOverviewPage':
                type = MobileMenuTypes.UsedCarsOptions;
                break;
            case 'articlesOverviewPage':
                type = MobileMenuTypes.Articles;
        }

        this.navigateByObject({
            type,
            navigation,
        });
    }

    navigateByObject(item: IMobileMenu) {
        this.currentMenu.next(item);

        this.breadCrumbs = [...this.breadCrumbs, item];
    }

    navigateToUsedCarsBrandModels(navigation: INavigationResponse, brandNodeId: string) {
        this.navigateByObject({
            navigation,
            type: MobileMenuTypes.UsedCarsBrandModels,
            brandNodeId,
        });
    }

    back() {
        if (this.breadCrumbs.length) {
            this.breadCrumbs.pop();
            this.currentMenu.next(this.breadCrumbs[this.breadCrumbs.length - 1]);
        } else {
            this.currentMenu.next(undefined);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
