import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RichTextModule } from '../rich-text/rich-text.module';
import { LegalComponent } from './legal.component';

@NgModule({
    declarations: [LegalComponent],
    imports: [CommonModule, RichTextModule],
    exports: [LegalComponent],
})
export class LegalModule {}
