import { Injectable } from '@angular/core';

import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';

export enum STORAGE_SERVICE_KEYS {
    USER_ACTIVE_BRAND_B2C = 'bnActiveBrandB2C',
    USER_ACTIVE_BRAND_B2B = 'bnActiveBrandB2B',
    USER_ACTIVE_PURCHASE_ORDER = 'bnActivePurchaseOrder',
    USER_ACTIVE_PURCHASE_ORDER_ID = 'bnActivePurchaseOrderId',
    USER_COMPLETED_PURCHASE_ORDER_ID = 'bnCompletedPurchaseOrderId',
    USER_ACTIVE_LEASING_CHECKOUT_DATA = 'bnLeasingActiveCheckoutData',
    USER_ACTIVE_LEASING_CHECKOUT_ID = 'bnLeasingActiveCheckoutOrderId',
    USER_ACTIVE_LEASING_PERSONAL_INFO_FORM = 'bnLeasingUserInfoForm',
    USER_EXCHANGE_CAR_LIST = 'bnExchangeCarList',
    BOOK_WORKSHOP = 'bnBookWorkshop',
    NOTIFICATIONS = 'bnClosedNotifications',

    // Leasing specific
    LEASING_DATA_KILOMETERS_PER_YEAR = 'leasingDataKilometersPerYear',
    LEASING_DATA_LOAN_DURATION = 'leasingDataLoanDuration',
}

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(private featureDetectionService: FeatureDetectionService) {}

    private getStorage(asSession?: boolean) {
        return asSession ? window.sessionStorage : window.localStorage;
    }

    public getItem(key: string, asSession?: boolean): string | undefined {
        if (this.featureDetectionService.isBrowser()) {
            return this.getStorage(asSession).getItem(key) || undefined;
        } else {
            return undefined;
        }
    }

    public removeItem(key: string, asSession?: boolean): void {
        if (this.featureDetectionService.isBrowser()) {
            this.getStorage(asSession).removeItem(key);
        }
    }

    public setItem(key: string, value: string, asSession?: boolean): void {
        if (this.featureDetectionService.isBrowser()) {
            this.getStorage(asSession).setItem(key, value);
        }
    }
}
