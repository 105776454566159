import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseSpot, IFooterColumn, IImage, ILink } from '@impact/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { chevronDownIcon } from '../../../icons';
import { SettingsService } from '../../core/settings.service';
import { MetaService } from '../../meta/meta.service';
import { FeatureDetectionService } from '../../utils/helpers/feature-detection.service';
import { TrackingService } from '../../utils/tracking/tracking.service';

@Component({
    selector: 'app-footer',
    template: `
        <footer>
            <div  class="site-footer">
                <div class="site-footer-top layout-wrapper">
                    <div class="container" *ngIf="footerItems?.length">
                        <div *ngIf="featureDetection.isBrowser()" class="site-footer-top-columns__mobile">
                            <app-expansion-panel
                                class="site-footer-top__column"
                                *ngFor="let menu of footerItems; trackBy: trackByColumn"
                            >
                                <h4 expansion-title class="site-footer-top__menu-label">
                                    <app-link [link]="menu.headerLink"></app-link>
                                </h4>
                                <ng-container expansion-icon>${chevronDownIcon}</ng-container>
                                <ul class="site-footer-top-menu-list" *ngIf="menu.links.length">
                                    <li
                                        class="site-footer-top-menu-list__item"
                                        *ngFor="let item of menu.links; trackBy: trackByColumnLink"
                                    >
                                        <app-link [link]="item"></app-link>
                                    </li>
                                </ul>
                            </app-expansion-panel>
                        </div>
                        <div class="site-footer-top-columns__desktop">
                            <div
                                class="site-footer-top__column"
                                *ngFor="let menu of footerItems; trackBy: trackByColumn"
                            >
                                <h4 class="site-footer-top__menu-label">
                                    <app-link [link]="menu.headerLink"></app-link>
                                </h4>
                                <ul class="site-footer-top-menu-list" *ngIf="menu.links.length">
                                    <li
                                        class="site-footer-top-menu-list__item"
                                        *ngFor="let item of menu.links; trackBy: trackByColumnLink"
                                    >
                                        <app-link [link]="item"></app-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="layout-wrapper">
                    <div class="site-footer-bottom">
                        <app-site-logo class="site-footer__logo"></app-site-logo>
                        <div class="site-footer-bottom__last-row">
                            <p class="site-footer-bottom__copyright" *ngIf="copyright">{{ copyright }}</p>
                            <nav class="site-footer-bottom-nav navbar">
                                <div class="site-footer-bottom-nav__item" *ngFor="let link of footerLinks">
                                    <app-link [link]="link"></app-link>
                                </div>
                                <div class="site-footer-bottom-nav__item">
                                    <button (click)="showCookieBanner()" class="button--as-link site-footer-bottom-nav__link">Cookiepolitik</button>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteFooterComponent implements OnInit, OnDestroy {
    logo: IImage;
    modelTitle: string;
    footerItems: IFooterColumn[];
    copyright: string;
    footerLinks: ILink[];
    baseUrl: SafeResourceUrl = '';
    private unsubscribe = new Subject<void>();

    constructor(
        private settingsService: SettingsService,
        private metaService: MetaService,
        private sanitizer: DomSanitizer,
        private cd: ChangeDetectorRef,
        public featureDetection: FeatureDetectionService,
        private readonly trackingService: TrackingService,
    ) { }

    ngOnInit() {
        this.baseUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.metaService.getBaseUrl());

        this.settingsService
            .get()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.copyright = settings.copyright;
                this.footerLinks = settings.footerLinks;
                this.footerItems = settings.footerColumns;
                this.cd.markForCheck();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    trackByColumn(index: number, item: IFooterColumn) {
        return item.headerLink.name || index;
    }

    trackByColumnLink(index: number, item: ILink) {
        return item.name || index;
    }

    showCookieBanner(): void {
        this.trackingService.showCookieBanner();
    }
}
