export const enum Theme {
    Default = 'default',
    Dark = 'dark',
    Light = 'light'
}

export enum HeaderThemes {
    DEFAULT = 'default',
    TRANSPARENT = 'transparent'
}
