import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './panel.component';
import { ButtonModule } from '../button/button.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [PanelComponent],
    imports: [ButtonModule, CommonModule, TranslateModule.forChild()],
    exports: [PanelComponent],
})
export class PanelModule {}
