import '@angular/common/locales/global/da';

import { DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    apiHttpStateInterceptorProvider,
    apiInterceptorProvider,
    GlobalDataService,
    JsonLdModule,
    MetaModule,
    NotificationBarModule,
    PageModule,
    PageTransitionModule,
    SeoSiteFooterModule,
    SettingsService,
    SiteFooterModule,
    SiteHeaderModule,
    SiteLayoutModule,
    SpotsConfig,
    TranslateLoaderService,
} from '@impact/ui';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const spotsConfigFactory = (settingsService: SettingsService) => {
    const model = new SpotsConfig();
    model.settings$ = settingsService.get();
    model.googleMapsApiKey = environment.googleMapsApiKey;
    model.hasUsedCars = true;
    model.iconType = 'light';
    model.hasUsedCars = true;
    model.isMetaLinksVisibleMobile = true;
    model.isMobileBackMultiLevel = true;
    model.invertModelPageHeader = false;
    return model;
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'bag-ncg' }),
        BrowserTransferStateModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLoaderService,
            },
        }),
        MetaModule,
        PageModule.forRoot(),
        SiteLayoutModule,
        SiteFooterModule,
        SeoSiteFooterModule,
        SiteHeaderModule,
        PageTransitionModule,
        NotificationBarModule,
        AppRoutingModule,
        JsonLdModule,
    ],
    providers: [
        {
            provide: SpotsConfig,
            useFactory: spotsConfigFactory,
            deps: [
                SettingsService,
                GlobalDataService
            ],
        },
        apiHttpStateInterceptorProvider,
        apiInterceptorProvider,
        {
            provide: LOCALE_ID,
            useValue: 'da-DK',
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'DKK'
        },
        DecimalPipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
