import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IImage } from '@impact/data';

import { StyleBreakpoints } from '../models/style-breakpoints';
import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';
import { mediaIsImage } from '../utils/helpers/image-helper';
import { IImageBreakpoints } from '../utils/responsive-image.component';
import { MediaSliderService } from './media-slider.service';

@Component({
    selector: 'app-media-item',
    template: `
        <ng-container [ngSwitch]="mediaIsImage(item)">
            <app-responsive-image
                *ngSwitchCase="true"
                [image]="item"
                [breakpoints]="imageBreakpoints"
                [lazyLoad]="lazyLoad"
                class="media-slider-content__image">
            </app-responsive-image>
            <app-video-player *ngSwitchCase="false"
                [video]="item?.url || item"
                [vimeoId]="vimeoId"
                [preload]="lazyLoad ? 'metadata' : 'auto'"
                [fallbackImage]="videoFallbackImage">
            </app-video-player>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaItemComponent implements AfterViewInit {
    @Input() item?: IImage;
    @Input() vimeoId?: string;
    @Input() videoFallbackImage?: IImage;
    @Input() lazyLoad = true;

    @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;

    mediaIsImage = mediaIsImage;
    imageBreakpoints: IImageBreakpoints[];

    constructor(
        private featureDetectionService: FeatureDetectionService,
        public mediaSliderService: MediaSliderService
    ) {
        this.imageBreakpoints = [
            {
                breakpoint: StyleBreakpoints.Implicit,
                width: 360,
                mode: 'crop',
            },
            {
                breakpoint: StyleBreakpoints.Xsmall,
                width: 460,
                mode: 'crop',
            },
            {
                breakpoint: StyleBreakpoints.Small,
                width: 768,
                mode: 'crop',
            },
            {
                breakpoint: StyleBreakpoints.Medium,
                width: 1024,
                mode: 'crop',
            },
            {
                breakpoint: StyleBreakpoints.Large,
                width: 1200,
                mode: 'crop',
            },
            {
                breakpoint: StyleBreakpoints.Xlarge,
                width: 1880,
                mode: 'crop',
            },
        ];
    }

    ngAfterViewInit() {
        // Ensure video is muted - fixes autoplay issues
        if (this.featureDetectionService.isBrowser() && this.videoElement) {
            this.videoElement.nativeElement.muted = true;
            this.videoElement.nativeElement.play();
        }
    }
}
