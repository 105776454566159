import { ILink } from "../generic/link.interface";
import { BaseSpot } from "./base-spot.model";

export enum TagColors {
    Blue = 'Blue',
    Green = 'Green'
}

export class WorkshopServiceSpot extends BaseSpot {
    title: string;
    description: string;
    bookWorkshopButton?: ILink;
    contactButton?: ILink;
    bottomText: string;
    price: string;
    tag?: string;
    tagColor?: TagColors;
}