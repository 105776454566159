import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ButtonModule } from '../../button/button.module';
import { LogosModule } from '../../logos/logos.module';
import { MainNavigationModule } from '../../navigation/main-navigation/main-navigation.module';
import { MegaNavigationModule } from '../../navigation/mega-navigation/mega-navigation.module';
import { MetaMenuModule } from '../../navigation/meta-menu/meta-menu.module';
import { NavigationModule } from '../../navigation/navigation.module';
import { UtilsModule } from '../../utils/utils.module';
import { SiteHeaderComponent } from './site-header.component';

@NgModule({
    imports: [
        CommonModule,
        ButtonModule,
        UtilsModule,
        LogosModule,
        MainNavigationModule,
        MegaNavigationModule,
        NavigationModule,
        MetaMenuModule,
        RouterModule
    ],
    exports: [
        SiteHeaderComponent
    ],
    declarations: [
        SiteHeaderComponent,
    ],
    providers: [],
})
export class SiteHeaderModule { }
