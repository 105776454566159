import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ILink } from '@impact/data';

@Component({
    selector: 'app-link',
    template: `
        <ng-container *ngIf="link.isExternal; else internalLink">
            <a rel="noopener" [href]="link.url" [target]="link.target"
                [class]="classString">
                <ng-container *ngTemplateOutlet="linkContent"></ng-container>
            </a>
        </ng-container>
        <ng-template #internalLink>
            <a [routerLink]="[link?.url]" [queryParams]="link?.urlParams"
                [class]="classString">
                <ng-container *ngTemplateOutlet="linkContent"></ng-container>
            </a>
        </ng-template>

        <ng-template #linkContent>
            <ng-container *ngIf="showLinkText" [ngSwitch]="linkText !== ''">
                <ng-container *ngSwitchCase="true">{{ linkText }}</ng-container>
                <ng-container *ngSwitchCase="false">{{ link.name }}</ng-container>
            </ng-container>
            <ng-content *ngIf="!showLinkText"></ng-content>
        </ng-template>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent {
    @Input() link: ILink;
    @Input() linkText? = '';
    @Input() classString?: string;
    @Input() showLinkText?: boolean = true;
}
