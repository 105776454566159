import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INavigationResponse, PAGE_TYPES } from '@impact/data';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { first, switchMap, take } from 'rxjs/operators';

import { removeDuplicatesById } from '../core/helpers';
import { SettingsService } from '../core/settings.service';
import { STORAGE_SERVICE_KEYS, StorageService } from '../core/storage.service';
import { BODY_CLASS, FeatureDetectionService } from '../utils/helpers/feature-detection.service';

export interface IShowMegaNavigation {
    isActive: boolean;
    item: INavigationResponse | undefined;
}

export enum SITE_CONTEXT {
    B2C = 'b2c',
    B2B = 'b2b'
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private mainNavigationTree$ = new ReplaySubject<INavigationResponse[]>(1);
    private b2bNavigationTree$ = new ReplaySubject<INavigationResponse[]>(1);
    private isB2BSite$ = new ReplaySubject<boolean>(1);
    private isLoadingPageData$ = new ReplaySubject<boolean>(1);
    private showMegaNavigation$ = new BehaviorSubject<IShowMegaNavigation>({
        isActive: false,
        item: undefined
    });
    private activeB2CBrand: string|undefined;
    activeB2CBrand$ = new ReplaySubject<string|undefined>(1);
    private activeB2BBrand: string|undefined;
    activeB2BBrand$ = new ReplaySubject<string|undefined>(1);

    // Current site context: B2C or B2B
    siteContext: string;
    isB2BSite: boolean;
    currentRouteUrl$ = new BehaviorSubject<string>('');
    usedCarsNavigation: INavigationResponse[];
    usedCarsNavigation$ = new ReplaySubject<INavigationResponse[]>(1);

    constructor(
        private http: HttpClient,
        private settingsService: SettingsService,
        private featureDetectionService: FeatureDetectionService,
        private storageService: StorageService
    ) {
        // Active brand is the user's last visited car brand
        this.activeB2CBrand = this.storageService.getItem(STORAGE_SERVICE_KEYS.USER_ACTIVE_BRAND_B2C) ?? undefined;
        this.activeB2BBrand = this.storageService.getItem(STORAGE_SERVICE_KEYS.USER_ACTIVE_BRAND_B2B) ?? undefined;

        this.getSiteB2Bstate().pipe(take(1)).subscribe(() => {
            if (this.activeB2CBrand && this.siteContext === SITE_CONTEXT.B2C) {
                this.setActiveBrandTag(this.activeB2CBrand);
            } if (this.activeB2BBrand && this.siteContext === SITE_CONTEXT.B2B) {
                this.setActiveBrandTag(this.activeB2BBrand);
            }
        });
    }

    initNavigation(): void {
        this.http.get<INavigationResponse[]>('/api/navigation', {
            params: {
                levels: '10'
            }
        })
        .subscribe(navigation => {
            // Add model types filters
            navigation.map((node) => {
                if (node.template === PAGE_TYPES.NEW_CARS_OVERVIEW_PAGE) {
                    if (node.children) {
                        node.children.map((brandItem) => {
                            const modelFilters: any[] = [];

                            brandItem.children?.map((model) => {
                                brandItem.newCarsModelFilters = [];

                                if (model.vehicleModelData && model.vehicleModelData.vehicleTypes) {
                                    model.vehicleModelData.vehicleTypes.map((vehicleType: any) => {
                                        modelFilters.push(vehicleType);
                                    });
                                }
                            });

                            brandItem.newCarsModelFilters = removeDuplicatesById(modelFilters);
                        });
                    }
                }
                if (node.template === PAGE_TYPES.USED_CARS_OVERVIEW_PAGE) {
                    if (node.children) {
                        this.usedCarsNavigation$.next(node.children);
                    }
                }
            });

            this.mainNavigationTree$.next(navigation);
        });
    }

    initB2BNavigation(): void {
        this.settingsService.get().pipe(
            switchMap((settings) => {

                if (!settings.globalPages?.b2bSectionPageId) {
                    return EMPTY;
                }

                return this.http
                    .get<INavigationResponse>(`/api/navigation/${settings.globalPages.b2bSectionPageId}`, {
                        params: {
                            levels: '10'
                        }
                    });
            }))
            .subscribe(navigation => {
                this.b2bNavigationTree$.next(navigation.children);
            });
    }

    getMainNavigation(): Observable<INavigationResponse[]> {
        return this.mainNavigationTree$.asObservable().pipe(first());
    }

    getB2BNavigation(): Observable<INavigationResponse[]> {
        return this.b2bNavigationTree$.asObservable().pipe(first());
    }

    // Sets the site current B2B/B2C state
    setSiteB2BState(isB2B: boolean): void {
        this.siteContext = isB2B ? SITE_CONTEXT.B2B : SITE_CONTEXT.B2C;
        this.isB2BSite = isB2B;
        this.isB2BSite$.next(isB2B);
    }

    // Get the site current B2B/B2C state
    getSiteB2Bstate(): Observable<boolean> {
        return this.isB2BSite$.asObservable();
    }

    setLoadingPageData(state: boolean): void{
        this.isLoadingPageData$.next(state);
    }

    getLoadingPageDataState(): Observable<boolean> {
        return this.isLoadingPageData$.asObservable();
    }

    showMegaNavigation(show: boolean, item?: INavigationResponse) {
        if (show && item) {
            this.featureDetectionService.addBodyClass(BODY_CLASS.MEGA_NAVIGATION_ACTIVE);
            this.showMegaNavigation$.next({
                isActive: true,
                item
            });
        } else {
            this.featureDetectionService.removeBodyClass(BODY_CLASS.MEGA_NAVIGATION_ACTIVE);
            this.showMegaNavigation$.next({
                isActive: false,
                item: undefined
            });
        }
    }

    isMegaNavigationActive(): Observable<any> {
        return this.showMegaNavigation$;
    }

    //
    // Helper methods

    // Returns the top level of a path, to be able to mark parent item in navigation
    getRouteTopLevel(url: string) {
        if (!url) {
            return;
        }

        const cleanUrl: string = url.split('?')[0];

        return cleanUrl.split('/').slice(0,2).join('/');
    }

    // Emits current route url, to components wanting to know what route is initialising
    emitCurrentRouteUrl(url: string): void {
        this.currentRouteUrl$.next(url);
    }

    // Active brand logic
    setActiveBrandTag(brandName: string) {
        if (brandName) {
            switch (this.isB2BSite) {
                case true:
                    this.activeB2BBrand = brandName;
                    this.storageService.setItem(STORAGE_SERVICE_KEYS.USER_ACTIVE_BRAND_B2B, brandName);
                    this.activeB2BBrand$.next(this.activeB2BBrand);
                    break;
                default:
                    this.activeB2CBrand = brandName;
                    this.storageService.setItem(STORAGE_SERVICE_KEYS.USER_ACTIVE_BRAND_B2C, brandName);
                    this.activeB2CBrand$.next(this.activeB2CBrand);
                    break;
            }
        }
    }

    getActiveBrandTag(): void {
        this.activeB2CBrand = this.activeB2CBrand ? this.activeB2CBrand : this.storageService.getItem(STORAGE_SERVICE_KEYS.USER_ACTIVE_BRAND_B2C);
        this.activeB2BBrand = this.activeB2BBrand ? this.activeB2BBrand : this.storageService.getItem(STORAGE_SERVICE_KEYS.USER_ACTIVE_BRAND_B2B);

        this.activeB2BBrand$.next(this.activeB2BBrand);
        this.activeB2CBrand$.next(this.activeB2CBrand);
    }
}
