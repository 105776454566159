import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MobileOverlayHeaderComponent } from './mobile-overlay-header.component';

@NgModule({
    declarations: [
        MobileOverlayHeaderComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        MobileOverlayHeaderComponent
    ]
})
export class MobileOverlayModule {}
