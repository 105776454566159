import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, UrlSegment } from '@angular/router';
import {
    AppHttpErrorResponse,
    IErrorPageResponse,
    ILinkPageResponse,
    IRedirectResponse,
    PAGE_TYPES,
    PageResponse,
} from '@impact/data';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Request as ExpressRequest, Response as ExpressResponse } from 'express';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';

import { SettingsService } from '../core/settings.service';
import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';
import { NavigationService } from '../navigation/navigation.service';

const PERMANENT_REDIRECT = 301;
const NOT_FOUND = 404;
const HTTP_GONE = 410;
const SERVER_ERROR = 500;

@Injectable()
export class PageResolve implements Resolve<PageResponse> {

    constructor(
        private http: HttpClient,
        private settingsService: SettingsService,
        private featureDetectionService: FeatureDetectionService,
        private navigationService: NavigationService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        @Optional()
        @Inject(REQUEST)
        private request?: ExpressRequest,
        @Optional()
        @Inject(RESPONSE)
        private response?: ExpressResponse
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        let sanitizedUrl;
        let ssrQueryParams;
        const urlSegmentsObject = route.url;

        // #1
        // Intercept malformed urls to make sure that we do not send malformed "bilmagasin" requests to the BFF
        // Can be removed if we figure out where these malformed urls are coming from, and are eliminated
        //
        // Example of malformed urls handled here:
        // Starts with /bilmagasin%23/: /bilmagasin%23/kia-praesenterer-den-nye-sportage/hvorfor-skal-jeg-lakforsegle-min-bil/hvilke-krav-er-der-til-sikkerhedsudstyr/hvordan-pakker-jeg-til-bilferien/ti-tips-til-din-bil-inden-ferien
        if (urlSegmentsObject.length && (urlSegmentsObject[0].path.indexOf('%23') !== -1 || urlSegmentsObject[0].path.indexOf('#') !== -1)) {
            return this._notFoundPage();
        } else {
            sanitizedUrl = urlSegmentsObject.length === 0 ? '/' : this._sanitizeUrlSegment(urlSegmentsObject);
            ssrQueryParams =  this.request?.originalUrl.split('?')[1];
        }

        // If not a malformed url, we resolve it
        return this.resolveUrl(sanitizedUrl,  ssrQueryParams);
    }

    resolveUrl(url: string, ssrQuery?: string | undefined): Observable<PageResponse> {
        /*
            In order to handle redirect lookup with url's containing query params,
            we need to pass the whole query param as an exact string.
            We need to check both during SSR and CSR.
            CSR: Angular router is not really helpfull with that, so we just use document.location.search.
            SSR: When doing SSR we can't access a document/window, so we use the injected "request".
            This was done in the method that parsed params to this function.

            Now we can pass the complete querystring as a parameter on any requests.
            Notice that we encodeURIComponent the params, to ensure a safe url.
            This will be decoded later when needed.
            It's only used for checking the redirect list for exact key matches.
            The redirect will handle removing any query params as they are not needed when the redirect is triggered.
        */

        // #2
        // Intercept malformed urls to make sure that we do not send malformed "bilmagasin" requests to the BFF
        // Can be removed if we figure out where these malformed urls are coming from, and are eliminated
        //
        // Example of malformed urls handled here:
        // Starts with /bilmagasin%3Fcategory/: /bilmagasin%3Fcategory/ny-e-transit-udsat-for-ekstreme-forhold/videotest-volvo-xc40-pa-ren-el/kia-forbedrer-ny-app/kia-praesenterer-den-nye-sportage/roserne-vaelter-ned-over-renault-arkana/renault-5-karet-til-arets-konceptbil
        if (url.indexOf('?') !== -1 || url.indexOf('%3f') !== -1) {
            // Is this parameter is alone, we redirect with new status
            const hasValue = url.split('?')[1].split('=')[1] !== undefined;

            if (!hasValue) {
                url = url.split('?')[0];
                return this._notFoundPage();
            }
        } else {
            // Make sure that used car pages do not trigger the 404
            const umbracoUrlCharactersRegexp = new RegExp(/[&\\#,+()$~%.'":*?<>{}]/g);
            url = url.toLowerCase().replace(umbracoUrlCharactersRegexp, '');
        }

        let queryString;
        if (ssrQuery) {
            queryString = '?' + ssrQuery;
        }
        else {
            queryString = this.document.location.search ? this.document.location.search : undefined;
        }
        const requestObj: any = {
            url
        };

        if (queryString) {
            requestObj.params = encodeURIComponent(queryString);
        }

        const isB2Bpage = requestObj.url.indexOf('/erhverv') === 0;
        this.navigationService.setSiteB2BState(isB2Bpage);

        return this.http
            .get<PageResponse>('/api/page/url', {
                params: requestObj,
            })
            .pipe(
                // PageResponse modifications
                switchMap((page) => {
                    // Make sure link pages are not accessible
                    if (page.template === PAGE_TYPES.LINK_PAGE) {
                        const castPage = page as ILinkPageResponse;

                        if (castPage.pageLink?.url) {
                            return this._redirectResponse(castPage.pageLink.url);
                        }

                        return this._notFoundPage();
                    }

                    page.isB2BPage = isB2Bpage;

                    return of(page);
                }),
                // Handle 404 and server errors
                catchError((err: AppHttpErrorResponse) => {
                    if (err && err.error && err.error.status) {
                        switch (err.error.status) {
                            case NOT_FOUND:
                                return this._notFoundPage();

                            case HTTP_GONE:
                                if (err.error.error && this._isRedirectResponse(err.error.error)) {
                                    const redirectResponse = err.error.error;
                                    // preserveQuery is set to false for all redirects for now.
                                    return this._redirectResponse(redirectResponse.location, redirectResponse.statusCode, false);
                                }
                                break;
                        }
                    }

                    return this._errorPage();
                })
            );
    }

    private _sanitizeUrlSegment(url: UrlSegment[]) {
        return url.reduce((acc, segment) => {
            return `${acc}/${segment.path}`;
        }, '');
    }

    private _isRedirectResponse(obj: any): obj is IRedirectResponse {
        return !!(obj as IRedirectResponse).location;
    }

    private _redirectResponse(location: string, statusCode: number = PERMANENT_REDIRECT, preserveQuery = true): Observable<never> {
        const isAbsoluteUrl = location.indexOf('http') === 0;

        if (preserveQuery && location.indexOf('?') === -1) {
            let search = '';

            if (this.request) {
                const searchSplit = this.request.url.split('?');
                if (searchSplit.length > 1) {
                    search = `?${searchSplit[1]}`;
                }
            } else if (this.featureDetectionService.isBrowser()) {
                search = window.location.search;
            }

            location += search;
        }

        // Browser
        if (this.featureDetectionService.isBrowser()) {
            if (isAbsoluteUrl) {
                window.location.href = location;
            } else {
                this.router.navigateByUrl(location);
            }
        }
        // Server
        else if (this.request && this.response) {
            location = isAbsoluteUrl ? location : `${this.request.protocol}://${this.request.get('host')}${location}`;
            if (this.response.writableEnded) {
                const req: any = this.request;
                req._r_count = (req._r_count || 0) + 1;

                console.warn('Attempted to redirect on a finished response. From', this.request.url, 'to', location);

                if (req._r_count > 10) {
                    console.error('Detected a redirection loop. killing the nodejs process');
                    process.exit(1);
                }
            } else {
                console.warn('Redirecting from', this.request.url, 'to', location, 'with code', statusCode);
                this.response.redirect(statusCode, location);
                this.response.end();
            }
        } else {
            return throwError(new Error(`Something went wrong and could not redirect to ${location}`));
        }

        return EMPTY;
    }

    private _notFoundPage(): Observable<PageResponse> {
        return this.settingsService.get().pipe(
            first(),
            switchMap((settings) => {
                if (this.response) {
                    this.response.status(NOT_FOUND);
                }

                if (!settings?.globalPages?.notFoundPage?.url) {
                    return throwError(new Error('No not found page'));
                }

                return this.http.get<PageResponse>('/api/page/url', {
                    params: {
                        url: settings.globalPages.notFoundPage.url,
                    },
                });
            }),
            // In case our 404 page doesn't work, we show the error page
            catchError(() => {
                return this._errorPage();
            })
        );
    }

    private _errorPage(): Observable<PageResponse> {
        if (this.response) {
            this.response.status(SERVER_ERROR);
            this.response.setHeader('Cache-Control', 'no-cache');
            this.response.setHeader('max-age', '-5000');
        }

        const errorPageData: IErrorPageResponse = {
            template: PAGE_TYPES.ERROR_PAGE,
            parentId: '',
            breadcrumbs: [],
            id: '',
            name: '',
            url: '',
            content: {
                grid: {
                    name: '',
                    sections: [],
                },
            },
            meta: {
                title: 'Error - something went wrong',
                description: '',
                excludeFromRobots: true,
                includeInNavigation: false,
                includeInSearch: false,
                includeInFooter: false,
                hideInMenu: false,
            },
            updateDate: new Date().toISOString(),
            createDate: new Date().toISOString(),
            isB2BPage: false,
            transparentSiteHeader: false
        };

        console.log('Error page: cancel all initiation of site');

        return of(errorPageData);
    }
}
