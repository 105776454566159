import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IGridRow } from '@impact/data';

import { getColorContrast, validateUmbracoBoolean } from '../../core/helpers';

@Component({
    selector: 'app-umb-row',
    template: `
        <app-umb-area
            *ngFor="let area of row.areas"
            class="layout-grid__area"
            [ngClass]="rowClasses"
            [ngStyle]="{ 'background-color': backgroundColor }"
            [id]="sectionAnchorId"
            [area]="area"
        ></app-umb-area>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UmbRowComponent implements OnInit {
    @Input()
    row: IGridRow;
    rowClasses: string[] = [];
    backgroundColor: string;
    sectionAnchorId: string;

    ngOnInit(): void {
        // Add spacing class
        if (this.row?.config && this.row?.config?.spacing) {
            this.rowClasses.push(`content-spacing-${this.row.config.spacing}`);
        }

        // Add narrow class
        if (this.row.config && validateUmbracoBoolean(this.row.config.narrow)) {
            this.rowClasses.push('is-narrow');
        }

        //Addy nospacing class
         if (this.row.config && validateUmbracoBoolean(this.row.config.nospacing)) {
            this.rowClasses.push('no-spacing');
        }

        // Add background color class
        if (this.row.config) {
            if (this.row.config?.extraSpacing && this.row.config?.extraSpacing !== 'none') {
                this.rowClasses.push(`extra-spacing-${this.row.config?.extraSpacing}`);
            }

            if (this.row?.config?.pageNavigationAnchorId) {
                this.sectionAnchorId = this.row?.config?.pageNavigationAnchorId;
            }
        }

        if (!validateUmbracoBoolean(this.row.config?.disableColors)) {
            if (this.row.config?.backgroundColor?.value) {
                this.rowClasses.push('has-background-color');
                this.backgroundColor = `#${this.row.config?.backgroundColor?.value}`;
            }

            if (this.row.config?.colorPickr) {
                this.rowClasses.push('has-background-color');
                this.backgroundColor = this.row.config?.colorPickr;
            }

            if (this.backgroundColor) {
                const contrastColorClass = getColorContrast(this.backgroundColor);
                this.rowClasses.push(contrastColorClass);
            }
        }
    }
}
