import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ToastService } from '../../core/toast.service';

@Component({
    selector: 'app-toasts',
    template: `
        <ngb-toast
            class="toast"
            *ngFor="let toast of toastService.toasts$ | async"
            [delay]="toast.delay || 5000"
            (hide)="toastService.remove(toast)"
        >
            {{toast.text}}
        </ngb-toast>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastsComponent {
    constructor(public toastService: ToastService) { }
}