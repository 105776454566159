import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormModule } from '../form/form.module';
import { SiteBackdropComponent } from './site-backdrop/site-backrop.component';

@NgModule({
  declarations: [
    SiteBackdropComponent,
  ],
  imports: [
    CommonModule,
    FormModule
  ],
  exports: [
    SiteBackdropComponent,
  ]
})
export class SiteLayoutModule { }
