import { animate, state, style, transition, trigger } from '@angular/animations';

export enum AnimationStates {
    SHOW = 'show',
    HIDE = 'hide'
}

const ANIMATION_TIMING = '500ms';
const ANIMATION_TIMING_CONTENT = '500ms cubic-bezier(0.16, 1, 0.3, 1)';

export const megaNavigationAnimation = [
    trigger('megaNavigationShow', [
        state(AnimationStates.HIDE, style({ height: '0' })),
        state(AnimationStates.SHOW,
            style({ height: '{{expandHeight}}' }),
            { params: { expandHeight: '*' }}
        ),

        transition(`${AnimationStates.HIDE} => ${AnimationStates.SHOW}`, animate(ANIMATION_TIMING)),
        transition(`${AnimationStates.SHOW} => ${AnimationStates.HIDE}`, animate(ANIMATION_TIMING))
    ]),
    trigger('megaNavigationContentShow', [
        state(AnimationStates.HIDE, style({
            opacity: 0,
        })),
        state(AnimationStates.SHOW, style({
            opacity: 1,
        })),

        transition(`${AnimationStates.HIDE} => ${AnimationStates.SHOW}`, animate(ANIMATION_TIMING_CONTENT)),
        transition(`${AnimationStates.SHOW} => ${AnimationStates.HIDE}`, animate('0s'))
    ])
];
