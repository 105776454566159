import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INavigationResponse, IUsedCarsNavigationLink } from '@impact/data';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { Observable } from 'rxjs';

import * as icons from '../../../icons';
import { mobileAnimation, MobileAnimationState } from '../../utils/animations/mobile-menu.animations';
import { MobileNavigationService } from './mobile-navigation.service';

import { chevronRight } from '../../../icons';

@Component({
    selector: 'app-mobile-used-cars-categories-nav',
    template: `
        <div [@slideContent]="animationState">
            <div class="mobile-menu__section-header">
                <h1>{{ 'navigation.select_category' | translate }}</h1>
            </div>

            <div class="mobile-menu__scroll-container mobile-menu__scroll-container--sub-header">
                <ul class="mobile-menu__list">
                    <li class="mobile-menu-item">
                        <a [routerLink]="navigation.url" (click)="overlayRef.close()">
                            <span class="mobile-menu-item__text">{{ 'car_data.see_all_from_brand' | translate }} {{ navigation.name }}</span>
                            <i class="mobile-menu-item__icon--nav">${chevronRight}</i>
                        </a>
                    </li>
                    <li class="mobile-menu-item" *ngFor="let category of categories$ | async">
                        <a [routerLink]="category.link?.url" [queryParams]="category.link ? category.link.params : {}" (click)="overlayRef.close()">
                            <span class="mobile-menu-item__text">{{ category.linkText }}</span>
                            <i class="mobile-menu-item__icon--nav">${chevronRight}</i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [mobileAnimation],
})
export class MobileUsedCarsCategoriesNavComponent {
    @Input() navigation: INavigationResponse;
    categories$: Observable<IUsedCarsNavigationLink[]>;

    icons = icons;

    animationState: MobileAnimationState = 'enter';

    constructor(public overlayRef: ImpactOverlayRef, private mobileNavigationService: MobileNavigationService) {
        this.categories$ = this.mobileNavigationService.usedCarsCategoryFilters$;
    }
}
