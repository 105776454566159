import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IGridSection } from '@impact/data';

@Component({
    selector: 'app-umb-section',
    template: `
        <app-umb-row
            *ngFor="let row of section.rows"
            class="layout-grid__section"
            [row]="row"
        ></app-umb-row>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UmbSectionComponent {
    @Input()
    section: IGridSection;
}
