import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IMegaNavigationSpotResponse } from '@impact/data';

@Component({
    selector: 'app-navigation-spot',
    template: `
        <div
            class="mega-navigation-spot-layout__spot"
            *ngIf="showSpot"
            [ngClass]="{ 'background-image': data?.image?.url }"
            [style]="'background-image:url(' + data?.image?.url + '?height=400&quality=100)'"
            [ngSwitch]="(data?.link | json) !== '{}'"
        >
            <ng-container *ngSwitchCase="true">
                <app-link *ngIf="data.link" [link]="data.link" [classString]="'mega-navigation-spot-layout__spot-content'" [showLinkText]="false">
                    <div class="text-content" *ngIf="data?.headline || data?.categoryHeadline || data?.text">
                        <h2 class="category-headline" *ngIf="data?.categoryHeadline">{{ data?.categoryHeadline }}</h2>
                        <h1 class="headline" *ngIf="data?.headline">{{ data?.headline }}</h1>
                        <p class="text" *ngIf="data?.text">{{ data?.text }}</p>
                    </div>
                    <div class="button--primary">{{ data?.link?.name }}</div>
                </app-link>
            </ng-container>

            <ng-container *ngSwitchCase="false">
                <div class="mega-navigation-spot-layout__spot-content">
                    <div class="text-content" *ngIf="data?.headline || data?.categoryHeadline || data?.text">
                        <h2 class="category-headline" *ngIf="data?.categoryHeadline">{{ data?.categoryHeadline }}</h2>
                        <h1 class="headline" *ngIf="data?.headline">{{ data?.headline }}</h1>
                        <p class="text" *ngIf="data?.text">{{ data?.text }}</p>
                    </div>
                </div>
            </ng-container>
        </div>
    `,
})
export class NavigationSpotComponent implements OnChanges {
    @Input() data: IMegaNavigationSpotResponse;

    showSpot: boolean;

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.data.currentValue) {
            this.showSpot = false;
            return;
        }

        // Check if spot has data
        if (this.data.headline === '' && this.data.text === '') {
            this.showSpot = false;
        } else {
            this.showSpot = true;
        }
    }
}
