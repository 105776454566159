import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IImage } from '@impact/data';

import { objectIsEmpty } from '../core/helpers';
import { StyleBreakpoints } from '../models/style-breakpoints';
import { IImageBreakpoints } from '../utils/responsive-image.component';
import { FeatureDetectionService } from './helpers/feature-detection.service';

// Native HTML5 video player

@Component({
    selector: 'app-video-player',
    template: `
        <video
            #videoElement
            *ngIf="!objectIsEmpty(video); else vimeoVideo"
            class="video-player"
            autoplay
            preload="{{preload}}"
            loop
            playsinline
            muted
            [poster]="fallbackImage?.url + '?format=webp'"
            appImageLoadFadeIn
            width="16"
            height="9">
            <source [src]="video" (error)="videoFail()" />
        </video>

        <!-- Fallback if video is not supported in browser -->
        <app-responsive-image
            *ngIf="videoFailed && fallbackImage"
            [image]="fallbackImage"
            [breakpoints]="imageBreakpoints"
            class="video-player__image">
        </app-responsive-image>

        <ng-template #vimeoVideo>
            <app-vimeo-player [videoId]="vimeoId" [thumbnail]="fallbackImage"></app-vimeo-player>
        </ng-template>

        <!-- If video fails to load -->
        <app-responsive-image
            *ngIf="videoFailed && fallbackImage"
            [image]="fallbackImage"
            [breakpoints]="imageBreakpoints"
            class="video-player__image"
        >
        </app-responsive-image>
    `,
})
export class VideoPlayerComponent implements AfterViewInit {
    @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;

    @Input() video?: IImage | string;
    @Input() vimeoId?: string;
    @Input() fallbackImage?: IImage;
    @Input() preload = 'auto';

    objectIsEmpty = objectIsEmpty;

    videoFailed: boolean;
    imageBreakpoints: IImageBreakpoints[];

    constructor(private featureDetectionService: FeatureDetectionService) {
        this.imageBreakpoints = [
            {
                breakpoint: StyleBreakpoints.Implicit,
                width: 360,
            },
            {
                breakpoint: StyleBreakpoints.Xsmall,
                width: 460,
            },
            {
                breakpoint: StyleBreakpoints.Small,
                width: 768,
            },
            {
                breakpoint: StyleBreakpoints.Medium,
                width: 1024,
            },
            {
                breakpoint: StyleBreakpoints.Large,
                width: 1200,
            },
            {
                breakpoint: StyleBreakpoints.Xlarge,
                width: 2000,
            },
        ];
    }

    ngAfterViewInit() {
        // Ensure video is muted - fixes autoplay issues
        if (this.featureDetectionService.isBrowser() && this.videoElement) {
            this.videoElement.nativeElement.muted = true;
            this.videoElement.nativeElement.play();
        }
    }

    videoFail() {
        this.videoFailed = true;
    }
}
