import { Injectable } from '@angular/core';
import { IImage } from '@impact/data';

import { ImageUrl, mediaIsImage } from '../utils/helpers/image-helper';

@Injectable({
    providedIn: 'root',
})
export class MediaSliderService {
    getImageSrc(image: string | IImage) {
        return mediaIsImage(image) ? ImageUrl(image as IImage, { width: 130, height: 73, mode: 'crop', format: 'webp' }) : null;
    }

    getImageAlt(image?: string | IImage) {
        if (!image) return;
        return mediaIsImage(image) ? (image as IImage).altText : null;
    }
}
