import { BreakpointObserver } from '@angular/cdk/layout';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { HeaderThemes } from '@impact/data';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HeaderService } from '../../core/header.service';
import { NavigationService } from '../../navigation/navigation.service';
import { FeatureDetectionService } from '../../utils/helpers/feature-detection.service';
import { SettingsService } from '../../core/settings.service';


@Component({
    selector: 'app-site-header',
    template: `
        <div [style.height]="headerTheme !== headerThemes.TRANSPARENT ? headerHeight + 'px' : ''">
            <header
                #header
                class="site-header"
                [ngClass]="{
                    'site-header--transparent': headerTheme === headerThemes.TRANSPARENT && !isFixed,
                    'site-header--is-sticky': isFixed
                }"
                [style.top]="hideNavBar ? -headerHeight + 'px' : ''"
            >
                <ng-container [ngSwitch]="isServer">
                    <app-ssr-main-navigation></app-ssr-main-navigation>
                </ng-container>
                <div class="site-header__wrapper">
                    <div class="site-header__content">
                        <ng-container [ngSwitch]="isB2BSite$ | async">
                            <ng-container *ngSwitchCase="true">
                                <a class="site-header__logo-link" [routerLink]="b2bSectionPage?.url" (click)="closeMegaNavigation()">
                                    <app-site-logo></app-site-logo>
                                </a>
                            </ng-container>
                            <ng-container *ngSwitchCase="false">
                                <a class="site-header__logo-link" routerLink="/" (click)="closeMegaNavigation()">
                                    <app-site-logo></app-site-logo>
                                </a>
                            </ng-container>
                        </ng-container>
                        <ng-container [ngSwitch]="isDesktop">
                            <ng-container *ngSwitchCase="true">
                                <app-main-navigation class="site-header__main-menu"></app-main-navigation>
                                <app-meta-menu class="site-header__meta-menu" [headerTheme]="headerTheme"></app-meta-menu>
                            </ng-container>
                            <ng-container *ngSwitchCase="false">
                                <app-meta-menu class="site-header__meta-menu" [headerTheme]="headerTheme"></app-meta-menu>
                                <app-button buttonClass="site-header-mobile-menu" appMobileMenuTrigger>
                                    <i class="button__icon site-header-mobile-menu__icon"
                                        >${'<svg #navAlignedIcon class="svg-nav-aligned-icon svg-icon" viewBox="0 0 14 11.5" aria-hidden="true"><path fill="none" stroke="currentColor" stroke-width="1.5" d="M0 10.75h14m-14-5h14m-14-5h14"/></svg>'}</i
                                    >
                                </app-button>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <app-mega-navigation *ngIf="isDesktop"></app-mega-navigation>
            </header>
        </div>
        <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    isTransparent$: Observable<boolean>;
    isMegaNavigationActive: boolean;
    headerTheme: HeaderThemes;
    headerThemes = HeaderThemes;
    headerIsTransaparent: boolean;

    // Dynamically sticky header:
    @ViewChild('header') private headerElement: ElementRef;

    currentPosition: number;
    isFixed: boolean;
    previousPosition: number;
    hideNavBar = false;
    headerHeight: number;

    // SSR
    isServer: boolean;
    isDesktop: boolean;

    isB2BSite$: Observable<boolean>;
    b2bSectionPage: any;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private cdr: ChangeDetectorRef,
        private headerService: HeaderService,
        private navigationService: NavigationService,
        private featureDetectionService: FeatureDetectionService,
        private settingService: SettingsService
    ) {
        // Check if SSR
        this.isServer = this.featureDetectionService.isServer();

        this.headerService.getIsHeaderTransparent().subscribe((isTransparent) => {
            this.headerIsTransaparent = isTransparent;
            this.headerTheme = isTransparent ? HeaderThemes.TRANSPARENT : HeaderThemes.DEFAULT;
            this.featureDetectionService.transparentHeaderMode(isTransparent);
            this.cdr.markForCheck();
        });

        this.navigationService.isMegaNavigationActive().subscribe((data) => {
            this.isMegaNavigationActive = data.isActive;
        });

        this.featureDetectionService.onResize$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.calcHeaderHeight();
        });

        this.settingService.getGlobalpages().subscribe((data) => {
            this.b2bSectionPage = data.b2bSectionPageUrl;
        });
    }

    ngOnInit() {
        this.isB2BSite$ = this.navigationService.getSiteB2Bstate();
        const layoutChanges$ = this.breakpointObserver.observe(['(min-width: 1024px)']);

        layoutChanges$.pipe(takeUntil(this.unsubscribe)).subscribe((result) => {
            if (result.matches || this.isServer) {
                this.isDesktop = true;
            } else {
                this.isDesktop = false;
            }

            this.cdr.markForCheck();
        });

        this.headerService
            .getScrollPosition()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((position: number) => {
                if (!this.isMegaNavigationActive) {
                    this.currentPosition = position;
                    this.handleHeaderPosition();
                }
            });
    }

    ngAfterViewInit(): void {
        this.setInitialValues();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setInitialValues() {
        if (this.featureDetectionService.isBrowser()) {
            this.calcHeaderHeight();
            this.cdr.markForCheck();
        }
    }

    calcHeaderHeight() {
        this.headerHeight = this.headerElement.nativeElement.offsetHeight;
        this.headerService.updateModelPageHeader(this.headerHeight);
        this.previousPosition = this.headerHeight;
    }

    handleHeaderPosition() {
        if (this.currentPosition > this.headerHeight) {
            if (this.currentPosition > this.previousPosition) {
                this.hideNavBar = true;
                this.previousPosition = this.currentPosition - 1;
            } else {
                this.isFixed = true;
                this.hideNavBar = false;
                this.previousPosition = this.currentPosition;
            }
        } else if (this.currentPosition === 0) {
            this.isFixed = false;
            this.hideNavBar = false;
            this.previousPosition = this.headerHeight;
        }

        this.cdr.markForCheck();
    }

    closeMegaNavigation() {
        this.navigationService.showMegaNavigation(false);
    }
}
