import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ComponentLoaderComponent } from './component-loader.component';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule
    ],
    exports: [ComponentLoaderComponent],
    declarations: [ComponentLoaderComponent],
    providers: [],
})
export class ComponentLoaderModule { }
