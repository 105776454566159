import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { INavigationResponse, PAGE_TYPES } from '@impact/data';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { carImageFallbackIcon, chevronRight } from '../../../icons';
import { NavigationService } from '../navigation.service';
import { megaNavigationColumnsMaxSize } from './mega-navigation.component';

@Component({
    selector: 'app-mega-navigation-new-cars',
    template: `
        <div class="mega-navigation-layout-wrapper">
            <div class="mega-navigation-layout-wrapper-inner">
                <div class="mega-navigation-content-layout" [ngClass]="{'is-ready': isReady}">
                    <div class="mega-navigation__content mega-navigation-new-cars__content">
                        <!-- LEFT COLUMN -->
                        <div class="mega-navigation__content-column-left">
                            <div class="mega-navigation__content-header" [ngClass]="{'is-ready': isReady}">
                                <h2 class="mega-navigation-spot-layout__content-header-text">{{ data?.name }}</h2>
                            </div>
                            <ul class="mega-navigation__navigation-list">
                                <ng-container *ngFor="let brand of pageNavigationData.children">
                                    <li class="mega-navigation__navigation-list-item" *ngIf="brand.includeInNavigation && brand.template === pageTypes.VEHICLE_BRAND_PAGE && !brand.hideInMenu">
                                        <button class="mega-navigation__navigation-list-link"
                                            [ngClass]="{ 'is-active': activeBrandNavigationData?.name === brand?.name }"
                                            (mouseover)="setActiveBrand(brand, activeBrandNavigationData?.name === brand?.name)">
                                            {{brand?.name}}
                                        </button>
                                        <div class="mega-navigation__navigation-active-indicator">${chevronRight}</div>
                                    </li>
                                </ng-container>
                            </ul>

                            <ul class="mega-navigation__navigation-list mega-navigation__navigation-list--secondary" *ngIf="pageNavigationData.megaNavigationNewCarsOptionalMenu">
                                <li *ngFor="let page of pageNavigationData.megaNavigationNewCarsOptionalMenu.links">
                                    <a [routerLink]="page?.url" class="mega-navigation__navigation-list-link">
                                        {{page?.name}}
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="mega-navigation__content-column-right">
                            <header class="mega-navigation-new-cars__content-header">
                                <h2 class="mega-navigation-spot-layout__content-header-text">{{ activeBrandNavigationData?.name }}</h2>
                                <a [routerLink]="activeBrandNavigationData?.url" class="button--primary">{{'car_data.see_all_from_brand' | translate}} {{activeBrandNavigationData?.name}}</a>
                            </header>

                            <!-- Model Filter -->
                            <ul class="new-cars-card-list__filter" *ngIf="brandModelFilters && brandModelFilters.length">
                                <li class="new-cars-card-list__filter-item" *ngFor="let filter of brandModelFilters; let i = index;">
                                    <button (click)="activeFilter.id !== filter.id ? setModelFilter(i) : false;"
                                        [ngClass]="{ 'active-item': activeFilter.id === filter.id }"
                                        class="button--minimal new-cars-card-list__filter-button">
                                        {{filter.displayName}}
                                    </button>
                                </li>
                            </ul>

                            <!-- NEW CARS CONTENT -->
                            <div class="mega-navigation__scroll-container" #newCarsContainer>
                                <ul class="mega-navigation-new-cars__content-list" *ngIf="modelsToShow">
                                    <ng-container *ngFor="let item of modelsToShow">
                                        <li class="mega-navigation-new-cars__content-list-item"
                                            *ngIf="item.template === pageTypes.VEHICLE_MODEL_PAGE && !item.hideInMenu">
                                            <a [routerLink]="item?.url" class="mega-navigation-new-cars__content-list-item-wrapper" (click)="navigationService.showMegaNavigation(false)">
                                                <ng-container [ngSwitch]="item?.vehicleModelData?.modelDisplayImage?.url !== undefined">
                                                    <img *ngSwitchCase="true"
                                                        [src]="item?.vehicleModelData?.modelDisplayImage?.url + '?width=250&format=webp'"
                                                        class="mega-navigation-new-cars__content-list-item-image"
                                                        appImageLoadFadeIn />
                                                    <div *ngSwitchCase="false" class="mega-navigation-new-cars__content-list-item-fallback-image">${carImageFallbackIcon}</div>
                                                </ng-container>
                                                <div class="mega-navigation-new-cars__content-list-item-info">
                                                    <div class="model-name">
                                                        {{item?.vehicleModelData?.modelName}}
                                                    </div>
                                                    <div class="model-price" *ngIf="item?.vehicleModelData?.startPrice">
                                                        {{'car_data.starting_price_from_short' | translate}} {{item?.vehicleModelData?.startPrice | defaultcurrency}}
                                                        <span class="vat-price" *ngIf="isB2B">{{'generic.vat_excluded' | translate}}</span>
                                                    </div>
                                                </div>
                                            </a>

                                            <div *ngIf="item?.activeCampaign" class="campaign-tag">
                                                {{'car_data.campaign_label_text' | translate}}
                                            </div>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MegaNavigationNewCarsComponent implements OnInit, OnDestroy {
    @Input() data: INavigationResponse;
    @ViewChild('newCarsContainer') newCarsContainer: ElementRef;

    activeBrandNavigationData: any;
    pageNavigationData: any;
    pageTypes = PAGE_TYPES;
    isB2B: boolean;
    brandModelFilters: any[] = [];
    activeFilter: any;
    defaultFilter: any;
    modelsToShow: any[];
    isReady: boolean;

    activeNavigationCategory: string;
    columnItemsSize = megaNavigationColumnsMaxSize;

    private unsubscribe = new Subject<void>();

    constructor(
        private cdr: ChangeDetectorRef,
        public navigationService: NavigationService,
        private translateService: TranslateService
    ) {
        this.navigationService.getSiteB2Bstate().pipe(takeUntil(this.unsubscribe)).subscribe((isB2B: boolean) => {
            this.isB2B = isB2B;
        });
    }

    ngOnInit() {
        this.pageNavigationData = this.data;

        // Update model filters array
        this.defaultFilter = {
            displayName: this.translateService.instant('car_data.vehicle_type_filter_button_all'),
            id: 'default'
        };

        // Initial getting active brand, will trigger listeners that will update data
        this.navigationService.getActiveBrandTag();

        // B2C active brand
        this.navigationService.activeB2CBrand$.pipe(
            map((brandName) => {

                if (this.navigationService.isB2BSite) {
                    return;
                }

                if (!brandName) {
                    this.activeBrandNavigationData = this.pageNavigationData.children[0];
                    this.setActiveBrand(this.activeBrandNavigationData, false);
                } else {
                    this.activeBrandNavigationData = this.pageNavigationData.children.find((item: any) => item.vehicleBrandData.brandName === brandName);

                    // Activate model filters
                    if (this.activeBrandNavigationData?.newCarsModelFilters) {
                        this.activateModelFilters(this.activeBrandNavigationData.newCarsModelFilters);
                    }
                    else {
                        this.modelsToShow = this.activeBrandNavigationData.children.filter((item: any) => item.includeInNavigation);
                    }
                }

                setTimeout(() => {
                    this.isReady = true;
                    this.cdr.markForCheck();
                }, 350);
            }), takeUntil(this.unsubscribe)).subscribe();

        // B2B active brand
        this.navigationService.activeB2BBrand$.pipe(
            map((brandName) => {

                if (!this.navigationService.isB2BSite) {
                    return;
                }

                if (!brandName) {
                    this.activeBrandNavigationData = this.pageNavigationData.children[0];
                    this.setActiveBrand(this.activeBrandNavigationData, false);
                } else {
                    this.activeBrandNavigationData = this.pageNavigationData.children.find((item: any) => item.vehicleBrandData.brandName === brandName);

                    // Activate model filters
                    if (this.activeBrandNavigationData?.newCarsModelFilters) {
                        this.activateModelFilters(this.activeBrandNavigationData.newCarsModelFilters);
                    }
                    else {
                        this.modelsToShow = this.activeBrandNavigationData.children.filter((item: any) => item.includeInNavigation);
                    }
                }

                setTimeout(() => {
                    this.isReady = true;
                    this.cdr.markForCheck();
                }, 350);
            }), takeUntil(this.unsubscribe)).subscribe();
    }

    activateModelFilters(filterArray: any[]) {
        this.brandModelFilters = filterArray ?? [];

        // Add default filter to start of array
        if (this.brandModelFilters.length && this.brandModelFilters[0].id !== this.defaultFilter.id) {
            this.brandModelFilters.unshift(this.defaultFilter);
        }

        if (this.brandModelFilters && this.brandModelFilters.length) {
            if (this.brandModelFilters.length > 2) {
                this.setModelFilter(0);
            } else {
                this.brandModelFilters = [];
                this.modelsToShow = this.activeBrandNavigationData.children.filter((item: any) => item.includeInNavigation);
            }
        }
    }

    setModelFilter(filterIndex: number) {
        this.activeFilter = this.brandModelFilters[filterIndex];
        
        if (this.activeFilter && this.activeFilter.id === this.defaultFilter.id) {
            // Show all models
            this.modelsToShow = this.activeBrandNavigationData.children.filter((item: any) => item.includeInNavigation);
        } else {
            // Filter out models based on activeFilter
            this.modelsToShow = [];
            if (this.activeBrandNavigationData?.children) {
                this.activeBrandNavigationData.children.filter((model: any) => {
                    if (model && model.vehicleModelData && model.vehicleModelData.vehicleTypes && model.vehicleModelData.vehicleTypes.length) {
                        model.vehicleModelData.vehicleTypes.filter((type: any) => {
                            if (type.id === this.activeFilter.id) {
                                this.modelsToShow.push(model);
                            }
                        });
                    }
                });
            }
        }

        this.cdr.markForCheck();
    }

    // Sets active brand, that triggers the active brand subscribers, that updates the content
    setActiveBrand(brand: any, isSameAsActive: boolean) {
        this.activeBrandNavigationData = this.pageNavigationData.children.find((item: any) => item.vehicleBrandData.brandName === brand.vehicleBrandData.brandName);

        this.modelsToShow = this.activeBrandNavigationData.children.filter((item: any) => item.includeInNavigation);

        if (!isSameAsActive) {
            this.navigationService.setActiveBrandTag(brand?.vehicleBrandData.brandName);

            this.brandModelFilters = this.activeBrandNavigationData.newCarsModelFilters;

            if (this.newCarsContainer) {
                this.newCarsContainer.nativeElement.scrollTo(0, 0);
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
