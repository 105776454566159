import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SSRMainNavigtionComponent } from '../../layout/ssr-navigation/ssr-main-navigation.component';
import { MainNavigationComponent } from './main-navigation.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forChild()
    ],
    exports: [
        MainNavigationComponent,
        SSRMainNavigtionComponent
    ],
    declarations: [
        MainNavigationComponent,
        SSRMainNavigtionComponent
    ],
    providers: [],
})
export class MainNavigationModule { }
