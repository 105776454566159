import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BrandTheme } from '@impact/data';
import { Observable, ReplaySubject } from 'rxjs';

import { SettingsService, SiteIds } from '../core/settings.service';
import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';

export const SiteThemeDefaults = {
    'Bjarne Nielsen': {
        primaryColor: {
            color: '2072B1', // Bjarne Nielsen default brand color
            label: 'defaultPrimaryColor'
        },
        secondaryColor: {
            color: 'ffffff',
            label: 'defaultSecondaryColor'
        }
    },
    'Bilernes Hus': {
        primaryColor: {
            color: '862436', // Bilernes Hus default brand color
            label: 'defaultPrimaryColor'
        },
        secondaryColor: {
            color: 'ffffff',
            label: 'defaultSecondaryColor'
        }
    },
    'Leasing': {
        primaryColor: {
            color: '00adee', // Leasing default brand color
            label: 'defaultPrimaryColor'
        },
        secondaryColor: {
            color: 'ffffff',
            label: 'defaultSecondaryColor'
        }
    },
    'Motorpoint': {
        primaryColor: {
            color: '2D7457', // Leasing default brand color
            label: 'defaultPrimaryColor'
        },
        secondaryColor: {
            color: 'F9F9F9',
            label: 'defaultSecondaryColor'
        }
    }
};

@Injectable({ providedIn: 'root' })
export class ThemeService {

    private activeBrandTheme$ = new ReplaySubject<BrandTheme>(1);
    defaultTheme: BrandTheme;
    activeBrandTheme: any;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private settingsService: SettingsService,
        private featureDetectionService: FeatureDetectionService
    ) {}

    //
    // Active brand theme logic
    setActiveBrandTheme(brandTheme?: any): void {
        if (brandTheme) {
            this.setTheme(brandTheme);
        } else {
            this.setDefaultTheme();
        }
    }

    getActiveBrandTheme(): Observable<BrandTheme> {
        return this.activeBrandTheme$.asObservable();
    }

    setDefaultTheme(): void {
        this.settingsService.getSiteId().subscribe((siteId: string) => {
            if (siteId === SiteIds.BNDK) {
                this.defaultTheme = SiteThemeDefaults['Bjarne Nielsen'];
            } else if (siteId === SiteIds.BHDK) {
                this.defaultTheme = SiteThemeDefaults['Bilernes Hus'];
            } else if (siteId === SiteIds.LEASINGDK) {
                this.defaultTheme = SiteThemeDefaults['Leasing'];
            } else if (siteId === SiteIds.MOTORPOINT) {
                this.defaultTheme = SiteThemeDefaults['Motorpoint'];
            }

            this.setTheme(this.defaultTheme);
        });
    }

    private setTheme(theme: any | any) {
        // Update CSS custom properties
        if (this.featureDetectionService.isBrowser()) {
            this.document.body.style.setProperty('--primary-brand-theme-color', `${this.hexToHSL(theme.primaryColor.color ?? theme.primaryColor)}`);
            this.document.body.style.setProperty('--secondary-brand-theme-color', `${this.hexToHSL(theme.secondaryColor.color ?? theme.secondaryColor)}`);
            this.document.body.style.setProperty('--primary-brand-theme-color-darker', `${this.hexToHSL(theme.primaryColor.color ?? theme.primaryColor, 'darken')}`);
            this.document.body.style.setProperty('--secondary-brand-theme-color-darker', `${this.hexToHSL(theme.secondaryColor.color ?? theme.secondaryColor, 'darken')}`);
            this.document.body.style.setProperty('--primary-brand-theme-color-lighter', `${this.hexToHSL(theme.primaryColor.color ?? theme.primaryColor, 'lighten')}`);
            this.document.body.style.setProperty('--secondary-brand-theme-color-lighter', `${this.hexToHSL(theme.secondaryColor.color ?? theme.secondaryColor, 'lighten')}`);
        }

        this.activeBrandTheme$.next(theme);
    }

    // Format Hexcode to HSL (Hue/Saturation/Lightness) to be able to manipulate colors in themes correctly
    private hexToHSL(hexColor: any, mode?: string) {

        // Add hash if missing
        if (String(hexColor).indexOf('#') !== 0) {
            hexColor = `#${hexColor}`;
        }

        // Convert hex to RGB first
        let r = '';
        let g = '';
        let b = '';

        if (hexColor.length === 4) {
            r = '0x' + hexColor[1] + hexColor[1];
            g = '0x' + hexColor[2] + hexColor[2];
            b = '0x' + hexColor[3] + hexColor[3];
        } else if (hexColor.length === 7) {
            r = '0x' + hexColor[1] + hexColor[2];
            g = '0x' + hexColor[3] + hexColor[4];
            b = '0x' + hexColor[5] + hexColor[6];
        }

        let rr: number = parseInt(r);
        let gg: number = parseInt(g);
        let bb: number = parseInt(b);

        // Then to HSL
        rr /= 255;
        gg /= 255;
        bb /= 255;

        const cmin = Math.min(rr, gg, bb);
        const cmax = Math.max(rr, gg, bb);
        const delta = cmax - cmin;
        let h = 0;
        let s = 0;
        let l = 0;

        if (delta === 0)
            h = 0;
        else if (cmax === rr)
            h = ((gg - bb) / delta) % 6;
        else if (cmax === gg)
            h = (bb - rr) / delta + 2;
        else
            h = (rr - gg) / delta + 4;

        h = Math.round(h * 60);

        if (h < 0) {
            h += 360;
        }

        l = (cmax + cmin) / 2;
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        let hsl;

        if (mode === 'darken') {
            hsl = `hsl(${h}, ${s}%, ${l * 0.8}%)`;
        } else if (mode === 'lighten') {
            hsl = `hsl(${h}, ${s}%, ${l / 0.8}%)`;
        } else {
            hsl = `hsl(${h}, ${s}%, ${l}%)`;
        }

        return hsl;
    }
}