import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit } from '@angular/core';
import { PageResponse } from '@impact/data';

import { UmbracoGridModule } from '../../umbraco-grid/umbraco-grid.module';
import { TrackingService } from '../../utils/tracking/tracking.service';

@Component({
    selector: 'app-not-found-page',
    template: `
        <main>
            <section class="page-grid-content">
                <app-umb-grid [grid]="data?.content?.grid"></app-umb-grid>
            </section>
        </main>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent implements OnInit {
    static ref = 'notFoundPage';

    @Input()
    data?: PageResponse;

    constructor(private trackingService: TrackingService) {}

    ngOnInit() {
        this.trackingService.track404();
    }
}

@NgModule({
    declarations: [NotFoundComponent],
    imports: [
        UmbracoGridModule
    ]
})
class NotFoundModule { }

export const LazyComponent = NotFoundComponent;
