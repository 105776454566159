import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INavigationResponse, IUsedCarsNavigationLink } from '@impact/data';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { Observable } from 'rxjs';

import { mobileAnimation, MobileAnimationState } from '../../utils/animations/mobile-menu.animations';
import { MobileNavigationService } from './mobile-navigation.service';

import { chevronRight } from '../../../icons';

@Component({
    selector: 'app-mobile-used-cars-budgets-nav',
    template: `
        <div [@slideContent]="animationState">
            <div class="mobile-menu__section-header">
                <h1>{{ 'navigation.select_budget' | translate }}</h1>
            </div>

            <div class="mobile-menu__scroll-container mobile-menu__scroll-container--sub-header">
                <ul class="mobile-menu__list" *ngIf="budgets$ | async as budgets">
                    <li class="mobile-menu-item">
                        <a [routerLink]="navigation.url" (click)="overlayRef.close()">
                            <span class="mobile-menu-item__text">{{ 'car_data.see_all_from_brand' | translate }} {{ navigation.name }}</span>
                            <i class="mobile-menu-item__icon--nav">${chevronRight}</i>
                        </a>
                    </li>
                    <li *ngFor="let budget of budgets" class="mobile-menu-item">
                        <a
                            class="mobile-menu-grid-item__link"
                            [routerLink]="budget.link?.url"
                            [queryParams]="budget.link ? budget.link.params : {}"
                            (click)="overlayRef.close()"
                        >
                            <span class="mobile-menu-item__text">{{ budget.linkText }}</span>
                            <i class="mobile-menu-item__icon--nav">${chevronRight}</i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [mobileAnimation],
})
export class MobileUsedCarsBudgetsNavComponent {
    @Input() navigation: INavigationResponse;
    budgets$: Observable<IUsedCarsNavigationLink[]>;

    animationState: MobileAnimationState = 'enter';

    constructor(public overlayRef: ImpactOverlayRef, mobileNavigationService: MobileNavigationService) {
        this.budgets$ = mobileNavigationService.usedCarsBudgetFilters$;
    }
}
