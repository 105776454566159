import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { INavigationResponse, NavigationLink, PAGE_TYPES } from '@impact/data';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { SettingsService } from '../../core/settings.service';
import { NavigationService } from '../../navigation/navigation.service';

@Component({
    selector: 'app-ssr-main-navigation',
    template: `
        <nav>
            <ul class="ssr-main-navigation">
                <ng-container *ngFor="let level1Item of ssrNavigation">
                    <li class="ssr-main-navigation__list-item" *ngIf="level1Item && level1Item.includeInNavigation && !level1Item.hideInMenu">

                        <!-- Top level in main navigation -->
                        <a class="ssr-main-navigation__list-item-link"
                            [routerLink]="level1Item?.pageLink?.cleanUrl || level1Item.url"
                            [queryParams]="level1Item?.pageLink?.urlParams">
                            {{ level1Item.name }}
                        </a>

                        <ng-container *ngIf="level1Item?.children">
                            <ng-container [ngSwitch]="level1Item.template">

                                <!-- NEW CARS -->
                                <ul *ngSwitchCase="pageTypes.NEW_CARS_OVERVIEW_PAGE">
                                    <ng-container *ngFor="let level2Item of level1Item.children">
                                        <li *ngIf="level2Item && level2Item.includeInNavigation && !level2Item.hideInMenu">
                                            <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: level2Item}"></ng-container>

                                            <ul *ngIf="level2Item?.children">
                                                <ng-container *ngFor="let level3Item of level2Item.children">
                                                    <li *ngIf="level3Item && level3Item.includeInNavigation && !level3Item.hideInMenu">
                                                        <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: level3Item}"></ng-container>

                                                        <ul *ngIf="level3Item?.children">
                                                            <ng-container *ngFor="let level4Item of level3Item.children">
                                                                <li *ngIf="level4Item && level4Item.includeInNavigation && !level4Item.hideInMenu">
                                                                    <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: level4Item}"></ng-container>
                                                                </li>
                                                            </ng-container>
                                                        </ul>
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </li>
                                    </ng-container>
                                </ul>

                                <!-- USED CARS -->
                                <ng-container *ngSwitchCase="pageTypes.USED_CARS_OVERVIEW_PAGE">
                                    <div *ngIf="level1Item?.usedCarsPageNavigationData">
                                        <ng-container *ngIf="level1Item?.usedCarsPageNavigationData?.primaryNavigationHeader && level1Item?.usedCarsPageNavigationData?.primaryNavigation">
                                            <div>{{level1Item?.usedCarsPageNavigationData?.primaryNavigationHeader}}</div>
                                            <ul>
                                                <li *ngFor="let item of level1Item?.usedCarsPageNavigationData?.primaryNavigation">
                                                    <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: item}"></ng-container>
                                                </li>
                                            </ul>
                                        </ng-container>
                                        <ng-container *ngIf="level1Item?.usedCarsPageNavigationData?.secondaryNavigationHeader && level1Item?.usedCarsPageNavigationData?.secondaryNavigation">
                                            <div>{{level1Item?.usedCarsPageNavigationData?.secondaryNavigationHeader}}</div>
                                            <ul>
                                                <li *ngFor="let item of level1Item?.usedCarsPageNavigationData?.primaryNavigation">
                                                    <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: item}"></ng-container>
                                                </li>
                                            </ul>

                                            <ng-container *ngIf="level1Item?.megaNavigationSpot?.link">
                                                <ng-container *ngTemplateOutlet="SSRNavigationSpot; context: {item: level1Item?.megaNavigationSpot}"></ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </ng-container>

                                <!-- ARTICLES -->
                                <ng-container *ngSwitchCase="pageTypes.ARTICLES_OVERVIEW_PAGE">
                                    <ng-container *ngIf="level1Item?.articlesNavigationData">
                                        <ng-container *ngIf="level1Item?.articlesNavigationData?.articleBrandTagsNavigation">
                                            <div>{{level1Item?.articlesNavigationData?.articleBrandsTagNavigationHeader}}</div>
                                            <ul>
                                                <li>
                                                    <a [routerLink]="articlesOverviewPageUrl">
                                                        {{'articles.show_all_articles_link_text' | translate}}
                                                    </a>
                                                </li>
                                                <li *ngFor="let item of level1Item?.articlesNavigationData?.articleBrandTagsNavigation">
                                                    <ng-container *ngTemplateOutlet="SSRNavigationTagLink; context: {item: item}"></ng-container>
                                                </li>
                                            </ul>
                                            <div>{{level1Item?.articlesNavigationData?.articleCategoriesTagNavigationHeader}}</div>
                                            <ul>
                                                <li *ngFor="let item of level1Item?.articlesNavigationData?.articleCategoriesTagNavigation">
                                                    <ng-container *ngTemplateOutlet="SSRNavigationTagLink; context: {item: item}"></ng-container>
                                                </li>
                                            </ul>

                                            <ng-container *ngIf="level1Item?.megaNavigationSpot?.link">
                                                <ng-container *ngTemplateOutlet="SSRNavigationSpot; context: {item: level1Item?.megaNavigationSpot}"></ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <!-- Column lists pages -->
                                <ng-container *ngSwitchDefault>
                                    <ul *ngIf="level1Item?.children">
                                        <ng-container *ngFor="let level2Item of level1Item.children">
                                            <li *ngIf="level2Item && level2Item.includeInNavigation && !level2Item.hideInMenu">
                                                <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: level2Item}"></ng-container>

                                                <ul *ngIf="level2Item?.children">
                                                    <ng-container *ngFor="let level3Item of level2Item.children">
                                                        <li *ngIf="level3Item && level3Item.includeInNavigation && !level3Item.hideInMenu">
                                                            <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: level3Item}"></ng-container>

                                                            <ul *ngIf="level3Item?.children">
                                                                <ng-container *ngFor="let level4Item of level3Item.children">
                                                                    <li *ngIf="level4Item && level4Item.includeInNavigation && !level4Item.hideInMenu">
                                                                        <ng-container *ngTemplateOutlet="SSRNavigationLink; context: {item: level4Item}"></ng-container>
                                                                    </li>
                                                                </ng-container>
                                                            </ul>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                            </li>
                                        </ng-container>
                                    </ul>

                                    <ng-container *ngIf="level1Item?.megaNavigationSpot?.link">
                                        <ng-container *ngTemplateOutlet="SSRNavigationSpot; context: {item: level1Item?.megaNavigationSpot}"></ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </li>
                </ng-container>
            </ul>
        </nav>

        <!-- Link Template -->
        <ng-template #SSRNavigationLink let-item="item">
            <a [routerLink]="item?.pageLink?.cleanUrl || item.url" [queryParams]="item?.pageLink?.urlParams">
                {{ item?.name }}
            </a>
        </ng-template>

        <!-- Tag link Template -->
        <ng-template #SSRNavigationTagLink let-item="item">
            <a [routerLink]="articlesOverviewPageUrl" [queryParams]="item?.param">{{item?.displayName}}</a>
        </ng-template>

        <!-- Mega navigation spot Template -->
        <ng-template #SSRNavigationSpot let-item="item">
            <a [routerLink]="item?.link?.cleanUrl || item?.link?.url" [queryParams]="item?.link?.urlParams">
                <span>{{item?.categoryHeadline}}</span>
                <span>{{item?.headline}}</span>
                <span>{{item?.text}}</span>
            </a>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SSRMainNavigtionComponent implements OnInit {
    pageTypes = PAGE_TYPES;
    ssrNavigation: INavigationResponse[];
    usedCarsOverviewPageUrl: string;
    articlesOverviewPageUrl: string;
    private readonly unsubscribe = new Subject();

    constructor(
        private cdr: ChangeDetectorRef,
        private navigationService: NavigationService,
        private settingsService: SettingsService
    ) {}

    ngOnInit() {
        const siteB2BState$ = this.navigationService.getSiteB2Bstate();
        const b2cNavigation$ = this.navigationService.getMainNavigation();
        const b2bNavigation$ = this.navigationService.getB2BNavigation();

        const connectStream$ = combineLatest([siteB2BState$, b2cNavigation$, b2bNavigation$]);

        connectStream$.pipe(
            map(([isB2B, b2cNav, b2bNav]) => {

                if (isB2B) {
                    this.ssrNavigation = this.formatSSRNavigation(b2bNav);
                } else {
                    this.ssrNavigation = this.formatSSRNavigation(b2cNav);
                }

                this.cdr.markForCheck();
        }), takeUntil(this.unsubscribe)).subscribe();
    }

    // SSR formatting
    formatSSRNavigation(navigation: INavigationResponse[]): any[] {
        this.settingsService.getGlobalpages().subscribe((globalPages: any) => {
            this.usedCarsOverviewPageUrl = globalPages.usedCarsOverviewPage.url ?? undefined;
            this.articlesOverviewPageUrl = globalPages.articlesOverviewPage.url ?? undefined;

            if (navigation) {
                for (const item of navigation) {
                    if (item.template === this.pageTypes.USED_CARS_OVERVIEW_PAGE) {
                        if (item.usedCarsPageNavigationData) {
                            if (item.usedCarsPageNavigationData.primaryNavigation) {
                                for (const navItem of item.usedCarsPageNavigationData.primaryNavigation) {
                                    navItem.link = new NavigationLink(this.usedCarsOverviewPageUrl, navItem.filterParameters);
                                }
                            }
                            if (item.usedCarsPageNavigationData.secondaryNavigation) {
                                for (const navItem of item.usedCarsPageNavigationData.secondaryNavigation) {
                                    navItem.link = new NavigationLink(this.usedCarsOverviewPageUrl, navItem.filterParameters);
                                }
                            }
                            if (item.usedCarsPageNavigationData.budgetsNavigation) {
                                for (const navItem of item.usedCarsPageNavigationData.budgetsNavigation) {
                                    navItem.link = new NavigationLink(this.usedCarsOverviewPageUrl, navItem.filterParameters);
                                }
                            }
                            if (item.usedCarsPageNavigationData.typesNavigation) {
                                for (const navItem of item.usedCarsPageNavigationData.typesNavigation) {
                                    navItem.link = new NavigationLink(this.usedCarsOverviewPageUrl, navItem.filterParameters);
                                }
                            }
                        }
                    }
                }
            }
        });

        return navigation;
    }
}
