import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional, Provider } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { AppHttpErrorResponse } from '@impact/data';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';

const NG_API_TIMEOUT_DEFAULT = 60000;
const NG_API_TIMEOUT_STATE_KEY = makeStateKey<number>('NG_API_TIMEOUT');

@Injectable({
    providedIn: 'root',
})
export class ApiInterceptorService implements HttpInterceptor {
    /**
     * Max timeout for API operations
     */
    private _ngApiTimeoutMax = 60000;

    constructor(
        private featureDetectionService: FeatureDetectionService,
        transferState: TransferState,
        @Optional() @Inject('NG_API_TIMEOUT') ngApiTimeout?: number
    ) {
        if (ngApiTimeout) {
            this._ngApiTimeoutMax = ngApiTimeout;
            transferState.set(NG_API_TIMEOUT_STATE_KEY, ngApiTimeout);
        } else {
            this._ngApiTimeoutMax = transferState.get(NG_API_TIMEOUT_STATE_KEY, NG_API_TIMEOUT_DEFAULT);
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let handler = next.handle(request);

        if (this.featureDetectionService.isBrowser()) {
            handler = handler.pipe(timeout(this._ngApiTimeoutMax));
        }

        return handler.pipe(
            catchError((error) => {
                const response = new AppHttpErrorResponse(error);

                if (error instanceof TimeoutError) {
                    console.warn('Timed out after request: ', error.message);
                } else {
                    if (error.error && error.error.message && Array.isArray(error.error.message) && error.error.message.length) {
                        const errors = error.error.message;
                        for (const validationError of error.error.message) {
                            if (Object.prototype.hasOwnProperty.call(validationError, 'constraints')) {
                                errors.forEach((x: any) =>
                                    Object.entries(x.constraints).forEach((constraint) => {
                                        response.validationErrors.push({
                                            property: validationError.property,
                                            error: constraint[1] as string,
                                        });
                                    })
                                );
                            }
                        }
                    }
                }

                return throwError(response);
            })
        );
    }
}

export const apiInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptorService,
    multi: true,
};
