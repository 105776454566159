import { ViewportScroller } from '@angular/common';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { SidePanelService, SidePanelSizes } from '../../side-panel/side-panel.service';

@Directive({
    selector: '[appInterceptLinks]',
})
export class InterceptLinksDirective {
    @Input() dialogId: string;

    @HostListener('click', ['$event'])
    onClick($event: MouseEvent): boolean {
        // Find the linkElement that was clicked
        let linkEle: HTMLAnchorElement = $event.target as any;

        // look up recursively until you find a linkElement
        // break when reaching container
        if (linkEle.tagName !== 'A') {
            while (linkEle.tagName !== 'A') {
                if (linkEle === this.elementRef.nativeElement) {
                    return true;
                }
                linkEle = linkEle.parentElement as any;
            }
        }

        // Eject if we lost the link element
        if (!linkEle) {
            return true;
        }

        // Eject if link has download attribute
        if (linkEle.download) {
            return true;
        }

        if (['mailto:', 'tel:'].indexOf(linkEle.href) === -1) {
            if (linkEle.target === '_blank') {
                linkEle.target = '_self';
                return true;
            }
        }


        // Eject if link has a target thats not empty or top
        if (['', 'top', undefined].indexOf(linkEle.target) === -1) {
            return true;
        }

        this.navigate(linkEle.href);

        return false;
    }

    /**
     * Navigate to any internal url that is not excluded by the [exclude] input
     */
    navigate(path: string): void {
        // Sanitize path with #
        path = path.replace('%23', '#');
        const url = new URL(path);

        // Eject if link points to another domain
        if (url.host !== location.host) {
            return;
        }

        if (url.hash && url.hash.indexOf('#sidepanel') !== -1) {
            // Find correct overlay component
            let size = SidePanelSizes.LARGE;

            if (url.pathname?.includes('medium')) {
                size = SidePanelSizes.SMALL;
            }

            if (url.pathname?.includes('small')) {
                size = SidePanelSizes.SMALL;
            }
            this.sidePanelService.getSidepanelContentAndOpen(url.pathname, size);
            return;
        }

        if (url.pathname === location.pathname && url.hash) {
            return;
        }

        const strippedUrlHash: string | undefined = url.hash ? url.hash.replace('#', '') : undefined;
        const extras: NavigationExtras = {
            fragment: strippedUrlHash,
            queryParams: this._parseQueryParams(url.search),
        };

        /**
         * If you are still here, the router will take over and do the navigation
         *
         * When anchor linking to an id the pathname will always be '/' because of "<base href="/">" in index.html.
         */
        if (url.hash && url.pathname === '/' && strippedUrlHash) {
            // If interceptLink is used in an overlay
            if (this.dialogId) {
                this._scrollToAnchorInDialog(strippedUrlHash);
                return;
            }

            // If it's the same hash, the scroll effect will not work, so use viewportScroller.scrollToAnchor instead
            if (url.hash === location.hash) {
                this.viewportScroller.scrollToAnchor(strippedUrlHash);
            } else {
                this.router.navigate([], extras);
            }
        } else {
            this.router.navigate([url.pathname], extras);
        }
    }

    private _scrollToAnchorInDialog(anchorId: string) {
        if (!this.dialogId) {
            return;
        }

        const dialogElement = document.getElementById(this.dialogId);
        if (dialogElement) {
            let idElement: HTMLElement | undefined;
            const goToId: HTMLElement | null = document.getElementById(anchorId);
            const goToName: NodeListOf<HTMLElement> = document.getElementsByName(anchorId);

            if (goToId) {
                idElement = goToId;
            } else if (goToName) {
                idElement = goToName[0];
            }

            if (idElement) {
                idElement.scrollIntoView(true);
            }
        }
    }

    private _parseQueryParams(searchString: string): { [key: string]: string } {
        return searchString
            .substring(1)
            .split('&')
            .reduce((params: any, pair: string) => {
                const kv = pair.split('=');
                const key = kv[0];
                const value = decodeURIComponent(kv[1])

                if(!key || !value) return params;

                if (params[key] && !Array.isArray(params[key])) {
                    params[key] = [params[key], value];
                }
                else if (Array.isArray(key)) {
                    params[key].push(value)
                }
                else {
                    params[key] = value;
                }
                return params;
            }, {});
    }

    constructor(
        private readonly elementRef: ElementRef,
        private readonly router: Router,
        private readonly viewportScroller: ViewportScroller,
        private readonly sidePanelService: SidePanelService
    ) {}
}
