export enum SpotTypes {
    WorkshopServiceSpot = 'workshopservicespot',
    AccordionSpot = 'accordionspot',
    ArticlesSpot = 'articlesspot',
    AuthorSpot = 'authorspot',
    BookUsedCarTestDriveCtaSpot = 'bookusedcartestdrivectaspot',
    BookNewCarTestDriveCtaSpot = 'booknewcartestdrivectaspot',
    VehicleCampaignsOverviewSpot = 'campaignsoverviewspot',
    DisclaimerSpot = 'disclaimerspot',
    DealershipContentSpot = 'dealershipcontentspot',
    ExteriorInteriorGallerySpot = 'exteriorinteriorgalleryspot',
    GridSpot = 'gridspot',
    HeadlineAndTextSpot = 'headlineandtextspot',
    ImageTextSplitSpot = 'imagetextsplitspot',
    overlayBoxSpot = 'overlayboxspot',
    factBoxSpot = 'factboxspot',
    quoteSpot = 'quotespot',
    ImageLinksGridSpot = 'imagelinksgridspot',
    QuoteFromExpertSpot = 'quotefromexpertspot',
    RichTextSpot = 'richtextspot',
    TwoColumnsTableSpot = 'twocolumntablespot',
    TrustBoxSpot = 'trustboxspot',
    UspSpot = 'uspspot',
    ImageVideoWithTextSpot = 'imagevideowithtextspot',
    UsedCarsFiltersSpot = 'usedcarsfiltersspot',
    EuroNCAPScoreSpot = 'euroncapscorespot',
    ReviewsSpot = 'reviewsspot',
    VehicleVariantsOverviewSpot = 'vehiclevariantsoverviewspot',
    VehicleVariantsLeasingSpot = 'vehiclevariantsleasingspot',
    UsedCarsListQuerySpot = 'usedcarslistqueryspot',
    CarouselContentSpot = 'carouselcontentspot',
    NewCarsModelCardListSpot = 'newcarsmodelcardlistspot',
    FilterMakesSpot = 'filtermakesspot',
    FilterModelsSpot = 'filtermodelsspot',
    NewsletterSignupSpot = 'newslettersignupspot',
    LouconLeasingVariantsSpot = 'louconleasingvariantsspot',
}
