import { Directive, ElementRef } from '@angular/core';

import { animateTo, fadeIn } from '../animations/easing.animations';
import { FeatureDetectionService } from '../helpers/feature-detection.service';

@Directive({
    selector: '[appImageLoadFadeIn]',
})
export class ImageLoadFadeInDirective {
    transitionDuration = 600;

    constructor(
        private el: ElementRef,
        private readonly featureDetectionService: FeatureDetectionService
    ) {
        if (this.featureDetectionService.isBrowser() && this.featureDetectionService.hasWebAnimation()) {
            const element: HTMLImageElement | HTMLVideoElement = this.el.nativeElement;

            if (element) {
                element.style.opacity = '0'; // Fixes the onload glitch

                if (element instanceof HTMLImageElement) {
                    element.onload = () => {
                        this._animateIn(element);
                    };
                } else if (element instanceof HTMLVideoElement) {
                    element.onloadeddata = () => {
                        this._animateIn(element);
                    };
                }
            }
        }
    }

    private _animateIn(element: HTMLImageElement | HTMLVideoElement) {
        element.style.opacity = ''; // Fixes the onload glitch
        animateTo(
            element,
            [
                {
                    opacity: '0',
                    offset: 0,
                },
            ],
            {
                duration: this.transitionDuration,
                easing: fadeIn,
            }
        ).finished.then(() => {
            if (element) {
                element.style.opacity = '';
            }
        });
    }
}
