import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ISettingsResponse } from '@impact/data';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { TrackingService } from '../utils/tracking/tracking.service';

export enum SiteIds {
    BNDK = 'Bjarne Nielsen',
    BHDK = 'Bilernes Hus',
    LEASINGDK = 'Leasing',
    MOTORPOINT = 'Motorpoint',
}

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    private settings$ = new ReplaySubject<ISettingsResponse>(1);
    private siteId$ = new ReplaySubject<string>(1);
    private globalPages$ = new ReplaySubject<any>(1);
    private globalUSPs$ = new ReplaySubject<any>(1);
    private globalUSPb2bs$ = new ReplaySubject<any>(1);

    metaLinks = this.settings$.pipe(map(settings => settings.metaLinks));

    constructor(
        private http: HttpClient,
        @Inject(DOCUMENT) private _document: Document,
        trackingService: TrackingService
    ) {
        this.get().subscribe(settings => {
            this.setBrowserLanguage(settings.languageCode);
            trackingService.initGtm(settings.gtmId);
        });

        this.http.get<ISettingsResponse>('/api/settings').subscribe((res: ISettingsResponse) => {
            this.settings$.next(res);
            this.siteId$.next(res.siteNameID);
            this.globalPages$.next(res.globalPages);
            this.globalUSPs$.next(res.globalUSPList);
            this.globalUSPb2bs$.next(res.globalUSPListb2b);
        });
    }

    get(): Observable<ISettingsResponse> {
        return this.settings$.asObservable();
    }

    getSiteId(): Observable<string> {
        return this.siteId$.asObservable();
    }

    getGlobalpages(): Observable<any> {
        return this.globalPages$.asObservable();
    }

    getGlobalUSPb2bs(): Observable<any> {
        return this.globalUSPb2bs$.asObservable();
    }

    getGlobalUSPs(): Observable<any> {
        return this.globalUSPs$.asObservable();
    }

    private setBrowserLanguage(languageKey: string): void {
        if (this._document && this._document.documentElement) {
            this._document.documentElement.lang = languageKey;
        }
    }
}
