import {
    ChangeDetectorRef,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewContainerRef,
} from '@angular/core';

import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';
import { getLazySpotComponent, spotComponents } from './spot-components';

@Directive({
    selector: '[appGenericSpot]'
})
export class GenericSpotDirective implements OnChanges, OnInit {
    @Input()
    spotType?: string;

    @Input()
    spotData: any;

    @Input()
    columns: number;

    private observer: IntersectionObserver;
    private options: IntersectionObserverInit;

    constructor(
        private viewContainer: ViewContainerRef,
        private cfResolver: ComponentFactoryResolver,
        private cdr: ChangeDetectorRef,
        private el: ElementRef,
        private featureDetectionService: FeatureDetectionService
    ) { }
    
    ngOnInit() {
        if (this.spotType
            && !this.featureDetectionService.isBrowser()) {
            this.viewContainer.clear();
            this.loadSpot();
        }
    }

    ngOnChanges(change: SimpleChanges) {
        if (
            change.spotData.currentValue.alias !== 'carouselspot' && 
            change.spotData.currentValue.alias !== 'louconleasingvariantsspot' &&
            this.spotType
        ) {
            // Start out by clearing the view container
            this.viewContainer.clear();

            const element: HTMLElement = this.el.nativeElement;

            if (element && this.featureDetectionService.hasIntersectionObserver()) {
                this.options = {
                    rootMargin: '0px 0px 0px 0px',
                    threshold: 0,
                };
    
                this.observer = new IntersectionObserver((entries) => {
                    for (const entry of entries) {
                        if (entry.isIntersecting) {
                            this.observer.unobserve(entry.target);
                            this.observer.disconnect();
                            this.loadSpot();
                        }
                    }
                }, this.options);
                this.observer.observe(element);
            }
        } else {
            this.loadSpot();
        }
    }

    async loadSpot() {
        let componentClass: any = spotComponents.find(component => component.ref === this.spotType);

        if (!componentClass) {
            // check if the spot is lazy
            const lazySpotComponent = getLazySpotComponent(this.spotType);

            if (lazySpotComponent) {
                const { LazySpotComponent } = await lazySpotComponent;
                componentClass = LazySpotComponent;
                this.cdr.markForCheck();
            }
        }
 
        if (componentClass === undefined) {
            console.warn(`Could not find spot type: ${this.spotType}`);
            return;
        }

        const spotComponentFactory = this.cfResolver.resolveComponentFactory(componentClass);
        const spotComponent: ComponentRef<any> = this.viewContainer.createComponent(spotComponentFactory);

        spotComponent.instance['data'] = this.spotData;
        spotComponent.instance['columns'] = this.columns;
    }
}
