import { PAGE_TYPES } from '@impact/data';

export const getLazyPageComponent = (template: string) => {
    switch (template) {
        case PAGE_TYPES.HOME_PAGE:
            return import('./home-page/home-page.component');
        case PAGE_TYPES.NEW_CARS_OVERVIEW_PAGE:
            return import('./new-cars-page/new-cars-page.component');
        case PAGE_TYPES.USED_CARS_OVERVIEW_PAGE:
            return import('./filter-page/filter-page.component');
        case PAGE_TYPES.USED_CARS_DETAILS_PAGE:
            return import('./used-car-details-page/used-car-details-page.component');
        case PAGE_TYPES.GRID_LAYOUT_PAGE:
            return import('./grid-layout-page/grid-layout-page.component');
        case PAGE_TYPES.LEFT_NAVIGATION_GRID_LAYOUT_PAGE:
            return import('./left-navigation-grid-layout-page/left-navigation-grid-layout-page.component');
        case PAGE_TYPES.NAVIGATION_SECTION_PAGE:
            return import('./navigation-section-page/navigation-section-page.component');
        case PAGE_TYPES.ARTICLES_OVERVIEW_PAGE:
            return import('./articles-overview-page/articles-overview-page.component');
        case PAGE_TYPES.ARTICLE_PAGE:
            return import('./article-page/article-page.component');
        case PAGE_TYPES.CAMPAIGN_PAGE:
            return import('./campaign-page/campaign-page.component');
        case PAGE_TYPES.CHECKOUT_PAGE:
            return import('./checkout/checkout-page.component');
        case PAGE_TYPES.LEASING_CHECKOUT_PAGE:
            return import('./leasing-checkout/leasing-checkout-page.component');
        case PAGE_TYPES.ORDER_CONFIRMATION_PAGE:
            return import('./order-confirmation-page/order-confirmation-page.component');
        case PAGE_TYPES.BOOK_WORKSHOP_PAGE:
            return import('./book-workshop-page/book-workshop-page.component');
        case PAGE_TYPES.VEHICLE_BRAND_PAGE:
            return import('./vehicle-brand-page/vehicle-brand-page.component');
        case PAGE_TYPES.VEHICLE_MODEL_PAGE:
            return import('./vehicle-model-page/vehicle-model-page.component');
        case PAGE_TYPES.VEHICLE_MODEL_SUBPAGE:
            return import('./vehicle-model-subpage/vehicle-model-subpage.component');
        case PAGE_TYPES.VEHICLE_LEASING_BRAND_PAGE:
            return import('./vehicle-leasing-brand-page/vehicle-leasing-brand-page.component');
        case PAGE_TYPES.VEHICLE_LEASING_MODEL_PAGE:
            return import('./vehicle-leasing-model-page/vehicle-leasing-model-page.component');
        case PAGE_TYPES.VEHICLE_VARIANT_PAGE:
            return import('./vehicle-variant-page/vehicle-variant-page.component');
        case PAGE_TYPES.DEALERSHIPS_OVERVIEW_PAGE:
            return import('./dealerships-overview-page/dealerships-overview-page.component');
        case PAGE_TYPES.DEALERSHIP_PAGE:
            return import('./dealership-page/dealership-page.component');
        case PAGE_TYPES.B2B_SECTION_PAGE:
            return import('./b2b-section-page/b2b-section-page.component');
        case PAGE_TYPES.NOT_FOUND_PAGE:
            return import('./not-found-page/not-found-page.component');
        case PAGE_TYPES.FILTER_PAGE:
            return import('./filter-page/filter-page.component');
        default:
            return null;
    }
};
