import { Component, Input } from '@angular/core';
import { IImage } from '@impact/data';

@Component({
    selector: 'app-vimeo-player',
    template: `
        <div class="vimeo-player">
            <img [src]="thumbnail?.url + '?format=webp'" class="vimeo-player__thumbnail" />
            <iframe
                [attr.data-src]="generateVideoPath"
                class="vimeo-player__video swiper-lazy"
                width="640"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
            ></iframe>
        </div>
    `,
})
export class VimeoPlayerComponent {
    @Input() videoId?: string;
    @Input() thumbnail?: IImage;

    get generateVideoPath() {
        return `https://player.vimeo.com/video/${this.videoId}?background=1`;
    }
}
