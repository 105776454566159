import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phonenumber' })
export class PhoneNumberPipe implements PipeTransform {
    transform(value?: number | string | null) {
        if (value) {
            value = value.toString();

            if (value && value.match(/^(\d{8})$/)) {
                const formattedNumber = value.replace(/(\d{4})(\d{4})/, '$1 $2');
                value = formattedNumber;
            } else {
                console.warn('[BN log: Phonenumber pipe unable to parse number]');
            }
        } else {
            console.warn('[BN log: Phonenumber pipe unable to parse number]');
        }

        return value;
    }
}
