export const easeSmoothOut = 'cubic-bezier(0.23, 0, 0.13, 1)'; // Equals scss var $SmoothOut
export const smoothIn = 'cubic-bezier(0.885, 0, 0.68, 1)'; // Equals scss var $SmoothIn
export const smoothInOut = 'cubic-bezier(0.165, 0.84, 0.44, 1)'; // Equals scss var $SmoothInOut
export const ease = 'cubic-bezier(0.6, 1.5, 0.6, 1)';
export const fadeIn = 'cubic-bezier(0.390, 0.575, 0.565, 1.000)';

 // Same as in _variables-animations.scss
export const easeInQuart = 'cubic-bezier(0.895, 0.03, 0.685, 0.22)';
export const easeOutQuart = 'cubic-bezier(0.165, 0.84, 0.44, 1)';
export const easeinOutQuart = 'cubic-bezier(0.77, 0, 0.175, 1)';
export const easeOutExpo = 'cubic-bezier(0.19, 1, 0.22, 1)';

export const ANIMATION_TIMINGS = '300ms cubic-bezier(0.35, 0, 0.25, 1)';

export const timingFast = 200;
export const timingNormal = 300;
export const timingSlow = 500;

export const animateTo = (element: HTMLElement, keyframes: Keyframe[], options?: KeyframeAnimationOptions): Animation => {
    const animate = element.animate(keyframes, { fill: 'both', ...options });
    animate.addEventListener('finish', () => {
        animate.cancel();
    });
    return animate;
};
