import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INavigationResponse, PAGE_TYPES } from '@impact/data';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';

import { MobileMenuTypes } from './mobile-menu';
import { MobileNavigationService } from './mobile-navigation.service';

@Component({
    selector: 'app-mobile-nav-list',
    template: `
        <div class="mobile-menu__heading">
            <a *ngIf="headingUrl; else noHeadingUrlTmpl" [routerLink]="headingUrl" (click)="closeMenu()">
                {{ heading }}
            </a>
            <ng-template #noHeadingUrlTmpl>{{ heading }}</ng-template>
        </div>

        <ul class="mobile-menu__list" *ngIf="navigationChildren">
            <li *ngFor="let item of navigationChildren" class="mobile-menu-item">
                <app-mobile-menu-link-item [item]="item" (btnClick)="navigateTo(item)"></app-mobile-menu-link-item>
            </li>
        </ul>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavListComponent {
    @Input() navigationChildren?: INavigationResponse[];
    @Input() heading?: string;
    @Input() headingUrl?: string;

    pageTypes = PAGE_TYPES;

    constructor(private overlayRef: ImpactOverlayRef, private mobileMenuService: MobileNavigationService) {}

    navigateTo(navigation: INavigationResponse) {
        this.mobileMenuService.navigateByTemplate(navigation, navigation.template);
    }

    closeMenu() {
        this.overlayRef.close();
    }
}
