import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ILink, PAGE_TYPES, PageResponse } from '@impact/data';

import { HeaderService } from '../core/header.service';
import { MetaService } from '../meta/meta.service';
import { NavigationService } from '../navigation/navigation.service';
import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';
import { ImageUrl } from '../utils/helpers/image-helper';
import { TrackingService } from '../utils/tracking/tracking.service';
import { NotFoundComponent } from './not-found-page/not-found-page.component';
import { getLazyPageComponent } from './page-components';

@Component({
    selector: 'app-page',
    template: '<dynamic-component [component]="component" [inputs]="inputs"></dynamic-component>',
})
export class PageComponent implements OnInit, OnDestroy {
    component: any;
    inputs: { data?: any } = {};
    quicklinksSearch: ILink[] = [];

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly metaService: MetaService,
        private readonly trackingService: TrackingService,
        private readonly featureDetectionService: FeatureDetectionService,
        private readonly headerService: HeaderService,
        private readonly navigationService: NavigationService,
        private readonly cd: ChangeDetectorRef
    ) {}

    async ngOnInit() {
        // Read the content from the route snapshot (this 'content' is the name of the resolve)
        let data: PageResponse = this.activatedRoute.snapshot.data.content;
        // Notify listeners that a page has been loaded
        this.navigationService.setLoadingPageData(false);

        if (!data) {
            return;
        }

        // Set header transparency based on page settings, and activate B2B header if needed
        this.headerService.setHeaderTransparency(data.transparentSiteHeader);
        this.navigationService.setSiteB2BState(data.isB2BPage);

        const lazyPageComponent = getLazyPageComponent(data.template);

        if (lazyPageComponent) {
            const { LazyComponent } = await lazyPageComponent;
            this.component = LazyComponent;
            this.cd.markForCheck();
        } else {
            this.component = NotFoundComponent;
            data = {
                ...data,
            };
        }

        this.inputs = { data };

        // SEO-related information extracted from the data object
        this.metaService.changePage(this.component.ref ?? data.template);
        this.metaService.setTitle(data.meta.title);
        this.metaService.setDescription(data.meta.description);
        this.metaService.setKeywords(data.meta.keywords);
        this.metaService.setNoIndex(data.meta.excludeFromRobots);
        this.metaService.setCanonical(data.meta.canonical);

        // Some page types should handle tracking themselves
        let allowTracking = true;
        switch (data.template) {
            case PAGE_TYPES.USED_CARS_DETAILS_PAGE: // Handled in used-car-details-page.component.ts
            case PAGE_TYPES.USED_CARS_OVERVIEW_PAGE: // Handled in filter-page.component.ts
                allowTracking = false;
                break;
        }

        if (allowTracking) {
            this.trackingService.trackVirtualPageview(data.template);
        }

        // Set CMS page ID for impact hook extension
        this.metaService.setImpactHook(data.id);

        // Open graph
        this.metaService.setOpenGraph({
            title: data.meta.title || '',
            image: data.meta.image
                ? {
                      url: ImageUrl(data.meta.image, { width: 1200, height: 630, mode: 'crop' }),
                      altText: data.meta.image.altText,
                      width: '1200',
                      height: '630',
                  }
                : undefined,
            type: 'website',
        });
    }

    ngOnDestroy() {
        this.featureDetectionService.isFirstPageLoad = false;
    }
}
