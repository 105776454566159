import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { RichTextModule } from '../rich-text/rich-text.module';
import { NotificationBarComponent } from './notification-bar.component';
import { LinkModule } from '../link/link.module';

@NgModule({
  declarations: [NotificationBarComponent],
  imports: [CommonModule, RichTextModule, RouterModule, TranslateModule, LinkModule],
  exports: [NotificationBarComponent]
})
export class NotificationBarModule { }
