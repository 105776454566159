import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID, SecurityContext } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request as ExpressRequest } from 'express';

export interface OpenGraph {
    title: string;
    image?: {
        url: string;
        width?: string;
        height?: string;
        altText?: string;
    };
    type: string;
    author?: string;
}

@Injectable({
    providedIn: 'root',
})
export class MetaService {
    previousPageRef?: string;
    currentPageRef?: string;
    private _maintainedScripts: string[] = [];
    private _canInsertDynamicScripts: boolean;

    constructor(
        private title: Title,
        private meta: Meta,
        private readonly sanitizer: DomSanitizer,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private doc: Document,
        @Optional() @Inject(REQUEST) private request: ExpressRequest
    ) {}

    isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    canInsertDynamicScripts(): boolean {
        if (this._canInsertDynamicScripts !== undefined) {
            return this._canInsertDynamicScripts;
        }
        this._canInsertDynamicScripts = false;

        if (this.isBrowser()) {
            this._canInsertDynamicScripts = document && 'createRange' in document;
        }

        return this._canInsertDynamicScripts;
    }

    changePage(ref: string): void {
        this.previousPageRef = this.currentPageRef;
        this.currentPageRef = ref;
    }

    setTitle(title?: string): void {
        if (title) {
            this.title.setTitle(title);
        }
    }

    getTitle(): string {
        return this.title.getTitle();
    }

    setDescription(content?: string): void {
        content = content || '';

        this.meta.updateTag({
            name: 'description',
            content,
        });

        this.meta.updateTag({
            property: 'og:description',
            content,
        });
    }

    setKeywords(content?: string): void {
        content = content || '';

        this.meta.updateTag({
            name: 'keywords',
            content,
        });
    }

    setNoIndex(shouldBlock: boolean): void {
        if (shouldBlock) {
            this.meta.updateTag({
                name: 'robots',
                content: 'noindex',
            });
        } else {
            this.meta.removeTag('name="robots"');
        }
    }

    setManualCanonical(canonicalUrl: string) {
        this.doc.querySelectorAll('[rel="canonical"]').forEach((x) => x.remove());

        const canonicalTag = this.doc.createElement('link');
        this.doc.head.appendChild(canonicalTag);

        const baseUrl = this.getBaseUrl();

        canonicalTag.setAttribute('rel', 'canonical');
        canonicalTag.setAttribute('href', baseUrl + canonicalUrl.toLowerCase());
    }

    setCanonical(content?: string): void {
        this.doc.querySelectorAll('[rel="canonical"]').forEach((x) => x.remove());

        if (this.doc.head) {
            if (content) {
                if (content.indexOf('http') !== 0) {
                    content = this.getBaseUrl() + content;
                }
            } else {
                content = this.getAbsoluteUrl();
            }

            const canonicalTag = this.doc.createElement('link');
            this.doc.head.appendChild(canonicalTag);

            canonicalTag.setAttribute('rel', 'canonical');
            canonicalTag.setAttribute('href', content.toLowerCase());
        }
    }

    setRelPrevNext(prevUrl?: string, nextUrl?: string): void {
        this.doc.querySelectorAll('[rel="prev"], [rel="next"]').forEach((x) => x.remove());
        const createLink = (rel: 'prev' | 'next', link?: string) => {
            if (this.doc.head && link) {
                const canonicalTag = this.doc.createElement('link');
                this.doc.head.appendChild(canonicalTag);
                canonicalTag.setAttribute('rel', rel);
                canonicalTag.setAttribute('href', link);
            }
        };
        createLink('prev', prevUrl);
        createLink('next', nextUrl);
    }

    setOpenGraph(data: OpenGraph): void {
        // Title
        this.meta.updateTag({
            property: 'og:title',
            content: data.title,
        });

        // Type
        this.meta.updateTag({
            property: 'og:type',
            content: data.type,
        });

        // Url
        this.meta.updateTag({
            property: 'og:url',
            content: this.getAbsoluteUrl(),
        });

        // Image
        if (data.image) {
            this.meta.updateTag({
                property: 'og:image',
                content: data.image.url,
            });
            this.meta.updateTag({
                property: 'og:image:width',
                content: data.image.width || '',
            });
            this.meta.updateTag({
                property: 'og:image:height',
                content: data.image.height || '',
            });
            this.meta.updateTag({
                property: 'og:image:alt',
                content: data.image.altText || '',
            });
        } else {
            this.meta.removeTag('property="og:image"');
            this.meta.removeTag('property="og:image:width"');
            this.meta.removeTag('property="og:image:height"');
            this.meta.removeTag('property="og:image:alt"');
        }
    }

    public setPreloadResource(src: string, as: string, type?:string) {
        const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(src)) || '';

        if (!sanitizedUrl) {
            return;
        }

        const link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('href', src);
        link.setAttribute('as', as);
        if (type) {
            link.setAttribute('type', type);
        }
        link.rel = 'preload';
        this.doc.getElementsByTagName('head')[0].appendChild(link);

        return link;
    }

    public setPreconnectResource(src: string, rel:string = 'preconnect') {
        const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(src)) || '';

        if (!sanitizedUrl) {
            return;
        }

        const link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('href', src);

        link.rel = rel;
        this.doc.getElementsByTagName('head')[0].appendChild(link);

        return link;
    }

    setImpactHook(pageId: any): void {
        if (this.doc && this.isBrowser()) {
            this.doc.documentElement.dataset.impactHookCmsId = pageId;
        }
    }

    getBaseUrl(): string {
        return this.request ? `${this.request.protocol}://${this.request.hostname}` : window.location.origin;
    }

    getCurrentUrl(): string {
        return this.request ? this.getBaseUrl() + this.request.path : window.location.href;
    }

    getAbsoluteUrl(): string {
        const url = this.getCurrentUrl();
        return url.split('?')[0];
    }

    appendScript(key: string, script: string, where: HTMLElement, maintain = true): void {
        if (this.canInsertDynamicScripts() && (!maintain || !this._hasMaintainedScript(key))) {
            this._maintainedScripts.push(key);

            const fragment = this._createFragment(script);
            where.appendChild(fragment);
        }
    }

    insertScript(key: string, script: string, where: HTMLElement, selector: string): void {
        if (this.canInsertDynamicScripts() && !this._hasMaintainedScript(key)) {
            this._maintainedScripts.push(key);

            const fragment = this._createFragment(script);
            where.insertBefore(fragment, document.body.querySelector(selector));
        }
    }

    private _hasMaintainedScript(key: string): boolean {
        return this._maintainedScripts.includes(key);
    }

    private _createFragment(script: string) {
        return document.createRange().createContextualFragment(script);
    }
}
