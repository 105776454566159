import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { INavigationResponse } from '@impact/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavigationService } from '../navigation.service';

@Component({
    selector: 'app-main-navigation',
    template: `
        <nav>
            <ul class="main-navigation__list" role="navigation" aria-label="main navigation">
                <ng-container *ngFor="let item of navigationItems">
                    <li *ngIf="item && item.includeInNavigation && !item.hideInMenu" class="main-navigation__list-item">
                        <a class="main-navigation__list-item-link"
                            (keydown.enter)="mainNavigationLinkClick(item, $event)"
                            [routerLinkActive]="'is-route-active'"
                            [ngClass]="{
                                'is-megamenu-active': activeMegamenuItem === item?.id,
                                'is-clicked-active': clickedActiveItemUrl === item?.url,
                                'is-route-active': activeRouteItem === item?.url
                            }"
                            [attr.aria-current]="activeMegamenuItem === item.id || activeRouteItem === item.url ? 'page' : undefined"
                            [routerLink]="item?.pageLink?.cleanUrl || item.url"
                            [queryParams]="item?.pageLink?.urlParams">

                            <span class="site-main-nav__link-text-wrapper" (click)="mainNavigationLinkClick(item, $event)">
                                <span class="site-main-nav__link-text" (click)="mainNavigationLinkClick(item, $event)">{{ item.name }}</span>
                            </span>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </nav>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavigationComponent implements OnInit, OnDestroy {
    navigationItems: INavigationResponse[];
    b2bNavigationItems: INavigationResponse[];
    mainNavigation: INavigationResponse[];
    activeMegamenuItem: any; // Keeps state of which megamenu item is active
    activeRouteItem: string | undefined; // Keeps state of which route item is active
    clickedActiveItemUrl: string;
    currentRoute: string; // Holds current route for switching between active states
    megaNavigationIsActive: boolean;

    private unsubscribe = new Subject<void>();

    constructor(
        private navigationService: NavigationService,
        private cdr: ChangeDetectorRef
    ) {
        // Close MegaNavigation on routing
        this.navigationService.currentRouteUrl$.subscribe((route) => {
            this.currentRoute = route;
            this.activeRouteItem = this.navigationService.getRouteTopLevel(route);
            this.navigationService.showMegaNavigation(false);
        });
    }

    ngOnInit() {
        // Detect when MegaNavigation is hidden, to reset the states of the navigation items
        this.navigationService.isMegaNavigationActive().subscribe((data) => {
            if (!data.isActive) {
                this.megaNavigationIsActive = false;
                this.activeMegamenuItem = undefined;
                this.clickedActiveItemUrl = '';

                if (this.currentRoute) {
                    this.activeRouteItem = this.navigationService.getRouteTopLevel(this.currentRoute);
                }
            } else {
                this.megaNavigationIsActive = true;
            }
            this.cdr.markForCheck();
        });

        this.navigationService.getSiteB2Bstate().subscribe((isB2B: boolean) => {
            if (isB2B) {
                this.showB2BNavigation();
            } else {
                this.showMainNavigation();
            }
        });
    }

    // Show Main navigation
    private showMainNavigation() {
        this.navigationService.getMainNavigation()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((navigation) => {
                this.navigationItems = navigation.filter((item) => item.includeInNavigation);
                this.cdr.markForCheck();
            });
    }

    // Show B2B navigation
    private showB2BNavigation() {
        this.navigationService.getB2BNavigation()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((navigation) => {
                if (navigation) {
                    this.navigationItems = navigation.filter((item) => item.includeInNavigation);
                    this.cdr.markForCheck();
                }
            });
    }

    // Main navigation click handler
    mainNavigationLinkClick(item: INavigationResponse, $event: any) {
        if (this.isMegaNavigationPage(item)) {
            // Prevent routing to be able to display MegaNavigation
            $event.preventDefault();
            $event.stopPropagation();
            this.clickedActiveItemUrl = item.url;

            if (this.megaNavigationIsActive) {
                // If user clicks the currently open MegaNavigation item, we close it
                if (this.activeMegamenuItem === item.id) {
                    this.activeRouteItem = this.navigationService.getRouteTopLevel(this.currentRoute);
                    this.navigationService.showMegaNavigation(false);
                } else {
                    // Change the current contents of the MegaNavigation
                    this.activeMegamenuItem = item.id;
                    this.navigationService.showMegaNavigation(true, item);
                }
            } else {
                // Activate MegaNavigation
                this.activeRouteItem = this.navigationService.getRouteTopLevel(this.currentRoute);
                this.activeMegamenuItem = item.id;
                this.navigationService.showMegaNavigation(true, item);
            }
        } else {
            // Open link / go to page. MegaNavigation will close when routing
            // Set activeRouteItem if route is the same as currentRoute
            this.activeRouteItem = this.navigationService.getRouteTopLevel(this.currentRoute);
            this.activeMegamenuItem = undefined;
            this.navigationService.showMegaNavigation(false, undefined);
        }

        this.cdr.markForCheck();
    }

    // Check wether item is eligible for showing mega navigation
    isMegaNavigationPage(item: INavigationResponse): boolean {
        if (!item.children?.length) {
            return false;
        }

        return true;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    trackByMethod(index: number, item: INavigationResponse) {
        return item.id || index;
    }
}
