import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from '../button/button.module';
import { CircleCheckmarkModule } from '../circle-checkmark/circle-checkmark.module';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { PanelModule } from '../panel/padel.module';
import { RichTextModule } from '../rich-text/rich-text.module';
import { UtilsModule } from '../utils/utils.module';
import { LeasingConfiguratorModule } from './configurator/leasin-configurator.module';
import { LeasingMiniConfiguratorComponent } from './mini-configurator/leasing-mini-configurator.component';

@NgModule({
    declarations: [
        LeasingMiniConfiguratorComponent,
        // LeasingConfiguratorComponent
    ],
    imports: [
        ButtonModule,
        CircleCheckmarkModule,
        CommonModule,
        FormElementsModule,
        PanelModule,
        FormsModule,
        ReactiveFormsModule,
        RichTextModule,
        UtilsModule,
        TranslateModule.forChild(),
        LeasingConfiguratorModule
    ],
    exports: [
        LeasingMiniConfiguratorComponent,
        // LeasingConfiguratorComponent
    ],
})
export class LeasingModule {}
