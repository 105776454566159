import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ExpansionPanelModule } from '../../expansion-panel/expansion-panel.module';
import { LinkModule } from '../../link/link.module';
import { LogosModule } from '../../logos/logos.module';
import { SpotsUiModule } from '../../spots/spots-ui.module';
import { UtilsModule } from '../../utils/utils.module';
import { SeoSiteFooterComponent } from './seo-site-footer.component';

@NgModule({
    imports: [
        CommonModule,
        LogosModule,
        UtilsModule,
        TranslateModule.forChild(),
        ExpansionPanelModule,
        LinkModule,
        SpotsUiModule
    ],
    exports: [
        SeoSiteFooterComponent
    ],
    declarations: [
        SeoSiteFooterComponent
    ],
    providers: [],
})
export class SeoSiteFooterModule { }
