import { Component, Input, OnInit } from '@angular/core';
import { IImage } from '@impact/data';

import { CroppingMode, IImageOptions, ImageUrl } from './helpers/image-helper';

export interface IImageBreakpoints {
    breakpoint: number;
    height?: number;
    mode?: CroppingMode;
    width?: number;
}

// This component is for using srcset and sizes approach for responsive images
// https://webdesign.tutsplus.com/tutorials/quick-tip-how-to-use-html5-picture-for-responsive-images--cms-21015

@Component({
    selector: 'app-responsive-image',
    template: `
        <img
            [ngClass]="cssClasses"
            [srcset]="srcSet | safe: 'resourceUrl'"
            [sizes]="sizes"
            [src]="image?.url + '?quality=90&format=webp'"
            [alt]="image?.altText"
            [width]="image?.width"
            [height]="image?.height"
            [attr.alt]="image?.altText"
            [attr.loading]="lazyLoad ? 'lazy' : null"
            appImageLoadFadeIn
        />
    `,
})
export class ResponsiveImageComponent implements OnInit {
    @Input() image: IImage | undefined;
    @Input() breakpoints?: IImageBreakpoints[];
    @Input() cssClasses = '';
    @Input() lazyLoad = true;

    srcSet: string;
    sizes: string;

    ngOnInit() {
        if (this.image) {
            this.generateSrcset();
        } else {
            console.warn('[BN warning: No image set for responsive image component]');
        }
    }

    generateSrcset(): void {
        if (this.breakpoints?.length && this.image) {
            const tempSrcSet: { url: string; srcSetWidth: number }[] = [];
            let srcSet: string[] = [];
            const sizes: string[] = [];

            // Generate url with params and the srcSet/sizes arrays
            for (const { breakpoint, height, width, mode } of this.breakpoints) {
                const imageParams: IImageOptions = {
                    height,
                    mode,
                    width,
                    quality: '90',
                    format: 'webp',
                };

                tempSrcSet.push({
                    url: ImageUrl(this.image, imageParams),
                    srcSetWidth: width ? width : breakpoint,
                });

                srcSet = tempSrcSet.sort((a, b) => a.srcSetWidth - b.srcSetWidth).map(({ url, srcSetWidth }) => `${url} ${srcSetWidth}w`);
                sizes.push(`(min-width: ${breakpoint}px) ${width ? width : breakpoint}px`);
            }

            this.sizes = sizes.reverse().join(', ');
            this.srcSet = srcSet.join(', ');
        }
    }
}
