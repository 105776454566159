import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';
import * as icons from '../../../icons';
import { MobileAnimationState, mobileAnimation } from '../../utils/animations/mobile-menu.animations';
import { IMobileMenu } from './mobile-menu';

@Component({
    selector: 'app-mobile-used-cars-brands-models-nav',
    template: `
        <div [@slideContent]="animationState">
            <div class="mobile-menu__section-header">
                <h1>{{ currentMenu.navigation?.name }}</h1>
            </div>

            <div class="mobile-menu__scroll-container mobile-menu__scroll-container--sub-header">
                <ul class="mobile-menu__list">
                    <li class="mobile-menu-item">
                        <a [routerLink]="currentMenu.navigation?.url" (click)="overlayRef.close()">
                            <span class="mobile-menu-item__text"
                                >{{ 'car_data.see_all_from_brand' | translate }} {{ currentMenu.navigation?.name }}</span
                            >
                            <i class="mobile-menu-item__icon--nav" [innerHTML]="icons.chevronRight | safe: 'html'"></i>
                        </a>
                    </li>
                    <li *ngFor="let brand of currentMenu?.navigation?.children" class="mobile-menu-item">
                        <a [routerLink]="brand.url" (click)="overlayRef.close()">
                            <span class="mobile-menu-item__text">{{ brand.name }}</span>
                            <i class="mobile-menu-item__icon--nav" [innerHTML]="icons.chevronRight | safe: 'html'"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [mobileAnimation],
})
export class MobileUsedCarsBrandsModelsNavComponent {
    @Input() currentMenu: IMobileMenu;
    icons = icons;

    animationState: MobileAnimationState = 'enter';

    constructor(public overlayRef: ImpactOverlayRef) {}
}
