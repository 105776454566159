import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '../../utils/utils.module';

import { MetaMenuComponent } from './meta-menu.component';

@NgModule({
    imports: [RouterModule, BrowserModule, UtilsModule],
    exports: [MetaMenuComponent],
    declarations: [MetaMenuComponent],
    providers: [],
})
export class MetaMenuModule {}
