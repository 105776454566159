import { Injectable } from '@angular/core';
import { ILink } from '@impact/data';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { NavigationService } from '../navigation/navigation.service';
import { GlobalDataService } from './global-data.service';
import { SettingsService, SiteIds } from './settings.service';
import { STORAGE_SERVICE_KEYS, StorageService } from './storage.service';

export interface INotificationBarItem {
    name: string;
    text?: string;
    link: ILink;
    bgColor?: string;
    textColor?: string;
    id: string;
    showOnPage: ILink;
    cascadeNotification: boolean;
    siteName: string;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationBarService {
    activeNotifications: INotificationBarItem[];
    notifications$ = new BehaviorSubject<INotificationBarItem[]>([]);
    currentRouteUrl: string;
    siteIds = SiteIds;
    closedNotifications$ = new BehaviorSubject<string[]>(this.getClosedNotificationsFromStorage());

    constructor(
        private globalDataService: GlobalDataService,
        private settingsService: SettingsService,
        private navigationService: NavigationService,
        private storageService: StorageService
    ) {
        const globalNotifications$ = this.globalDataService.getGlobalNotifications();
        const currentRouteUrl$ = this.navigationService.currentRouteUrl$;
        const siteNameId$ = this.settingsService.getSiteId();
        const connectStream$ = combineLatest([globalNotifications$, currentRouteUrl$, siteNameId$]);

        connectStream$.pipe(
            map(([notifications, currentUrl, siteName]) => {
                const closedNotifications = this.getClosedNotificationsFromStorage();

                // Filter out notifications that should not be shown
                if (notifications?.children) {
                    this.activeNotifications = notifications.children.filter((item: any) => {
                        if (item.cascadeNotification) {
                            return !closedNotifications.includes(item.id) && currentUrl.includes(item.showOnPage.url) && item.siteName === siteName;
                        } else {
                            return !closedNotifications.includes(item.id) && currentUrl === item.showOnPage.url && item.siteName === siteName;
                        }
                    });
                    this.notifications$.next(this.activeNotifications);
                }
            }
        )).subscribe();
    }

    private getClosedNotificationsFromStorage(): string[] {
        let closedNotifications: string[] = [];

        const storage = this.storageService.getItem(STORAGE_SERVICE_KEYS.NOTIFICATIONS);
        if (storage) {
            closedNotifications = JSON.parse(storage);
        }

        return closedNotifications;
    }

    storeClosedNotification(item: any): void {
        const closedNotifications = this.getClosedNotificationsFromStorage();

        if (!closedNotifications.includes(item)) {
            closedNotifications.push(item);

            this.storageService.setItem(
                STORAGE_SERVICE_KEYS.NOTIFICATIONS,
                JSON.stringify(closedNotifications)
            );
        }
    }
}
