import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { ThemeService } from '../themes/theme.service';

@Injectable({
    providedIn: 'root',
})
export class GlobalVehicleDataService {

    constructor(
        private http: HttpClient,
        private themeService: ThemeService
    ) {}

    // Vehicle model exterior/interior gallery
    public getVehicleModelGallery(id: string): Observable<any> {
        if (!id) {
            console.warn('No model id provided for vehicleModelgallery');
            return EMPTY;
        }

        return this.http.get(`/api/vehicleData/vehicleModelGallery`, {params: {id}});
    }

    // Vehicle model card list
    public getVehicleModelCardList(id: string): Observable<any> {
        if (!id) {
            console.warn('No model id provided for vehicleModelCardListById');
            return EMPTY;
        }

        return this.http.get(`/api/vehicleData/vehicleModelCardListById`, {params: {id}});
    }

    // Vehicle model and variants
    // param id = Umbraco Brand ID
    public getVehicleModelAndVariants(id: string): Observable<any> {
        if (!id) {
            console.warn('No id provided for VehicleModelAndVariants');
            return EMPTY;
        }

        return this.http.get(`/api/vehicleData/vehicleModelAndVariants`, {params: {id}});
    }

    // Vehicle model and variants
    // param id = Umbraco Brand ID
    public getVehicleBrands(): Observable<any> {
        return this.http.get(`/api/vehicleData/getbrands`);
    }

    // Vehicle model and variants
    // param id = Umbraco Brand ID
    public getVehicleModels(id: string): Observable<any> {
        if (!id) {
            console.warn('No id provided for getModels');
            return EMPTY;
        }
        return this.http.get(`/api/vehicleData/getmodels`, {params: {id}});
    }

    // Vehicle brand theme
    // param id = Umbraco Brand/model ID
    public getVehicleBrandTheme(id: string): Observable<any> {
        if (!id) {
            console.warn('No id provided for getTheme');
            return EMPTY;
        }
        return this.http.get(`/api/globalData/GetTheme`, {params: {id}});
    }

    // Get brand theme from vehicle brand
    findAndSetBrandThemeForVehicle(vehicleId: string): void {
        this.getVehicleBrandTheme(vehicleId).subscribe((brandTheme: any) => {
            if (!brandTheme) {
                this.themeService.setActiveBrandTheme();
            } else {
                this.themeService.setActiveBrandTheme(brandTheme);
            }
        });
    }
}