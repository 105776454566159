import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SpotsUiModule } from '../spots/spots-ui.module';
import { UmbAreaComponent } from './umb-area/umb-area.component';
import { UmbGridComponent } from './umb-grid/umb-grid.component';
import { UmbRowComponent } from './umb-row/umb-row.component';
import { UmbSectionComponent } from './umb-section/umb-section.component';

@NgModule({
    imports: [CommonModule, SpotsUiModule],
    declarations: [UmbAreaComponent, UmbRowComponent, UmbSectionComponent, UmbGridComponent],
    exports: [UmbAreaComponent, UmbRowComponent, UmbSectionComponent, UmbGridComponent]
})
export class UmbracoGridModule {}
