import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IMegaNavigationSpotResponse, INavigationResponse, PAGE_TYPES } from '@impact/data';

import { chevronRight } from '../../../icons';
import { NavigationService } from '../navigation.service';
import { megaNavigationColumnsMaxSize } from './mega-navigation.component';

@Component({
    selector: 'app-mega-navigation-leasing-cars',
    template: `
        <div class="mega-navigation-layout-wrapper">
            <div class="mega-navigation-layout-wrapper-inner">
                <div class="mega-navigation__content-header" [ngClass]="{'is-ready': isReady}">
                    <h2 class="mega-navigation-spot-layout__content-header-text">{{ data?.name }}</h2>
                </div>

                <div class="mega-navigation-content-layout" [ngClass]="{'is-ready': isReady}">
                    <div class="mega-navigation__content">
                        <!-- LEFT COLUMN -->
                        <div class="mega-navigation__content-column-left">
                            <ul class="mega-navigation__navigation-list" *ngIf="navigationData?.leasingNavigation">
                                <li class="mega-navigation__navigation-list-item">
                                    <ng-container *ngTemplateOutlet="Level1Button; context: { 
                                        text: data?.leasingCarsNavigationHeader,
                                        category: data?.leasingCarsNavigationHeader
                                    }"></ng-container>
                                </li>
                                <ng-container *ngIf="navigationData?.secondaryNavigation.length">
                                    <li class="mega-navigation__navigation-list-item" *ngFor="let page of navigationData?.secondaryNavigation">
                                        <ng-container [ngSwitch]="page.hasChildren">
                                            <ng-container *ngSwitchCase="true">
                                                <ng-container *ngTemplateOutlet="Level1Button; context: { 
                                                    text: page?.name,
                                                    category: page?.name
                                                }"></ng-container>
                                            </ng-container>
                                            <a *ngSwitchCase="false"
                                                [routerLink]="page?.url"
                                                class="mega-navigation__navigation-list-link"
                                                (mouseover)="setActiveCategory('')">{{ page.name }}</a>
                                        </ng-container>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="mega-navigation__content-column-right">
                            <!-- Leasing vehicles navigation data -->
                            <ng-container *ngIf="navigationData?.leasingNavigation && activeNavigationCategory === data?.leasingCarsNavigationHeader">
                                <div class="mega-navigation__scroll-container">
                                    <ng-container *ngTemplateOutlet="CategoryLinks; context: { items: navigationData?.leasingNavigation }"></ng-container>
                                </div>
                            </ng-container>

                            <!-- Secondary navigation -->
                            <ng-container *ngIf="navigationData?.secondaryNavigation.length">
                                <ng-container *ngFor="let subPage of navigationData?.secondaryNavigation">
                                    <div class="mega-navigation__scroll-container" *ngIf="activeNavigationCategory === subPage?.name">
                                        <ng-container *ngTemplateOutlet="CategoryLinks; context: { items: subPage?.children }"></ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <!-- MEGA NAVIGATION SPOT -->
            <div class="mega-navigation__spot-wrapper" *ngIf="navigationSpot">
                <app-navigation-spot [data]="navigationSpot"></app-navigation-spot>
            </div>
        </div>

        <!-- TEMPLATES -->
        <ng-template #Level1Button let-text="text" let-category="category">
            <button type="button" class="mega-navigation__navigation-list-link"
                [ngClass]="{'is-active': activeNavigationCategory === category}"
                (click)="setActiveCategory(category)"
                (mouseover)="setActiveCategory(category)">
                {{text}}
            </button>
            <div class="mega-navigation__navigation-active-indicator">${chevronRight}</div>
        </ng-template>

        <ng-template #CategoryHeader let-text="text" let-url="url">
            <div class="mega-navigation-spot-layout__content-header">
                <h2 *ngIf="text"
                    class="mega-navigation-spot-layout__content-header-text">
                    {{ text }}
                </h2>
                <a [routerLink]="url" *ngIf="url" class="button--primary">Se alle {{ text }}</a>
            </div>
        </ng-template>

        <ng-template #CategoryLinks let-items="items">
            <ul class="mega-navigation-spot-layout__content-list"
                [ngClass]="{
                    'double-column': items.length > columnItemsSize,
                    'triple-column': items.length > columnItemsSize * 2,
                    'quadruple-column': !navigationSpot && items.length > columnItemsSize * 3
                }">
                <li *ngFor="let link of items">
                    <a [routerLink]="link?.url" [queryParams]="link?.param">{{ link?.name }}</a>
                </li>
            </ul>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegaNavigationLeasingCarsComponent implements OnChanges {
    @Input() data: INavigationResponse;

    navigationSpot: IMegaNavigationSpotResponse;
    pageTypes = PAGE_TYPES;

    navigationData: any;
    activeNavigationCategory: string;
    columnItemsSize = megaNavigationColumnsMaxSize;
    isReady: boolean;

    constructor(
        private cdr: ChangeDetectorRef,
        public navigationService: NavigationService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const pageData = changes.data?.currentValue;

        if (pageData.children) {
            this.navigationData = this.formatNavigation(pageData.children);

            this.navigationSpot = changes.data.currentValue.megaNavigationSpot;
            this.data = pageData;

            if (this.data.leasingCarsNavigationHeader) {
                this.setActiveCategory(this.data.leasingCarsNavigationHeader);
            }

            this.isReady = true;
            this.cdr.markForCheck();
        }
    }

    formatNavigation(data: INavigationResponse[]): any {
        const leasingNavigation = data.filter((item: INavigationResponse) => item.template === PAGE_TYPES.VEHICLE_LEASING_BRAND_PAGE && item.includeInNavigation);
        const secondaryNavigation = data.filter((item: INavigationResponse) => item.template !== PAGE_TYPES.VEHICLE_LEASING_BRAND_PAGE && item.includeInNavigation);

        return {
            leasingNavigation,
            secondaryNavigation
        }
    }

    setActiveCategory(category: string) {
        this.activeNavigationCategory = category;
    }
}
