import { AnimationEvent } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { IMetaLink, IMetaLinkB2B2C, INavigationResponse } from '@impact/data';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SettingsService } from '../../core/settings.service';
import { mobileAnimation, MobileAnimationState } from '../../utils/animations/mobile-menu.animations';
import { IMobileMenu, MobileMenuTypes } from './mobile-menu';
import { MobileNavigationService } from './mobile-navigation.service';

@Component({
    selector: 'app-mobile-menu',
    template: `
        <aside
            class="mobile-menu"
            [@slideContent]="animationState"
            (@slideContent.done)="onAnimationDone($event)"
            *ngIf="menuList$ | async as menuList"
        >
            <nav role="navigation" aria-label="mobile navigation">
                <app-mobile-overlay-header
                    [showBack]="(isRoot$ | async) === false"
                    (back)="back()"
                    (closed)="closeMenu()"
                ></app-mobile-overlay-header>
                <div
                    class="mobile-menu__root-conte1nt mobile-menu__scroll-container"
                    *ngIf="(currentMenu$ | async) === undefined; else itemNavigation"
                >
                    <app-mobile-nav-list [navigationChildren]="menuList" [heading]="'navigation.menu' | translate"></app-mobile-nav-list>
                    <app-mobile-meta-list [metaList]="metaList$ | async"></app-mobile-meta-list>
                </div>

                <ng-template #itemNavigation>
                    <ng-container *ngIf="currentMenu$ | async as currentMenu" [ngSwitch]="currentMenu.type">
                        <ng-container *ngIf="currentMenu.navigation">
                            <app-mobile-brand-overview-nav *ngSwitchCase="mobileMenuTypes.BrandsOverview" [navigation]="currentMenu.navigation">
                            </app-mobile-brand-overview-nav>
                            <app-mobile-new-cars-brand-models-nav
                                *ngSwitchCase="mobileMenuTypes.NewCarsBrandModels"
                                [navigation]="currentMenu.navigation"
                            ></app-mobile-new-cars-brand-models-nav>
                            <app-mobile-used-cars-brands-models-nav
                                *ngSwitchCase="mobileMenuTypes.UsedCarsBrandModels"
                                [currentMenu]="currentMenu"
                            ></app-mobile-used-cars-brands-models-nav>
                            <app-mobile-articles-nav
                                *ngSwitchCase="mobileMenuTypes.Articles"
                                [navigation]="currentMenu.navigation"
                            ></app-mobile-articles-nav>
                            <app-mobile-used-cars-nav
                                *ngSwitchCase="mobileMenuTypes.UsedCarsOptions"
                                [navigation]="currentMenu.navigation"
                            ></app-mobile-used-cars-nav>
                            <app-mobile-default-nav *ngSwitchDefault [navigation]="currentMenu.navigation"></app-mobile-default-nav>
                            <app-mobile-used-cars-brands-nav
                                *ngSwitchCase="mobileMenuTypes.UsedCarsBrands"
                                [navigation]="currentMenu.navigation"
                            ></app-mobile-used-cars-brands-nav>
                            <app-mobile-used-cars-budgets-nav
                                *ngSwitchCase="mobileMenuTypes.UsedCarsBudgets"
                                [navigation]="currentMenu.navigation"
                            ></app-mobile-used-cars-budgets-nav>
                            <app-mobile-used-cars-types-nav
                                *ngSwitchCase="mobileMenuTypes.UsedCarsTypes"
                                [navigation]="currentMenu.navigation"
                            ></app-mobile-used-cars-types-nav>
                            <app-mobile-used-cars-categories-nav
                                *ngSwitchCase="mobileMenuTypes.UsedCarsCategories"
                                [navigation]="currentMenu.navigation"
                            ></app-mobile-used-cars-categories-nav>
                        </ng-container>
                    </ng-container>
                </ng-template>
            </nav>
        </aside>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [mobileAnimation],
})
export class MobileMenuComponent {
    menuList$: Observable<INavigationResponse[]>;
    metaList$: Observable<(IMetaLink | IMetaLinkB2B2C)[]>;
    currentMenu$: Observable<IMobileMenu | undefined>;
    isRoot$: Observable<boolean>;

    animationState: MobileAnimationState = 'enter';

    mobileMenuTypes = MobileMenuTypes;

    constructor(
        settingsService: SettingsService,
        private mobileNavigationService: MobileNavigationService,
        private overlayRef: ImpactOverlayRef,
        private cdr: ChangeDetectorRef
    ) {
        this.menuList$ = this.mobileNavigationService.rootMenu$;

        this.metaList$ = settingsService.metaLinks.pipe(map((metaLinks) => metaLinks.filter((metaLink) => metaLink.visibleOnMobile)));

        this.currentMenu$ = this.mobileNavigationService.currentMenu$.pipe(
            tap((currentMenu) => {
                if (!currentMenu) {
                    this.animationState = 'enter';
                    this.cdr.markForCheck();
                }
            })
        );

        this.isRoot$ = this.currentMenu$.pipe(map((current) => !current));
    }

    back() {
        this.mobileNavigationService.back();
    }

    closeMenu() {
        this.animationState = 'leave';
    }

    onAnimationDone(event: AnimationEvent) {
        if (event.toState === 'leave') {
            this.overlayRef.close();
        }
    }
}
