import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';

@Injectable({
    providedIn: 'root',
})
export class HeaderService implements OnDestroy {
    scrollPosition$ = new Subject<number>();
    siteHeaderIsTransparent: boolean;
    private isTransparent$ = new BehaviorSubject(false);
    private headerHeight$ = new BehaviorSubject(100);

    constructor(private featureDetection: FeatureDetectionService) {
        if (this.featureDetection.isBrowser()) {
            document.addEventListener('scroll', this.updateScrollPosition, { passive: true });
        }
    }

    getScrollPosition() {
        return this.scrollPosition$.asObservable();
    }

    updateScrollPosition = () => {
        const position = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        this.scrollPosition$.next(position);
    };

    private removeScrollListener(): void {
        if (this.featureDetection.isBrowser()) {
            document.removeEventListener('scroll', this.updateScrollPosition);
        }
    }

    setHeaderTransparency(transparency: boolean) {
        this.siteHeaderIsTransparent = transparency;
        this.isTransparent$.next(transparency);
    }

    // Toggle transparency when mega navigation is active
    toggleHeaderTransparancy(setTransprent?: boolean) {
        if (typeof setTransprent !== 'undefined') {
            if (this.siteHeaderIsTransparent && !setTransprent) {
                this.isTransparent$.next(false);
            }
        } else {
            this.isTransparent$.next(this.siteHeaderIsTransparent);
        }
    }

    getIsHeaderTransparent(): Observable<boolean> {
        return this.isTransparent$.asObservable();
    }

    updateModelPageHeader(height: number) {
        this.headerHeight$.next(height);
    }

    getHeaderEvents(): Observable<number> {
        return this.headerHeight$.asObservable();
    }

    ngOnDestroy() {
        this.removeScrollListener();
    }
}
