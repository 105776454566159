import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICarDetails } from '@impact/data';
import { Observable } from 'rxjs';

import { DialogService } from '../utils/dialog/dialog.service';

export enum SidePanelComponentsEnum {
    LOAN_CALCULATION = 'Loan Calculation',
    BOOK_NEW_CAR_TESTDRIVE = 'Book New Car Test Drive',
    BOOK_USED_CAR_TESTDRIVE = 'Book Used Car Test Drive',
    GET_NEW_CAR_OFFER = 'Get New Car Offer',
    EXCHANGE_CAR = 'Exhange car',
    CAMPAIGN = 'Campaign',
    BOOK_MEETING = 'Book Meeting',
    BOOK_MEETING_SMALL = 'Book Meeting Small',
    ASK_THE_EXPERT = 'Ask the expert',
    INDICATA_INFO = 'Indicata info',
}

export enum SidePanelSizes {
    SMALL = 'side-panel-size__small',
    MEDIUM = 'side-panel-size__medium',
    LARGE = 'side-panel-size__large',
    XLARGE = 'side-panel-size__xlarge',
}

export enum SidepaneParameters {
    WITH_LEAD_GENERATION = 'With lead generation',
}

@Injectable({
    providedIn: 'root',
})
export class SidePanelService {
    constructor(private readonly dialogService: DialogService, private http: HttpClient) {}

    getSidepanelContentAndOpen(url: string, size?: SidePanelSizes) {
        this.http.get('/api/page/url', { params: { url } }).subscribe((content) => {
            this.openSidePanel('sidepanel', { ...content, sidePanelSize: size });
        });
    }

    async openSidePanel(component: string, componentData: any) {
        const { SidePanelComponent } = await import('./side-panel.component');

        componentData = {
            ...componentData,
            sidePanelSize: componentData.sidePanelSize ? componentData.sidePanelSize : SidePanelSizes.LARGE,
        };

        this.dialogService.openDialogWithComponent(SidePanelComponent, {
            fullHeight: true,
            blockScroll: true,
            hasBackdrop: true,
            data: {
                component,
                componentData,
            },
            positionHorizontal: {
                placement: 'right',
            },
        });
    }

    showLoanCalculatorSidePanel(loanData?: any) {
        this.openSidePanel('sidepanel', {
            sidePanelContent: SidePanelComponentsEnum.LOAN_CALCULATION,
            sidePanelSize: SidePanelSizes.LARGE,
            objectPrice: loanData?.objectPrice,
            registrationDate: loanData?.registrationDate,
        });
    }

    showGetNewCarOfferSidePanel(modelData: string) {
        this.openSidePanel('sidepanel', {
            sidePanelContent: SidePanelComponentsEnum.GET_NEW_CAR_OFFER,
            sidePanelSize: SidePanelSizes.SMALL,
            modelData,
        });
    }

    showExhangeCarSidePanel(withLeadGeneration?: boolean) {
        this.openSidePanel('sidepanel', {
            sidePanelContent: SidePanelComponentsEnum.EXCHANGE_CAR,
            sidePanelSize: SidePanelSizes.MEDIUM,
            withLeadGeneration
        });
    }

    showBookNewCarTestDriveSidePanel(modelData: any, preSelectedOption?: string) {
        this.openSidePanel('sidepanel', {
            sidePanelContent: SidePanelComponentsEnum.BOOK_NEW_CAR_TESTDRIVE,
            sidePanelSize: SidePanelSizes.SMALL,
            modelData,
            preSelectedOption,
        });
    }

    showBookUsedCarTestDriveSidePanel(car$: Observable<ICarDetails>, preSelectedOption?: string) {
        this.openSidePanel('sidepanel', {
            sidePanelContent: SidePanelComponentsEnum.BOOK_USED_CAR_TESTDRIVE,
            sidePanelSize: SidePanelSizes.SMALL,
            car$,
            preSelectedOption,
        });
    }

    showAskTheExpert() {
        this.openSidePanel('sidepanel', {
            sidePanelContent: SidePanelComponentsEnum.ASK_THE_EXPERT,
            sidePanelSize: SidePanelSizes.SMALL,
        });
    }

    showIndicataInfoSidePanel() {
        this.openSidePanel('sidepanel', {
            sidePanelContent: SidePanelComponentsEnum.INDICATA_INFO,
            sidePanelSize: SidePanelSizes.MEDIUM,
        });
    }
}
