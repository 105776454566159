import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import {
    AppGtmDataLayer,
    AppGtmEcommerceOptions,
    AppGtmVehicle,
    AppGtmVirtualFilter,
    GtmWindow,
    ICarDetails,
    PAGE_TYPES,
} from '@impact/data';
import isbot from 'isbot';
import { BehaviorSubject } from 'rxjs';

import { FeatureDetectionService } from '../helpers/feature-detection.service';

// import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
// import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
declare let CookieInformation: any;
// const AI_STATE_KEY = makeStateKey<string>('APPINSIGHTS_INSTRUMENTATIONKEY');

@Injectable({
    providedIn: 'root',
})
export class TrackingService {
    private readonly gtmWindow: GtmWindow;
    private readonly initPromise: Promise<boolean>;
    private readonly marketingConsentGiven$ = new BehaviorSubject(false);
    // private appInsights: ApplicationInsights;

    private initResolver?: (gtmActive: boolean) => void;

    constructor(
        readonly transferState: TransferState,
        // private readonly router: Router,
        @Inject(DOCUMENT) private readonly document: Document,
        @Optional() private readonly featureDetectionService?: FeatureDetectionService,
        @Inject('APPINSIGHTS_INSTRUMENTATIONKEY') @Optional() readonly _aiInstrumentationKey?: string
    ) {
        this.gtmWindow = (this.featureDetectionService && this.featureDetectionService.isBrowser() ? window : {}) as GtmWindow;
        this.initPromise = new Promise<boolean>((resolve) => {
            this.initResolver = resolve;
        });
        this.initPromise.catch(() => {
            console.log('There was a problem with the initial promise');
        });
        this.cookieInformationConsentGiven();

        // Disabling Application Insights per March 10th '22, since the API takes up a whopping 120kB,
        // and it's unclear how much it's actually being used for. Service Delivery has not opposed to us
        // removing it, so we will - and if we need it later, the code is still here. Just remember to
        // re-add the import as well.
        //
        // See BND-286.
        /*
        const aiInstrumentationKey = transferState.get(AI_STATE_KEY, _aiInstrumentationKey);

        if (aiInstrumentationKey) {
            transferState.set(AI_STATE_KEY, aiInstrumentationKey);
            this.initApplicationInsights(aiInstrumentationKey);
        } else {
            console.warn('APPINSIGHTS is missing instrumentation key');
        }
        */
    }

    marketingConsentGiven() {
        return this.marketingConsentGiven$.asObservable();
    }

    /**
     * Initializes GTM.
     */
    initGtm(gtmId: string) {
        if (this.featureDetectionService && this.featureDetectionService.isBrowser() && isbot(window.navigator.userAgent)) {
            this.resolvePromise(false, 'Is a Bot. Script load aborted.');
            return;
        }

        if (!gtmId) {
            console.warn('GTM is missing an access key');
            this.resolvePromise(false, 'WARNING: No container id was specified for google tag manager. Script load aborted.');
            return;
        }

        if (this.featureDetectionService && this.featureDetectionService.isBrowser()) {
            // Check if dataLayer has been setup. (Setup is done on-page in <head> to ensure compatibility with various GTM testing tools)
            // window.dataLayer should not be used directly on-page other than this one time though.
            if (!this.gtmWindow.dataLayer) {
                this.resolvePromise(false, 'WARNING: GTM dataLayer is not defined. Please ensure that it has been defined in the <head> section.');
                return;
            }

            // Push start info
            this.setGtmStart();
            this.pushAppStateToDataLayer();

            // Load gtm script
            this.loadScript('https://www.googletagmanager.com/gtm.js?id=' + gtmId, true, () => {
                if (!this.gtmWindow.google_tag_manager) {
                    console.log('INFO: GTM is being prevented from loading (likely because of an ad-blocker).');
                }

                // Resolve promise
                this.resolvePromise(true);
            });
        }
    }

    /**
     * Initialize Application Insights JS
     *
     * NOTE: disabled per March 10th, '22. See BND-286, or the explanation in the constructor.
     */
    /*
    initApplicationInsights(instrumentationKey: string) {
        if (!this.featureDetectionService?.isBrowser()) {
            return;
        }

        const angularPlugin = new AngularPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                disableCookiesUsage: true,
                extensions: [angularPlugin],
                extensionConfig: {
                    [angularPlugin.identifier]: { router: this.router },
                },
            },
        });

        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();

        return this.appInsights;
    }
    */

    /**
     * Used to track page views. Both regular and dynamic.
     */
    trackVirtualPageview(virtualPageType: string) {
        this.push({
            event: 'virtualPageView',
            virtualPage: this.getHref(),
            virtualPageTitle: this.document.title,
            virtualPageType,
        });
    }

    trackVirtualPageViewFilter(virtualPageFilter: AppGtmVirtualFilter) {
        this.push({
            event: 'virtualPageView',
            virtualPage: this.getHref(),
            virtualPageTitle: this.document.title,
            virtualPageType: PAGE_TYPES.USED_CARS_OVERVIEW_PAGE,
            virtualPageFilter,
        });
    }

    trackVirtualPageViewPdp(vehicle: ICarDetails, virtualPageTitle?: string) {
        this.push({
            event: 'virtualPageView',
            virtualPage: this.getHref(),
            virtualPageTitle: virtualPageTitle || this.document.title,
            virtualPageType: PAGE_TYPES.USED_CARS_DETAILS_PAGE,
            virtualPagePdp: {
                contentId: vehicle.id,
                make: vehicle.make?.data ?? '',
                model: vehicle.model?.data ?? '',
                color: vehicle.color?.data ?? '',
                year: vehicle.year?.data,
                bodyTypes: vehicle.bodyTypes?.data ?? undefined,
                driveTrain: vehicle.driveWheels?.data ?? '',
                fuelType: vehicle.propellant?.data ?? '',
                transmission: vehicle.gearType?.data ?? '',
                price: vehicle.price?.data ?? 0,
                mileage: vehicle.mileage?.data ?? 0,
                dealershipZipcode: vehicle.dealer?.data?.dealerAddressZipCode?.data ?? '',
                image: vehicle.pictures && vehicle.pictures.length ? vehicle.pictures[0] : '',
            },
        });
    }

    trackProductDetailsPage(): void {
        this.push({
            event: 'serverEvent',
            eventCategory: 'product detail',
        });
    }

    /**
     * Track 404 pages.
     */
    track404() {
        if (this.featureDetectionService?.isBrowser()) {
            this.push({
                event: 'serverEvent',
                eventCategory: '404 not found',
                eventAction: this.getHref(),
                eventLabel: this.document.referrer,
            });
        }
    }

    /**
     * Track form events like initialization and completion
     *
     * @TODO Update the `string` type for `eventAction` to a template literal type (`step-${string}`) when you upgrade to TypeScript >4
     */
    trackFormEvent(
        eventAction: 'initialization' | 'completion' | string,
        eventLabel: string,
        hasConsent = false,
        vehicle?: AppGtmVehicle,
        customData?: Record<string, unknown>
    ): void {
        const event: AppGtmDataLayer = {
            event: 'serverEvent',
            eventCategory: 'form',
            eventAction,
            eventLabel,
        };

        if (vehicle) {
            event.vehicle = vehicle;
        }

        if (customData) {
            event.customData = customData;
        }

        this.push(event);

        if (hasConsent) {
            this.trackConsentGiven();
        }
    }

    /**
     * Send an event if the consent field was checked while submitting a form
     */
    trackConsentGiven(): void {
        this.push({
            event: 'serverEvent',
            eventCategory: 'consent',
            eventAction: 'given',
        });
    }

    /**
     * Send an event for various stages of checkout following Google's Universal Ecommerce definition
     */
    trackEcommerce(ecommerce: AppGtmEcommerceOptions, clearPrevious = false, event?: string): void {
        if (clearPrevious) {
            this.push({
                eventCategory: 'product detail',
                ecommerce: null,
            });
        }

        const layer: AppGtmDataLayer = {
            event: 'Product detail',
            ecommerce,
        };

        if (event) {
            layer.event = event;
        }

        this.push(layer);
    }

    /**
     * Show CookieInformation cookie popup
     */
    showCookieBanner() {
        if (window && 'showCookieBanner' in window) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).showCookieBanner();
        }
    }

    /**
     * Wraps basic push function gtm.js. Use this instead of accessing window.dataLayer directly.
     * @param {object} gtmDataLayerObj - Standard GTM dataLayer object format.
     * @param {boolean} immediate - Don't defer pushing data until GTM has been initialized (default: false).
     */
    private push(gtmDataLayerObj: AppGtmDataLayer, immediate?: boolean) {
        if (this.featureDetectionService && this.featureDetectionService.isBrowser()) {
            if (!this.gtmWindow.google_tag_manager && gtmDataLayerObj.eventCallback) {
                // Ensure callback fires even if tracking is disabled or blocked.
                gtmDataLayerObj.eventCallback();
                gtmDataLayerObj.eventCallback = undefined;
            }

            if (this.gtmWindow.dataLayer) {
                if (!immediate) {
                    this.initPromise
                        .then(() => {
                            this.gtmWindow.dataLayer.push(gtmDataLayerObj);
                        })
                        .catch((reason) => reason);
                } else {
                    this.gtmWindow.dataLayer.push(gtmDataLayerObj);
                }
            }
        }
    }

    /**
     * Pushes the start event.
     */
    private setGtmStart() {
        this.push({
            event: 'gtm.js',
            'gtm.start': new Date().getTime(),
        });
    }

    /**
     * Resolves the initPromise. Deferred pushes wait for this.
     * @param {boolean} status - Init successful or not.
     * @param {string} message - Optional warning message for console.
     */
    private resolvePromise(status: boolean, message?: string) {
        if (this.initResolver) {
            this.initResolver(status);
            if (message) {
                console.warn(message);
            }
        }
    }

    /**
     * Simple script loader for loading script tags into dom via js.
     * Ideal for loading 3rdParty scripts (tracking, etc.).
     * @param url {string} - the url of the script file (use // without of protocol).
     * @param isAsync {boolean} - whether script should async or not (default is true)
     * @param callback {function} - optional callback function for onload event.
     */
    public loadScript(url: string, isAsync: boolean = true, callback?: () => any): void {
        if (this.featureDetectionService && this.featureDetectionService.isBrowser()) {
            const doc = this.document;
            const scriptElement: HTMLScriptElement = doc.createElement('script');
            if (callback) {
                scriptElement.onload = callback;
            }
            scriptElement.async = isAsync;
            scriptElement.src = url;
            doc.getElementsByTagName('head')[0].appendChild(scriptElement);
        } else if (callback) {
            callback();
        }
    }

    /**
     * Get href
     */
    private getHref(): string | undefined {
        const doc = this.document;
        return doc.location ? doc.location.href : undefined;
    }

    private cookieInformationConsentGiven() {
        if (!this.featureDetectionService || this.featureDetectionService.isServer()) {
            return;
        }

        window.addEventListener(
            'CookieInformationConsentGiven',
            () => {
                const marketingCookie = CookieInformation.getConsentGivenFor('cookie_cat_marketing');
                this.marketingConsentGiven$.next(!!marketingCookie);
            },
            false
        );
    }

    private pushAppStateToDataLayer(appState: string = 'appReady') {
        if (this.gtmWindow.dataLayer) {
            try {
                this.gtmWindow.dataLayer.push({
                    event: appState,
                });
            } catch (exception) {
                console.warn('GTM: Failed to push appState to dataLayer');
            }
        }
    }
}
