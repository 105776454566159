import { NgModule, Optional, SkipSelf } from '@angular/core';

@NgModule()
export class MetaModule {
    constructor(@Optional() @SkipSelf() parentModule: MetaModule) {
        if (parentModule) {
            throw new Error('MetaModule is already loaded. Import it in the AppModule only');
        }
    }
}
