import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { INavigationResponse, IUspItem, PAGE_TYPES } from '@impact/data';

import { SettingsService } from '../../core/settings.service';
import { FeatureDetectionService, KEY_CODES } from '../../utils/helpers/feature-detection.service';
import { NavigationService } from '../navigation.service';
import { AnimationStates } from './mega-navigation.animations';
import { Observable } from 'rxjs';

export const megaNavigationColumnsMaxSize = 10;
const megaMenuMinHeight = 500;

@Component({
    selector: 'app-mega-navigation',
    template: `
        <div class="mega-menu-backdrop" [ngStyle]="{
            'transform': 'translateY(' + megaNavigationPositionTop + 'px)'
        }">
            <ng-container [ngSwitch]="isB2BSite$ | async">
                <ng-container *ngSwitchCase="true">
                    <div class="mega-navigation__usp" *ngIf="globalUspListb2b && megaNavigationContent && megaNavigationContent?.template !== pageTypes.NEW_CARS_OVERVIEW_PAGE">
                        <ul class="usp__items">
                            <li class="usp-item" *ngFor="let item of globalUspListb2b">
                                <i *ngIf="item.svgIcon" aria-hidden="true" class="usp-item__icon" [innerHTML]="item?.svgIcon | safe: 'html'"></i>
                                <img
                                    *ngIf="item.icon?.url && !item.svgIcon"
                                    [src]="item.icon?.url + '?width=50&format=webp'"
                                    class="usp-item__icon"
                                    loading="lazy"
                                    width="50"
                                    height="50"
                                    [alt]="item?.title"
                                />
                                <div>
                                    <h6 class="usp-item__heading" *ngIf="item?.title">{{ item.title }}</h6>
                                    <div class="usp-item__text" *ngIf="item?.text">{{ item.text }}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                    <div class="mega-navigation__usp" *ngIf="globalUspList && megaNavigationContent && megaNavigationContent?.template !== pageTypes.NEW_CARS_OVERVIEW_PAGE">
                        <ul class="usp__items">
                            <li class="usp-item" *ngFor="let item of globalUspList">
                                <i *ngIf="item.svgIcon" aria-hidden="true" class="usp-item__icon" [innerHTML]="item?.svgIcon | safe: 'html'"></i>
                                <img
                                    *ngIf="item.icon?.url && !item.svgIcon"
                                    [src]="item.icon?.url + '?width=50&format=webp'"
                                    class="usp-item__icon"
                                    loading="lazy"
                                    width="50"
                                    height="50"
                                    [alt]="item?.title"
                                />
                                <div>
                                    <h6 class="usp-item__heading" *ngIf="item?.title">{{ item.title }}</h6>
                                    <div class="usp-item__text" *ngIf="item?.text">{{ item.text }}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="mega-navigation" #meganavigationContainer>
            <div class="mega-navigation__content-wrapper" id="meganavigationContainer" aria-live="polite">
                <ng-container *ngIf="megaNavigationContent" [ngSwitch]="megaNavigationContent?.template">
                    <app-mega-navigation-new-cars
                        class="mega-navigation__content-component"
                        *ngSwitchCase="pageTypes.NEW_CARS_OVERVIEW_PAGE"
                        [data]="megaNavigationContent">
                    </app-mega-navigation-new-cars>
                    <app-mega-navigation-used-cars
                        class="mega-navigation__content-component"
                        *ngSwitchCase="pageTypes.USED_CARS_OVERVIEW_PAGE"
                        [data]="megaNavigationContent">
                    </app-mega-navigation-used-cars>
                    <app-mega-navigation-articles-universe
                        class="mega-navigation__content-component"
                        *ngSwitchCase="pageTypes.ARTICLES_OVERVIEW_PAGE"
                        [data]="megaNavigationContent">
                    </app-mega-navigation-articles-universe>
                    <app-mega-navigation-leasing-cars
                        class="mega-navigation__content-component"
                        *ngSwitchCase="pageTypes.VEHICLE_LEASING_OVERVIEW_PAGE"
                        [data]="megaNavigationContent">
                    </app-mega-navigation-leasing-cars>
                    <app-mega-navigation-list-columns
                        class="mega-navigation__content-component"
                        *ngSwitchDefault [data]="megaNavigationContent">
                    </app-mega-navigation-list-columns>
                </ng-container>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MegaNavigationComponent implements OnInit {
    megaNavigationState: string;
    meganavigationContentState: string;
    megaNavigationContent: INavigationResponse | undefined;
    pageTypes = PAGE_TYPES;
    megaNavigationheight: string;
    megaNavigationPositionTop: number;

    globalUspList: IUspItem[];
    globalUspListb2b: IUspItem[];

    isB2BSite$: Observable<boolean>;

    @ViewChild('meganavigationContainer') meganavigationContainer: ElementRef;

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.key === KEY_CODES.ESCAPE && this.megaNavigationState === AnimationStates.SHOW) {
            this.navigationService.showMegaNavigation(false);
            this.megaNavigationState = AnimationStates.HIDE;
            this.setNavigationHeight();
        }
    }

    constructor(
        private cdr: ChangeDetectorRef,
        private navigationService: NavigationService,
        private settingsService: SettingsService,
        private featureDetectionService: FeatureDetectionService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.megaNavigationState = AnimationStates.HIDE;
        this.meganavigationContentState = AnimationStates.HIDE;

        this.settingsService.getGlobalUSPs().subscribe((globalUSPs) => {
            if (globalUSPs.length) {
                this.globalUspList = globalUSPs;
            }
        });

        this.settingsService.getGlobalUSPb2bs().subscribe((globalUSPb2bs) => {
            if (globalUSPb2bs.length) {
                this.globalUspListb2b = globalUSPb2bs;
            }
        });

        this.navigationService.isMegaNavigationActive().subscribe((data) => {

            if (!data?.isActive || !data?.item) {
                this.megaNavigationState = AnimationStates.HIDE;
                this.megaNavigationContent = undefined;
                this.megaNavigationPositionTop = 0;
                this.cdr.markForCheck();
                return;
            }

            // Set content
            this.megaNavigationContent = data.item;

            // If already open, we will update the content
            if (this.megaNavigationState === AnimationStates.SHOW && data.isActive) {
                this.fadeInNewMegaNavigationContent();
            } else {
                this.megaNavigationState = data.isActive ? AnimationStates.SHOW : AnimationStates.HIDE;
            }

            this.cdr.markForCheck();
        });
    }

    ngOnInit() {
        this.isB2BSite$ = this.navigationService.getSiteB2Bstate();

        if (this.featureDetectionService.isBrowser()) {
            const observer = new ResizeObserver(entries => {
                entries.forEach(entry => {
                    this.setNavigationHeight();
                });
            });

            observer.observe(this.document.querySelector('#meganavigationContainer') as Element);
        }
    }

    showMegaNavigationContent() {
        if (this.megaNavigationState === AnimationStates.SHOW) {
            this.meganavigationContentState = AnimationStates.SHOW;
        }
    }

    hideMegaNavigationContent() {
        if (this.megaNavigationState === AnimationStates.HIDE) {
            this.meganavigationContentState = AnimationStates.HIDE;
        }
    }

    // When the mega navigation is open, but another mega navigation item is active,
    // we hide the content, then fade in the new
    fadeInNewMegaNavigationContent() {
        const meganavigationContentStateTimeout = setTimeout(() => {
            this.meganavigationContentState = AnimationStates.HIDE;
            this.meganavigationContentState = AnimationStates.SHOW;
            clearTimeout(meganavigationContentStateTimeout);
            this.cdr.markForCheck();
        }, 150);
    }

    setNavigationHeight() {
        let bottomSpacing;

        if (this.megaNavigationContent && this.megaNavigationContent.template === PAGE_TYPES.NEW_CARS_OVERVIEW_PAGE) {
            bottomSpacing = 0;
        } else {
            bottomSpacing = 134;
        }

        if (this.megaNavigationState === AnimationStates.HIDE) {
            this.megaNavigationPositionTop = 0;
        } else if (this.megaNavigationState === AnimationStates.SHOW && this.megaNavigationPositionTop < 700) {
            this.megaNavigationPositionTop = 700 + bottomSpacing;
        }

        if (this.megaNavigationState === AnimationStates.SHOW) {
            this.megaNavigationPositionTop =
                this.meganavigationContainer.nativeElement.offsetHeight > megaMenuMinHeight ? this.meganavigationContainer.nativeElement.offsetHeight + bottomSpacing : megaMenuMinHeight;
        }

        this.cdr.detectChanges();
    }

    trackByMethod(index: number, item: INavigationResponse) {
        return item.id || index;
    }
}
