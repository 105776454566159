import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { SettingsService } from '../core/settings.service';
import { MetaService } from '../meta/meta.service';

@Component({
    selector: 'app-json-ld',
    template: ` <ngx-json-ld [json]="schema" *ngIf="schema"></ngx-json-ld> `,
})
export class JsonLdComponent implements OnInit {
    schema: any;

    constructor(
        private settingsService: SettingsService,
        private metaService: MetaService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        // JSON+LD
        this.settingsService
            .get()
            .pipe(first())
            .subscribe((settings) => {
                const baseUrl = this.metaService.getBaseUrl();

                this.schema = {
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    url: baseUrl,
                    logo: `${baseUrl}/assets/icons/logo.svg`,
                    name: settings.companyName,
                };

                // Set preconnect to required domains
                if (settings.preConnectDomains && settings.preConnectDomains.length) {
                    for (let i = 0; i < settings.preConnectDomains.length; i++) {
                        this.metaService.setPreconnectResource(settings.preConnectDomains[i]);
                    }
                }

                this.cd.markForCheck();
            });
    }
}
