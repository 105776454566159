export class NavigationLink {
    url: string;
    params: any;

    constructor(url: string, params: string) {
        const paramsArray = params?.length > 1 ? params.replace(' ', '').split(',') : null;
        const paramsObject: { [key: string]: string[] } = {};

        if (paramsArray?.length) {
            for (const param of paramsArray) {
                // Find Key and Value of the query param
                const [key, value] = param.split('=');
                // check if is this param key already is in the paramsObj
                const { [key]: prevFacetValue } = paramsObject;

                if (key !== undefined && value !== undefined) {
                    // IF the key of the param is already in the paramsObj
                    // we here merge that value, prevFacetValue, into a string[]
                    // with the new value
                    paramsObject[key] = [...(prevFacetValue || []), decodeURIComponent(value)];
                }
            }
        }

        this.url = url;
        this.params = paramsObject;
    }
}
