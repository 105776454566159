import { Injectable, OnDestroy } from '@angular/core';
import { IValidationError } from '@impact/data';
import { ImpactOverlayConfig, ImpactOverlayRef, OverlayService } from '@impactdk/ngx-overlay';
import { Subject } from 'rxjs';

import { SimpleDialogConfig } from './dialog';

@Injectable({
    providedIn: 'root',
})
export class DialogService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    constructor(private impactOverlay: OverlayService) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    openDialogWithComponent<T = any>(component: any, dialogConfig: ImpactOverlayConfig<T>): ImpactOverlayRef {
        const config: SimpleDialogConfig = { hasBackdrop: true, ...dialogConfig };
        return this.impactOverlay.open(component, config);
    }

    openSidePanelWithComponent<T = any>(component: any, dialogConfig: ImpactOverlayConfig<T>): ImpactOverlayRef {
        const config: SimpleDialogConfig = {
            hasBackdrop: true,
            fullHeight: true,
            blockScroll: true,
            positionHorizontal: {
                placement: 'right',
            },
            ...dialogConfig,
        };

        return this.impactOverlay.open(component, config);
    }

    async openDialog(dialogConfig: SimpleDialogConfig): Promise<ImpactOverlayRef> {
        const { DialogComponent } = await import('./dialog.component');
        return this.openDialogWithComponent(DialogComponent, dialogConfig);
    }

    showMessage(text: string | string[], header?: string): void {
        // SetTimeout ensures the context has been rendered before showing a dialog, which could happen when opened in an ngOnInit hook
        // https://github.com/angular/material2/issues/5268
        setTimeout(() => {
            this.openDialog({
                data: {
                    header,
                    text,
                },
            });
        });
    }

    showValidationErrors(validationErrors: IValidationError[], headerVariable: string = 'forms.dialog_validation_errors'): void {
        const message = validationErrors.map((x) => x.error).join('<br>');
        this.showMessage(validationErrors && validationErrors.length ? message : 'Error', headerVariable);
    }
}
