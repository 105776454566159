import { SpotTypes } from '@impact/data';

import { CarouselSpotComponent } from './carousel-spot/carousel-spot.component';

export const spotComponents = [CarouselSpotComponent];

export const getLazySpotComponent = (type?: string) => {
    switch (type) {
        case SpotTypes.WorkshopServiceSpot:
            return import('./workshop-service-spot/workshop-service-spot.component');
        case SpotTypes.AccordionSpot:
            return import('./accordion-spot/accordion-spot.component');
        case SpotTypes.ArticlesSpot:
            return import('./articles-spot/articles-spot.component');
        case SpotTypes.AuthorSpot:
            return import('./author-info-spot/author-info-spot.component');
        case SpotTypes.BookUsedCarTestDriveCtaSpot:
            return import('./book-used-car-test-drive-cta-spot/book-used-car-test-drive-cta-spot.component');
        case SpotTypes.BookNewCarTestDriveCtaSpot:
            return import('./book-new-car-test-drive-cta-spot/book-new-car-test-drive-cta-spot.component');
        case SpotTypes.VehicleCampaignsOverviewSpot:
            return import('./../new-cars/vehicle-models-campaign-overview.component');
        case SpotTypes.DisclaimerSpot:
            return import('./disclaimer-spot/disclaimer-spot.component');
        case SpotTypes.DealershipContentSpot:
            return import('./dealership-content-spot/dealership-content-spot.component');
        case SpotTypes.ExteriorInteriorGallerySpot:
            return import('./exterior-interior-gallery-spot/exterior-interior-gallery-spot.component');
        case SpotTypes.GridSpot:
            return import('./grid-spot/grid-spot.component');
        case SpotTypes.HeadlineAndTextSpot:
            return import('./headline-and-text-spot/headline-and-text-spot.component');
        case SpotTypes.ImageTextSplitSpot:
            return import('./image-text-split-spot/image-text-split-spot.component');
        case SpotTypes.QuoteFromExpertSpot:
            return import('./quote-from-expert-spot/quote-from-expert-spot.component');
        case SpotTypes.RichTextSpot:
            return import('./rte-spot/rte-spot.component');
        case SpotTypes.TwoColumnsTableSpot:
            return import('./two-column-table-spot/two-column-table-spot.component');
        case SpotTypes.TrustBoxSpot:
            return import('./trustbox-spot/trustbox-spot.component');
        case SpotTypes.UspSpot:
            return import('./usp-spot/usp-spot.component');
        case SpotTypes.ImageVideoWithTextSpot:
            return import('./image-video-text-spot/image-video-text-spot.component');
        case SpotTypes.UsedCarsFiltersSpot:
            return import('./used-cars-filters-spot/used-cars-filters-spot.component');
        case SpotTypes.EuroNCAPScoreSpot:
            return import('./euro-ncap-score-spot/euro-ncap-score-spot.component');
        case SpotTypes.ReviewsSpot:
            return import('./reviews-spot/reviews-spot.component');
        case SpotTypes.VehicleVariantsOverviewSpot:
            return import('../new-cars/vehicle-variants-overview-spot.component');
        case SpotTypes.VehicleVariantsLeasingSpot:
            return import('../new-cars/vehicle-variants-leasing-spot.component');
        case SpotTypes.UsedCarsListQuerySpot:
            return import('./used-cars-list-query-spot/used-cars-list-query-spot.component');
        case SpotTypes.NewCarsModelCardListSpot:
            return import('./new-cars-model-card-list-spot/new-cars-model-card-list-spot.component');
        case SpotTypes.ImageLinksGridSpot:
            return import('./image-links-grid-spot/image-links-grid-spot.component');
        case SpotTypes.NewsletterSignupSpot:
            return import('./newsletter-signup-spot/newsletter-signup-spot.component');
        case SpotTypes.LouconLeasingVariantsSpot:
            return import('./loucon-leasing-variants-spot/loucon-leasing-variants-spot.component');
        case SpotTypes.overlayBoxSpot:
            return import('./overlay-box-spot/overlay-box-spot.component');
        case SpotTypes.factBoxSpot:
            return import('./fact-box-spot/fact-box-spot.component');
        case SpotTypes.quoteSpot:
            return import('./quote-spot/quote-spot.component');
        case SpotTypes.CarouselContentSpot:
            return import('./carousel-content-spot/carousel-content-spot.component');
        default:
            return undefined;
    }
};
