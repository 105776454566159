import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { expansionAnimation } from './expansion.animation';

@Component({
    selector: 'app-expansion-panel',
    template: `
        <section>
            <ng-container *ngIf="headerClickable; else buttonOnly">
                <header class="expansion-panel__header" (click)="onExpandClick($event)" role="button" aria-label="Click to see more">
                    <ng-container *ngTemplateOutlet="title"></ng-container>
                </header>
            </ng-container>

            <ng-template #buttonOnly>
                <header class="expansion-panel__header">
                    <ng-container *ngTemplateOutlet="title"></ng-container>
                </header>
            </ng-template>

            <ng-template #title>
                <ng-content select="[expansion-title]"></ng-content>
                <i
                    aria-hidden="true"
                    class="expansion-panel__icon"
                    [class.expansion-panel__icon--rotate-up]="expanded"
                    (click)="onExpandClick($event)"
                >
                    <ng-content select="[expansion-icon]"></ng-content>
                </i>
            </ng-template>

            <div
                class="expansion-panel__content"
                [@expandAnimation]="expandedState"
                (@expandAnimation.start)="onAnimationStartEvent()"
                (@expandAnimation.done)="onAnimationDoneEvent()"
            >
                <ng-content></ng-content>
            </div>
        </section>
    `,
    animations: [expansionAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelComponent implements OnInit {
    @Input()
    expanded = false;

    @Input()
    headerClickable = false;

    @Output() changed = new EventEmitter<boolean>();

    expandedState?: 'expanded' | 'closed';
    animating = false;

    ngOnInit() {
        this.expandedState = this.expanded ? 'expanded' : 'closed';
    }

    onExpandClick(event?: Event) {
        this.expanded = !this.expanded;
        this.expandedState = this.expanded ? 'expanded' : 'closed';
        this.changed.emit(this.expanded);

        if (event) {
            // Prevent double-click
            event.stopPropagation();
            event.preventDefault();
        }
    }

    onAnimationStartEvent() {
        this.animating = true;
    }

    onAnimationDoneEvent() {
        this.animating = false;
    }
}
