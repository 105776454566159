import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SettingsService } from '../../core/settings.service';

@Injectable({
    providedIn: 'root',
})
export class ClientRoutingService {
    constructor(private router: Router, private settingsService: SettingsService) {}

    redirectToNotFoundPage() {
        this.settingsService.getGlobalpages().subscribe((globalPages) => {
            this.router.navigateByUrl(globalPages.notFoundPage.url ?? '/404');
        });
    }
}
