import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';

export interface IToast {
    text: string;
    delay?: number;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
    private _toasts$ = new BehaviorSubject<IToast[]>([]);
    toasts$ = this._toasts$.asObservable();

    constructor(private featureDetection: FeatureDetectionService) {}

    show(text: string) {
        if (this.featureDetection.isBrowser()) {
            const toasts = this._toasts$.value;
            toasts.push({ text });
            this._toasts$.next(toasts);
        }
    }

    remove(toast: IToast) {
        let toasts = this._toasts$.value;
        toasts = toasts.filter(t => t !== toast);
        this._toasts$.next(toasts);
    }
}