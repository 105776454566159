import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { chevronLeft, closeLightIcon } from '../../icons';

@Component({
    selector: 'app-mobile-overlay-header',
    template: `
        <header class="mobile-overlay-header__header">
            <button class="button--close mobile-overlay-header__header-button" aria-label="Luk navigation" (click)="closed.emit()">
                ${closeLightIcon}
            </button>
            <h5 class="mobile-overlay-header__title" *ngIf="title">{{ title }}</h5>
            <button *ngIf="showBack" aria-label="Tilbage" class="button button--minimal mobile-overlay-header__header-button" (click)="back.emit()">
                ${chevronLeft}
            </button>
        </header>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileOverlayHeaderComponent {
    @HostBinding('class') class = 'mobile-overlay-header';
    @Input() showBack = false;
    @Input() title?: string;
    @Output() back = new EventEmitter();
    @Output() closed = new EventEmitter();
}
